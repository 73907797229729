import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface TableState {
  identifier: {[key: string]: string}
  data: {[key: string]: Array<any>};
  sortBy: {[key: string]: string};
  direction: {[key: string]: string};
  loading: {[key: string]: boolean};
}

const initialState: TableState = {
  identifier: {},
  data: {},
  sortBy: {},
  direction: {},
  loading: {},
};

const sortData = (data: Array<any>, sortKey: string, sortDirection: string) => {

  if (!data) {
    return Array([]);
  }

  if (data.length === 0) {
    return data;
  }

  let sortedData = [...data];
  let direction = 1;

  if (sortDirection === "DESC") {
    direction = -1;
  }

  if (typeof data[0][sortKey] === "string") {
    sortedData.sort((obj_a: any, obj_b: any) => {
      let upper_a = obj_a[sortKey].toUpperCase();
      let upper_b = obj_b[sortKey].toUpperCase();

      let r = 0;

      if (upper_a < upper_b) {
        r = -1;
      } else if (upper_a > upper_b) {
        r = 1;
      }

      return r * direction;
    });
  } else if (typeof data[0][sortKey] === "number") {
    sortedData.sort((obj_a: any, obj_b: any) => {
      return (obj_a[sortKey] - obj_b[sortKey]) * direction;
    });
  } else if (typeof data[0][sortKey] === "object") {
    try {
      sortedData.sort((obj_a: any, obj_b: any) => {
        return (obj_a[sortKey] - obj_b[sortKey]) * direction;
      });
    } catch(err: any) {
      console.error(err)
      throw new Error(
        'Something went wrong when sorting object'
      );
    }
    sortedData.sort((obj_a: any, obj_b: any) => {
      return (obj_a[sortKey] - obj_b[sortKey]) * direction;
    });
    
  }

  return sortedData;
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    initializeTables: (
      state,
      action: PayloadAction<{ keys: Array<string> }>
    ) => {
      action.payload.keys.forEach((key: string) => {
        state.identifier[key] = "";
        state.loading[key] = true;
        state.data[key] = [];
        state.sortBy[key] = 'modifiedAt'
        state.direction[key] = 'DESC'
      })
    },
    setTableItem: (
      state,
      action: PayloadAction<{ key: string; data: Array<any> }>
    ) => {
      
      const sortBy: string = state.sortBy[action.payload.key];
      const sortDirection: string = state.direction[action.payload.key];

      if (sortBy && sortDirection) {
        state.data[action.payload.key] = sortData(
          action.payload.data,
          sortBy,
          sortDirection
        );
      } else {
        state.data[action.payload.key] = action.payload.data;
      }
    },
    initializeTableItem: (
      state,
      action: PayloadAction<{ key: string; data: Array<any>, sortBy: string, sortDirection: string }>
    ) => {
      state.direction[action.payload.key] = action.payload.sortDirection;
      state.sortBy[action.payload.key] = action.payload.sortBy;
      state.data[action.payload.key] = sortData(
        action.payload.data,
        action.payload.sortBy,
        action.payload.sortDirection
      );
      state.loading[action.payload.key] = false;
      
    },
    setIdentifier: (
      state,
      action: PayloadAction<{ key: string; identifier: string }>
    ) => {
      state.identifier[action.payload.key] = action.payload.identifier;
    },
    setSortBy: (
      state,
      action: PayloadAction<{ key: string; sortBy: string }>
    ) => {
      state.sortBy[action.payload.key] = action.payload.sortBy;
    },
    setSortDirection: (
      state,
      action: PayloadAction<{ key: string; direction: string }>
    ) => {
      state.direction[action.payload.key] = action.payload.direction;
    },
    setLoading: (
      state,
      action: PayloadAction<{ key: string; loading: boolean }>
    ) => {
      state.loading[action.payload.key] = action.payload.loading;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initializeTableItem,
  initializeTables,
  setTableItem,
  setSortBy,
  setSortDirection,
  setLoading,
} = tableSlice.actions;

export default tableSlice.reducer;
