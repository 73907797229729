export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export function applyContrast(imgData: Uint8ClampedArray, c: number) : Uint8ClampedArray{  //input range [-100..100]
    let d: Uint8ClampedArray = imgData;
    let shifted_c = (c / 100) + 1;  //convert to decimal & shift range: [0..2]
    let intercept = 128 * (1 - shifted_c);
    for (let i = 0; i < d.length ; i++) {
        d[i] = clamp((d[i] * shifted_c) + intercept, 0, 255)
    }

    return d;
}
export function applyBrightness(imgData: Uint8ClampedArray, b: number): Uint8ClampedArray {
    let d: Uint8ClampedArray = imgData;
    for (let i = 0; i < d.length; i++) {
        if (i % 4 !== 3) {
            d[i] = clamp(d[i] + (255 * (b / 100)), 0, 255);
        }
        
    }
    return d;
}

export function getStandardDeviation(array: any) {
    const n: number = array.length
    const mean = array.reduce((a: number, b: number) => a + b) / n
    return {
        mean: mean,
        std: Math.sqrt(array.map((x:number) => Math.pow(x - mean, 2)).reduce((a:number, b:number) => a + b) / n)
    }
  }

/**
 * 
 * @param imgData (Int32Array): unmodified UInt16 array as Int32Array
 * @param stds (number): Number of standard devaiations from the mean
 * @returns (Int32Array): Outliner normalized image
 */
export function applyOutlierNormalization(imgData: Int32Array, stds: number): Int32Array {

    let tmp = new Int32Array(imgData);
    const {mean, std} = getStandardDeviation(imgData)
    const minMaxAvg = getMinMax(imgData)

    let offset = 0
    let min = minMaxAvg.min
    let max = minMaxAvg.max

    if (minMaxAvg.min < 0) {
        offset = Math.abs(minMaxAvg.min)
        min = 0
    }

    if (max > mean && max > mean+(stds*std)) {
        max = mean+(stds*std)
    }

    max = max + offset

    for (let i = 0; i < tmp.length ; i++) {
        tmp[i] = (
            clamp((((tmp[i]+offset) - min) / (max - min)), 0, 1) * 245
        )
    }

    return tmp

}

export function applyThreshold(imgData: Uint8ClampedArray, original: Int32Array, t: number): Uint8ClampedArray {


    // Ex: For every 4 pixels in imgData is one in the original (L vs RGBA)
    const ratio = imgData.length / original.length

    let d: Uint8ClampedArray = imgData;
    let j = 0
    for (let i = 0; i < d.length; i++) {
        
        j = i*ratio

        if (original[i] >= t) {
            d[j] = 255;
            d[j+1] = 255;
            d[j+2] = 255;
            d[j+3] = 255;
        }
    }
    return d;
}

export function countValues(imgData: Uint8ClampedArray) {
    let d = new Uint8ClampedArray(256).fill(0);

    for (let i = 0; i < d.length; i++) {
        if (i % 4 !== 3) {
            d[Math.round(imgData[i])]++;
        }
        
    }
    return d;
}

/**
 * 
 * @param imgData Flattened array holding image data
 * @param height height of the image
 * @param width width of the image
 * @returns array with max value for each row
 */
export function getMaxAxis(imgData: Uint8ClampedArray, height: number, width: number) {
    // Example, height 256, width 336


    let RowMax: Uint8ClampedArray = new Uint8ClampedArray(height).fill(0);
    // let ColMax: Uint8ClampedArray = new Uint8ClampedArray(width).fill(0);

    for (let h = 0 ; h < height; h++) {

        let start = h * 4 * width;      // start at 0, 336, 672, etc
        let end = start + width * 4;    // [0 - 335(336)], [336 - 671(672)]

        let row = imgData.slice(start, end);
        let localMax = 0;

        row.forEach((item: number, index: number) => {
            if ((localMax < item) && index % 4 !== 3) {
                localMax = item;
            }
        })

        RowMax[h] = localMax
    }

    return RowMax
}

export function getMaxAxisOriginal(imgData: Int32Array, height: number, width: number) {
    // Example, height 256, width 336

    if (!imgData) {
        console.warn("Something went wrong")
    }

    let RowMax: Uint16Array = new Uint16Array(height).fill(0);
    // let ColMax: Uint16Array = new Uint16Array(width).fill(0);

    for (let h = 0 ; h < height; h++) {

        let start = h * width;      // start at 0, 336, 672, etc
        let end = start + width;    // [0 - 335(336)], [336 - 671(672)]

        let row = imgData.slice(start, end);
        let localMax = 0;

        row.forEach((item: number, index: number) => {
            if ((localMax < item)) {
                localMax = item;
            }
        })

        RowMax[h] = localMax
    }

    return RowMax
}

export const singleToRGBA = (data: any) => {
  const len = data.length;
  let newData = new Uint16Array(len*4).fill(255);

  for (let i = 0 ; i < data.length ; i++) {

      newData[(i*4)] = data[i]    // R value
      newData[(i*4)+1] = data[i]  // G value
      newData[(i*4)+2] = data[i]  // B value
      newData[(i*4)+3] = 255      // A value

  }

  return newData
}

export const getMinMax = (data: any) => {
  
  let min = 2**32 + 1;
  let max = 0;
  let cma = 0

  data.forEach((pixel: number, i: number) => {
      if (pixel < min) {
          min = pixel
      }

      if (pixel > max) {
          max = pixel
      }

      let n = i+1
      cma = (pixel + (n * cma)) / (n+1)

  })

  return {min: min, max: max, avg: cma}

}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export function hslToRgb(h: number, s: number, l: number): [number, number, number]{
    let r, g, b;

    if (s === 0){
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}