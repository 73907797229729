import React from 'react';
import * as yup from 'yup';
import { Form, Field, ErrorMessage, Formik  } from 'formik';
import { Link, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify';

export const ResetPassword = (props: any) => {

    const history = useHistory()

    const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required')
    });

    return (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={values => {
                Auth.forgotPassword(values.email)
                    .then(data => {
                        history.push('/reset/confirm');
                    })
                    .catch(err => console.error(err));
            }}
        >
            <div className="flex justify-center items-center mt-8">
                <div className="w-full max-w-xs">
                    <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div>
                            <h1 className="text-center text-xl font-bold pb-6">
                                Forgot Password
                            </h1>
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="email"
                                type="email"
                            />
                            <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="email" />
                        </div>
                        <div className="flex items-center justify-between">
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Send request
                            </button>
                            <Link className="inline-block align-baseline font-bold text-sm text-gray-600 hover:text-gray-700" to="/login">
                                Back to Login
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    )

};
 
export default ResetPassword;