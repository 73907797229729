import { FireIcon, PlusIcon, ChevronRightIcon, ChevronLeftIcon, DatabaseIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import BrandLarge from '../../assets/fireAI-Logo-Medium.webp'
import { useAuthContext } from '../../libs/contextLib'


const navigation = [
  { name: 'Create Fire', icon: PlusIcon, to: '/fire/create' , exact: true, strict: false},
  { name: 'Fires', icon: FireIcon, to: '/fire' , exact: true, strict: false},
]

export const LeftNavbar = (props: any) => {

  const { org } = useAuthContext()
  const {isOpen, setIsOpen} = props;

  return (
    <div className="flex h-full">
      { isOpen ? (
        <div className={classNames("flex w-56 sm:w-64 flex-col flex-grow border-r border-gray-200 pt-5 pb-3 bg-white overflow-y-auto max-w-sm min-w-min")}>
        <div className="flex items-center flex-shrink-0 px-4 space-y-5">
          <div className="">
            <img
              className="w-auto"
              src={BrandLarge}
              alt="Workflow"
            />
          </div>
        </div>
        <div className="mt-5 flex-grow flex flex-col">
          <nav className="flex-1 bg-white space-y-1" aria-label="Sidebar">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.to}
                exact={item.exact}
                strict={item.strict}
                activeClassName="bg-indigo-50 border-indigo-600 text-indigo-600 border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
                className="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
              >
                <item.icon
                  className="group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                
                {item.name}
              </NavLink>
            ))}
            {org && org === 'Hummingbird' ? (
              <NavLink
              key={`hummingbird-hbview-1`}
              to={`/hbview`}
              exact={true}
              strict={false}
              activeClassName="bg-indigo-50 border-indigo-600 text-indigo-600 border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
              className="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium border-l-4"
            >
              <DatabaseIcon
                className="group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                aria-hidden="true"
              />
              
              HBView Builder
            </NavLink>) : null
            }
          </nav>
        </div>
        { process.env.REACT_APP_VERSION ? (<div className="px-3 text-xs text-gray-400">
          Version: { process.env.REACT_APP_VERSION}
        </div>) : null}
      </div>
      ) : null }
      
      <div className="sm:hidden w-8 h-full bg-white border-r flex justify-center items-center cursor-pointer" onClick={() => setIsOpen((prev: boolean) => !prev)}>
        <div className="flex justify-center items-center">
          <div>
            {isOpen ? <ChevronLeftIcon className="h-4"/> : <ChevronRightIcon className="h-4"/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftNavbar