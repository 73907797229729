import { Redirect, Route, Switch } from "react-router-dom";

import AuthenticatedRoute from '../hoc/AuthenticatedRoute'

import Upload from "../containers/Upload/Upload"
import Uploads from "../containers/Uploads/Uploads"
import Results from "../containers/Results/Results"
import NotFound from "../containers/NotFound/NotFound"

import CreateFire from "../containers/Fire/CreateFire";
import FireDetails from "../containers/Fire/FireDetails";
import FireList from "../containers/Fire/FireList";

import CreateOp from "../containers/OP/CreateOP"
import OPList from "../containers/OP/OPList"
import OPDetails from "../containers/OP/OPDetails"
import { useAuthContext } from "../libs/contextLib";
import CreateMapkit from "../containers/Mapkit/CreateMapkit";
import MapkitDetails from "../containers/Mapkit/MapkitDetails";
import CreateFlight from "../containers/Flight/CreateFlight";
import FlightDetails from "../containers/Flight/FlightDetails";
import EditFire from "../containers/Fire/EditFire";
import HummingbirdRoute from "../hoc/HummingbirdRoute";
import HBViewSelector from "../containers/HBView/HBViewSelector";
import HBViewBuilder from "../containers/HBView/HBViewBuilder";

// import Test from "../containers/Test/Test"

export const AuthRoutes = () => {
    const { authenticated } = useAuthContext();
  return (
    authenticated ? (<Switch>    

        {/* Hummingbird Only Routes */}
        <HummingbirdRoute exact path="/hbview"><HBViewSelector /></HummingbirdRoute>
        <HummingbirdRoute exact path="/hbview/builder/fire/:fireId/op/:opId/flight/:flightId"><HBViewBuilder/></HummingbirdRoute>

        {/* Testing */}
        <AuthenticatedRoute exact path="/drive/upload"><Upload /></AuthenticatedRoute>
        <AuthenticatedRoute exact path="/products"><Results /></AuthenticatedRoute>  
        <AuthenticatedRoute exact path="/uploads"><Uploads /></AuthenticatedRoute>
  
        {/* fire */}
        <AuthenticatedRoute exact path="/fire/create"><CreateFire /></AuthenticatedRoute>
        <AuthenticatedRoute exact path="/fire/:fireId"><FireDetails /></AuthenticatedRoute>
        <AuthenticatedRoute exact path="/fire/:fireId/edit"><EditFire /></AuthenticatedRoute>
        <AuthenticatedRoute exact path="/fire/"><FireList /></AuthenticatedRoute>
  
        {/* fire > operational period */}
        <AuthenticatedRoute exact path="/fire/:fireId/op/create"><CreateOp /></AuthenticatedRoute>
        <AuthenticatedRoute exact path="/fire/:fireId/op/:opId"><OPDetails /></AuthenticatedRoute>
        <AuthenticatedRoute exact path="/fire/:fireId/op/"><OPList /></AuthenticatedRoute>
  
        {/* fire > operational period > vscan */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/vscan/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/vscan/:vscanId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/vscan/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/vscan/:vscanId/delete"></AuthenticatedRoute>
  
        {/*  Fire > operational period > Flight */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/create"><CreateFlight /></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/:flightId"><FlightDetails /></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/:flightId/delete"></AuthenticatedRoute>
  
        {/*  Fire > operational period > Flight > irscan */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/:flightId/irscan/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/:flightId/irscan/:irscanId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/:flightId/irscan/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/flight/:flightId/irscan/:irscanId/delete"></AuthenticatedRoute>
  
        {/* fire > operational period > mapkit */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/create"><CreateMapkit /></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId"><MapkitDetails /></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/delete"></AuthenticatedRoute>
  
        {/* fire > operational period > mapkit > agi */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/agi/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/agi/:agiId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/agi/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/agi/:agiId/delete"></AuthenticatedRoute>
  
        {/* fire > operational period > mapkit > arc */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/arc/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/arc/:arcId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/arc/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/arc/:arcId/delete"></AuthenticatedRoute>
  
        {/* fire > operational period > mapkit > shapefile */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/shapefile/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/shapefile/:shapefileId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/shapefile/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/shapefile/:shapefileId/delete"></AuthenticatedRoute>
  
        {/* fire > operational period > mapkit > pdfmaps */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/pdfmaps/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/pdfmaps/:pdfmapsId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/pdfmaps/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/pdfmaps/:pdfmapsId/delete"></AuthenticatedRoute>
  
        {/* fire > operational period > mapkit > ortho */}
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/ortho/create"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/ortho/:orthoId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/ortho/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/op/:opId/mapkit/:mapkitId/ortho/:orthoId/delete"></AuthenticatedRoute>
  
        {/* fire > deliverable */}
        <AuthenticatedRoute path="/fire/:fireId/deliverable/upload"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/deliverable/:deliverableId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/deliverable/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/deliverable/:deliverableId/delete"></AuthenticatedRoute>
  
        {/* fire > supporting material */}
        <AuthenticatedRoute path="/fire/:fireId/support/upload"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/support/:supportId"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/support/"></AuthenticatedRoute>
        <AuthenticatedRoute path="/fire/:fireId/support/:supportId/delete"></AuthenticatedRoute>
        
        {/* <AuthenticatedRoute path="/test"><Test/></AuthenticatedRoute> */}

        <AuthenticatedRoute path="/"><Redirect to="/fire" /></AuthenticatedRoute>
  
        <Route><NotFound /></Route>
      </Switch>) : null
    
  );
}

export default AuthRoutes;