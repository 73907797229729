import { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";

import {
  CashIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DownloadIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { s3BulkDownload } from "../../libs/fileLib";

import { RegionAbbreviation } from "../../helpers/labels";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setTableItem } from "../../redux/reducers/tableReducer";
import { createNotification, dismissNotification, pushNotification } from "../../redux/reducers/notificationReducer";

export type ColumnType = {
  label: string;
  name: string;
  type: string;
};

export type TableActionType = {
  Icon?: any;
  name: string;
  label: string;
  clickHandler: Function;
};
interface CustomTableProps {
  tableName: string;
  HeaderIcon: any;
  columns: ColumnType[];
  statusNames?: string[];
  rowClickedHandler: any;
  usePagination: boolean;
  setPaginationLimit?: Function;
  paginationLimit?: number;
  showActions: boolean;
  tableKey: string;
  defaultSortBy: string;
  deleteData?: Function;
  identifier: string;
  initialSelectedId?: string | null
}

dayjs.extend(calendar);

export const CustomTable = (props: CustomTableProps) => {
  const {
    tableName,
    columns,
    HeaderIcon,
    statusNames,
    rowClickedHandler,
    usePagination,
    showActions,
    tableKey,
    deleteData,
    identifier,
    initialSelectedId
  } = props;

  const dispatch = useDispatch();

  let data: Array<any> = useSelector(
    (state: RootState) => state.table.data[tableKey]
  );
  let loading: boolean = useSelector(
    (state: RootState) => state.table.loading[tableKey]
  );
  let sortBy: string = useSelector(
    (state: RootState) => state.table.sortBy[tableKey]
  );
  let sortDirection: string = useSelector(
    (state: RootState) => state.table.direction[tableKey]
  );

  useEffect(() => {
    if (data && sortBy && sortDirection) {
      setLastSelectedRow(undefined)
    }
  }, [data, sortBy, sortDirection])

  useEffect(() => {
    if (initialSelectedId) {
      const i = data.findIndex((item: any) => (
        item[identifier] === initialSelectedId
      ))
      setLastSelectedRow(i)
    }
  }, [data, initialSelectedId, identifier])

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [lastSelectedRow, setLastSelectedRow] = useState<number>();

  const rowItemClickedHandler = (item: any, index: number) => {
    setLastSelectedRow(index);
    rowClickedHandler(item);
  }

  const actions = [
    {
      Icon: DownloadIcon,
      label: "Download",
      name: "download",
      clickHandler: async (list: any) => {
        const toDownload: string[] = [];
        const filteredItems = list.map((i: number) => data[i]);

        if (filteredItems.length > 0) {
          filteredItems.forEach((item: any) => {
            toDownload.push(item.s3Key);
          });

          const downloadStartNotification = createNotification(
            "Download Started",
            `Your download request of ${toDownload.length} files has started. Please wait as the files are zipped`,
            "download",
            true
          )
          dispatch(pushNotification(downloadStartNotification))
          setTimeout(() => dispatch(dismissNotification(downloadStartNotification)), 5000);

          s3BulkDownload(toDownload)
            .then(() => {
              setSelectedRows([]);
            })
            .then(() => {
              const downloadNotification = createNotification(
                "Download Started",
                `Your download of ${filteredItems.length} files has completed.`,
                "success",
                true
              )
              dispatch(pushNotification(downloadNotification))
              setTimeout(() => dispatch(dismissNotification(downloadNotification)), 5000);
            })
            .catch((err) => {
              console.error(err);
              const errorNotification = createNotification(
                "Upload Started",
                "Something went wrong when attempting to download files",
                "fail",
                true
              )
              dispatch(pushNotification(errorNotification))
              setTimeout(() => dispatch(dismissNotification(errorNotification)), 5000);
            });
        }
      },
    },
    {
      Icon: TrashIcon,
      label: "Delete Items",
      name: "delete",
      clickHandler: async (list: any) => {
        // const filteredItems = list.filter((_: any, ind: number) => data.includes(ind))
        const filteredItems = list.map((i: number) => data[i]);

        const promises: any = [];

        if (filteredItems.length > 0 && deleteData) {
          filteredItems.forEach((item: any) => {
            promises.push(deleteData(item[identifier], item.s3Key));
          });

          Promise.all(promises)
            .then(() => {
              const newData = data.filter(
                (item: any) => !filteredItems.includes(item)
              );
              dispatch(setTableItem({ key: tableKey, data: newData }));
              setSelectedRows([]);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },
    },
  ];

  const statusStyles = [
    "bg-yellow-100 text-yellow-500", // 0 todo
    "bg-blue-100 text-blue-500", // 1 processing
    "bg-green-100 text-green-500", // 2 completed
  ];

  const rowCheckboxClickedHandler = (i: number) => {
    const selected = [...selectedRows];

    // If index exists - remove it. Otherwise, add it
    const index = selected.indexOf(i);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(i);
    }

    setSelectedRows(selected);
  };

  const setAllCheckboxClickedHandler = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      const selected: number[] = Array.from(Array(data.length).keys());
      setSelectedRows(selected);
    }
  };

  return (
    <Fragment>
      <div className="flex flex-row justify-between items-end">
        <div>
          <h2 className="mx-auto px-4 text-lg leading-6 font-medium text-gray-900">
            {tableName}
          </h2>
        </div>
        {showActions && (
          <Menu as="div" className="relative">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button
                    className={classNames(
                      selectedRows.length === 0
                        ? "opacity-50 cursor-default"
                        : "",
                      "max-w-xs disabled bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                    )}
                    disabled={selectedRows.length === 0}
                  >
                    <span className="hidden ml-3 text-gray-700 text-sm lg:block">
                      <span className="sr-only">Open </span>Actions
                    </span>
                    <ChevronDownIcon
                      className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
                  >
                    {actions.map((action: TableActionType, index: number) => {
                      return (
                        <Menu.Item key={`action-${index}`}>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "flex flex-row space-x-2 px-4 py-2 text-sm text-gray-600 w-full text-left"
                              )}
                              onClick={() => action.clickHandler(selectedRows)}
                            >
                              {action.Icon ? (
                                <action.Icon
                                  className="flex-shrink-0 h-5 w-5 text-gray-600 group-hover:text-gray-700"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="pl-2">{action.label}</div>
                            </button>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        )}
      </div>
      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-gray-200 overflow-x-hidden shadow sm:hidden">
          {!loading &&
            tableKey &&
            data &&
            data.length > 0 &&
            data.map((item: any, i: number) => (
              <li key={`${tableName}-${i}`}>
                <button
                  type="button"
                  onClick={() => rowItemClickedHandler(item, i)}
                  className="block px-4 py-4 bg-white hover:bg-gray-50 cursor-pointer w-full"
                >
                  <span className="flex items-center space-x-4">
                    <span className="flex-1 flex space-x-2 truncate">
                      <CashIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="flex flex-col text-gray-500 text-sm truncate">
                        {columns.map((col: ColumnType, j: number) => {
                          if (col.type === "header") {
                            return (
                              <span
                                key={`${tableName}-${i}-${j}-${col.name}`}
                                className="truncate"
                              >
                                {item[col.name]}
                              </span>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </span>
                    </span>
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </button>
              </li>
            ))}
        </ul>
        {usePagination ? (
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </button>
              <button
                type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </button>
            </div>
          </nav>
        ) : null}
      </div>

      <div className="hidden sm:block">
        <div className="">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow sm:rounded-lg">
              <table
                className={classNames(
                  "min-w-full divide-y divide-gray-200 table-fixed",
                  { "animate-pulse": loading }
                )}
              >
                <thead>
                  <tr>
                    {showActions && !loading && data !== undefined && data.length > 0 ? (
                      <th scope="col" className="bg-gray-50 px-6 py-3">
                        <input
                          id={`${tableName}-select-all`}
                          name={`${tableName}-select-all`}
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          checked={selectedRows.length === data.length}
                          onChange={() => setAllCheckboxClickedHandler()}
                        />
                      </th>
                    ) : null}
                    {columns.map((col: ColumnType, h: number) => (
                      <th
                        key={`${tableName}-${h}-${col.name}`}
                        className={classNames(
                          "px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
                          {
                            "w-1/2": col.type === "header",
                            "md:table-cell text-left": col.type === "status",
                          }
                        )}
                        scope="col"
                      >
                        <div className="inline-flex">
                          {col.label}
                          {sortBy && sortDirection && sortBy === col.name ? (
                            sortDirection === "ASC" ? (
                              <ChevronUpIcon className="h-4" />
                            ) : (
                              <ChevronDownIcon className="h-4" />
                            )
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {!loading && data !== undefined ? (
                    data.length > 0 ? (
                      data.map((item: any, i: number) => (
                        <tr key={`${tableName}-${i}`} className={classNames("bg-white", {"bg-gray-100 text-gray-800 underline": lastSelectedRow === i})}>
                          {showActions ? (
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <input
                                id={`${tableName}-${i}`}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                name={`${tableName}-${i}`}
                                type="checkbox"
                                checked={selectedRows.includes(i)}
                                onChange={() => rowCheckboxClickedHandler(i)}
                              />
                            </td>
                          ) : null}
                          {columns.map((col: ColumnType, j: number) => {
                            if (col.type === "header") {
                              return (
                                <td
                                  key={`${tableName}-${i}-${j}-${col.name}`}
                                  className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900 hover:underline"
                                  onClick={() => rowItemClickedHandler(item, i)}
                                >
                                  <div className="flex">
                                    <button
                                      className="group inline-flex space-x-2 truncate text-sm w-full cursor-pointer"
                                    >
                                      <HeaderIcon
                                        className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      <p className="text-gray-500 truncate group-hover:text-gray-900">
                                        {item[col.name]}
                                      </p>
                                    </button>
                                  </div>
                                </td>
                              );
                            } else if (col.type === "date") {
                              return (
                                <td
                                  key={`${tableName}-${i}-${j}-${col.name}`}
                                  className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                                >
                                  <time>{dayjs(item[col.name]).calendar()}</time>
                                </td>
                              );
                            } else if (col.type === "status" && statusNames) {
                              return (
                                <td
                                  key={`${tableName}-${i}-${j}-${col.name}`}
                                  className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block"
                                >
                                  <span
                                    className={classNames(
                                      statusStyles[item[col.name]],
                                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                                    )}
                                  >
                                    {statusNames[item[col.name]]}
                                  </span>
                                </td>
                              );
                            } else if (col.type === "region") {
                              return (
                                <td
                                  key={`${tableName}-${i}-${j}-${col.name}`}
                                  className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                                >
                                  {}
                                  {RegionAbbreviation.get(item[col.name])}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={`${tableName}-${i}-${j}-${col.name}`}
                                  className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500"
                                >
                                  <span className="text-gray-900 font-medium">
                                    {item.amount}
                                  </span>
                                  {item[col.name]}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-white">
                        {columns.map((col: ColumnType, k: number) => {
                          return (
                            <td
                              key={`${col.name}-${k}-nd`}
                              className="w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                            >
                              <div className="flex">
                                <div className="h-4 rounded">
                                  {k === 0 ? "No records found." : null}
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    )
                  ) : (
                    <tr className="bg-white">
                      {columns.map((col: ColumnType, k: number) => {
                        return (
                          <td
                            key={`${col.name}-${k}-loading`}
                            className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900 hover:underline animate-pulse"
                          >
                            <div className="flex">
                              <div className="h-4 bg-gray-300 rounded w-3/4" />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomTable;
