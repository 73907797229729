import React, { useState } from 'react';
import * as yup from 'yup';
import { Form, Field, ErrorMessage, Formik  } from 'formik';
import { Link, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify';

export const ResetPasswordConfirm = (props: any) => {

    const history = useHistory()

    const [error, setError] = useState<string | null>(null)

    const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    code: yup
        .string()
        .required('Code is required'),
    password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    confirmpassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    });

    return (
        <Formik
            initialValues={{ email: '', code: '', password: '', confirmpassword: '' }}
            validationSchema={validationSchema}
            onSubmit={values => {
                Auth.forgotPasswordSubmit(values.email, values.code, values.password)
                    .then(() => {
                        setError(null)
                        history.push('/login')
                    })
                    .catch(err => {
                        setError(err.message)
                    });
            }}
        >
            <div className="flex justify-center items-center mt-8">
                <div className="w-full max-w-xs">
                    <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div>
                            <h1 className="text-center text-xl font-bold pb-6">
                                Confirm Forgot Password
                            </h1>
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="email"
                                type="email"
                            />
                            <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="email" />
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="code">
                                Code
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="code"
                                type="text"
                            />
                            <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="code" />
                        </div>
                        <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    Password
                                </label>
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                />
                                <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="password" />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmpassword">
                                    Confirm Password
                                </label>
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="confirmpassword"
                                    type="password"
                                    autoComplete="new-password"
                                />
                                <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="confirmpassword" />
                            </div>
                        <div className="flex items-center justify-between">
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Confirm
                            </button>
                            <Link className="inline-block align-baseline font-bold text-sm text-gray-600 hover:text-gray-700" to="/login">
                                Back to Login
                            </Link>
                        </div>
                        <div>
                            {error ? <p className="text-red-500 pt-5 px-1">{error}</p> : null}
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    )

};
 
export default ResetPasswordConfirm;