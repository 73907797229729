import { useEffect, Fragment } from 'react';


import { useAppDispatch } from '../../hooks/hooks'


const Results = (props: any) => {

    // const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch()
    // const data = useAppSelector((state) => state.data.value)

    /**
     * Get all files from S3 when authenticated state or current directory changes
     */
     useEffect(() => {
        // dispatch(getFiles('PRODUCT/'));
        // setLoading(false);
  
    }, [dispatch]);

    return (
        <Fragment>   
            <h1 className="py-4 text-gray-700 font-bold text-3xl text-center w-full">PRODUCT</h1>
            {/* <FileDrive data={data} dataLoading={loading} location="PRODUCT/"/> */}
        </Fragment>

    )
}

export default Results
