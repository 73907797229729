/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { ArrowCircleDownIcon, ArrowCircleUpIcon, CheckCircleIcon, ExclamationCircleIcon, MinusCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { dismissNotification, NotificationItemState } from '../../redux/reducers/notificationReducer'

export default function CustomNotification() {

  const notifications: Array<any> = useSelector((state: RootState) => state.notification.notifications);
  const show: boolean = useSelector((state: RootState) => state.notification.show);

  const dispatch: AppDispatch = useDispatch()

  const dismissNotificationHandler = (notification: NotificationItemState) => {
    dispatch(dismissNotification(notification))
  }

  const notificationIconHandler = (notificationType: string) => {
    switch(notificationType) {
      case "upload":
        return <ArrowCircleUpIcon className={`h-6 w-6 text-blue-400`} aria-hidden="true" />
      case "download":
        return <ArrowCircleDownIcon className={`h-6 w-6 text-blue-400`} aria-hidden="true" />
      case "info":
        return <ExclamationCircleIcon className={`h-6 w-6 text-blue-400`} aria-hidden="true" />
      case "success":
        return <CheckCircleIcon className={`h-6 w-6 text-green-400`} aria-hidden="true" />
      case "fail":
        return <MinusCircleIcon className={`h-6 w-6 text-red-400`} aria-hidden="true" />
      case "error":
        return <MinusCircleIcon className={`h-6 w-6 text-red-400`} aria-hidden="true" />
      default:
        return <ExclamationCircleIcon className={`h-6 w-6 text-blue-400`} aria-hidden="true" />
    }
  }


  return (
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {
            notifications && notifications.map((notification: NotificationItemState) => (
              <Transition
                key={notification.id}
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          {notificationIconHandler(notification.type)}
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-semibold text-gray-900">{notification.label}</p>
                          <p className="mt-1 text-sm text-gray-500">{notification.message}</p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            className="bg-white rounded-md text-sm inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              dismissNotificationHandler(notification)
                            }}
                          >
                            <span className="sr-only">Close</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </Transition>
            ))
          }
        </div>
      </div>
  )
}