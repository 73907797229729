import React from 'react'

const NotFound = () => {
    return (
        <div className="flex justify-center ">
            <div className="text-5xl font-bold mt-10  w-full h-full items-center">
                Sorry, page not found!
            </div>
        </div>
        
    )
}

export default NotFound
