import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import CustomBreadcrumbs, { BreadcrumbItem } from '../../components/Breadcrumbs/CustomBreadcrumbs';


import CustomForm, { FormItem } from '../../components/Form/CustomForm';
import { addOperationalPeriodDB, getFireDB } from '../../libs/dynamoLib';
import { FireInterface, OPInterface, OPReq } from '../../model/types';


/**
 * 
 * Allow the user to create a fire. A successful creation will add the data to DynamoDB
 * And will redirect to the created fire
 * 
 * @returns A form with a field for name and date.
 */
const CreateOP = () => {

    const params: {fireId: string} = useParams();
    const history = useHistory();

    // const [ error, setError ] = useState({});
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ fireNumber, setFireNumber ] = useState<string>('')

    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

    const initialValues = {date: new Date()};

    useEffect(() => {
        
        if (params.fireId) {
            getFireDB(params.fireId)
            .then((res: FireInterface) => {
                setFireNumber(res.number);

                setBreadcrumbs([
                    {
                      name: 'Fire',
                      to: `/fire/${params.fireId}`
                    }
                  ])

                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            })
        }
        

    }, [params.fireId])

    const formData: FormItem[] = [
        {
            label: 'Date',
            name: 'date',
            type: 'datepicker',
            id: 'date',
            autocomplete: null,
            placeholder: "Optional"
        },
        
    ]


    const validationSchema = yup.object({
        date: yup
            .string()
            .required(),
    });

    const submitHandler = (values: any) => {

        const payload: OPReq = {
            fireId: params.fireId,
            name: `${dayjs(values.date).format('YYYY-MM-DD')} - ${fireNumber}`,
            date: dayjs(values.date).format('YYYY-MM-DD'),
            status: 0
        }

        addOperationalPeriodDB(payload)
            .then((res: OPInterface) => {
                history.push(`/fire/${params.fireId}/op/${res.opId}`);
            })
            .catch(err => {
                console.error(err.message);
            })
    }

    return (
        <Fragment>
            <CustomBreadcrumbs crumbs={breadcrumbs}/>
            <div className="mt-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            {!loading ? (
                <CustomForm
                    title="Create Operational Period"
                    subtitle="Let’s get started by filling in the information below to create a new operational period."
                    formData={formData}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    submitHandler={(values: any) => submitHandler(values)}
                    cancelHandler={() => history.push(`/fire/${params.fireId}`)}
                    loading={false}
                />
            ) : <p>Loading...</p>}
            
        </div>
        </Fragment>
        
    )
}

export default CreateOP
