import { hslToRgb } from "../../tools/imageTools";

export type Color = {
  color: string,
  r: number,
  g: number,
  b: number,
  a: number,
}

export const imageDataToImageURL = (imagedata: ImageData, width: number, height: number) => {
  var canvas = document.createElement('canvas');
  var ctx: any = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.putImageData(imagedata, 0, 0);

  return canvas.toDataURL();
}

export function actionDraw(ctx: any, coordX: number, coordY: number, currentColor: Color) {
  ctx.fillStyle = currentColor;
  ctx.fillRect(coordX, coordY, 1, 1);
}

export function actionFill(startX: number, startY: number, currentColor: Color, src: ImageData, tar: ImageData, fillOffset: number | null = null, force: boolean = false): {"imageData": ImageData, fillPoints: number[]} {

  console.log(new Uint8ClampedArray(src.data))
  console.log(new Uint8ClampedArray(tar.data))

  // Clone 
  let srcLayer: ImageData = new ImageData(new Uint8ClampedArray(src.data), src.width, src.height);
  let targetLayer: ImageData = new ImageData(new Uint8ClampedArray(tar.data), tar.width, tar.height);
  
  let startPos = (startY * srcLayer.width + startX) * 4;

  //clicked color
  let startR = srcLayer.data[startPos];	
  let startG = srcLayer.data[startPos+1];	
  let startB = srcLayer.data[startPos+2];
  let startA = srcLayer.data[startPos+3];

  // exit if color is the same
  if (currentColor.r === startR && currentColor.g === startG && currentColor.b === startB && currentColor.a === startA) {
    console.warn('Start color is the same as the desired color')
    return {"imageData": targetLayer, "fillPoints": []};
  }

  // exit if color is the same
  if (!force && startR < 50 && startG < 50 && startB < 50) {
    console.log(`r: ${startR}, g: ${startG}, b: ${startB}`)
    console.warn('Color values are less than 50. This is a safety procaution just in case the user misclicks the background and the call stack is exceeded')
    return {"imageData": targetLayer, "fillPoints": []};
  }

  //Start with click coords
  let pixelStack: Array<[number, number]> = [[startX, startY]];
  let newPos: any, x: number, y: number, pixelPos: number, reachLeft: boolean, reachRight: boolean;
  
  let pixelHistory: number[] = []

  floodFill();

  return {"imageData": targetLayer, "fillPoints": pixelHistory}

  function floodFill() {
    
    newPos = pixelStack.pop();
    x = newPos[0];
    y = newPos[1];
    
    //get current pixel position
    pixelPos = (y * srcLayer.width + x) * 4;
    // Go up as long as the color matches and are inside the canvas
    while(y-- >= 0 && matchStartColor(pixelPos)) {
        pixelPos -= srcLayer.width * 4;
    }
    // Don't overextend
    pixelPos += srcLayer.width * 4;
    ++y;
    reachLeft = false;
    reachRight = false;

    // Go down as long as the color matches and in inside the canvas
    while(y++ < srcLayer.height-1 && matchStartColor(pixelPos)) {
        colorPixel(pixelPos);
        pixelHistory.push(pixelPos);
        if(x > 0) {
            if(matchStartColor(pixelPos - 4)) {
                if(!reachLeft) {
                    //Add pixel to stack
                    pixelStack.push([x - 1, y]);
                    reachLeft = true;
                }
            } else if(reachLeft) {
                reachLeft = false;
            }
        }
    
        if(x < srcLayer.width-1) {
            if(matchStartColor(pixelPos + 4)) {
                if(!reachRight) {
                    // Add pixel to stack
                    pixelStack.push([x + 1, y]);
                    reachRight = true;
                }
            } else if(reachRight) {
                reachRight = false;
            }
        }    
        pixelPos += srcLayer.width * 4;
    }

    
    if (pixelStack.length) {
        floodFill();
        // window.setTimeout(floodFill, 100);
    }
  }

  //helpers
  function matchStartColor(pixelPos: number) {
    let r = srcLayer.data[pixelPos];	
    let g = srcLayer.data[pixelPos+1];	
    let b = srcLayer.data[pixelPos+2];
    let a = srcLayer.data[pixelPos+3];

    if (fillOffset !== null || fillOffset === 0) {
      
      return (
        (r > startR-fillOffset && r < startR+fillOffset)
        && (g > startG-fillOffset && g < startG+fillOffset)
        && (b > startB-fillOffset && b < startB+fillOffset)
        && (a === startA)
      )

    }

    return (r === startR && g === startG && b === startB && a === startA);
  }

  function colorPixel(pixelPos: number) {
    targetLayer.data[pixelPos] = currentColor.r;
    targetLayer.data[pixelPos+1] = currentColor.g;
    targetLayer.data[pixelPos+2] = currentColor.b;
    //not ideal
    targetLayer.data[pixelPos+3] = currentColor.a;

    srcLayer.data[pixelPos] = currentColor.r;
    srcLayer.data[pixelPos+1] = currentColor.g;
    srcLayer.data[pixelPos+2] = currentColor.b;
    //not ideal
    srcLayer.data[pixelPos+3] = currentColor.a;
  }
}

/**
 * Draw the supplied path on the canvas with the supplied color
 */
export const fillPixels = (cvs: any, pixelIndices: number[], color: Color, ratio: number = 1) => {

  if (pixelIndices.length === 0) {
    return
  }

  const ctx = cvs.getContext('2d');
  
  ctx.fillStyle = color.color

  // const height = cvs.height;
  const width = cvs.width;
  let x: number, y:number, p: number
  if (ctx) {
    for (let i = 0 ; i < pixelIndices.length ; i++ ) {  
      p = (pixelIndices[i] / 4) // Indices are from imageData (rgba)
      
      x = p % width
      y = Math.floor(p / width)

      ctx.fillRect(x, y, ratio, ratio)
    }
  }
}

export function actionLine(sx: number, sy: number, tx: number, ty: number, currentColor: Color, ctx: any, scale: number = 1) {
    
  ctx.fillStyle = currentColor.color;

  let tri: {x?: number, y?: number, long?: number} = {}
  
  let drawPixel = (x: number, y: number, w: number, h: number) => {
    return ctx.fillRect(x, y, w, h)
  };

  function getTriangle(x1: number, y1: number, x2: number, y2: number, ang: number) {
      if (Math.abs(x1-x2) > Math.abs(y1-y2)) {
          tri.x = Math.sign(Math.cos(ang));
          tri.y = Math.tan(ang)*Math.sign(Math.cos(ang));
          tri.long = Math.abs(x1-x2);
      } else { 
          tri.x = Math.tan((Math.PI / 2)-ang) * Math.sign(Math.cos((Math.PI / 2) - ang));
          tri.y = Math.sign(Math.cos((Math.PI / 2) - ang));
          tri.long = Math.abs(y1 - y2);
      }
  }

  // finds the angle of (x,y) on a plane from the origin
  function getAngle(x: number, y: number) {
      return Math.atan(y / (x === 0 ? 0.01 : x)) + (x < 0 ? Math.PI : 0);
  }
      
  let angle = getAngle(tx - sx,ty - sy); // angle of line
      
  getTriangle(sx, sy, tx, ty, angle);

  if (tri.long !== undefined && tri.x !== undefined && tri.y !== undefined) {
      for(let i = 0 ; i < tri.long ; i++) {
          
          let thispoint = {
              x: Math.round(sx + tri.x * i),
              y: Math.round(sy + tri.y*i)
          };

          // for each point along the line
          drawPixel(
            thispoint.x*scale, // round for perfect pixels
            thispoint.y*scale, // thus no aliasing
            scale,
            scale
          )
      }
  }
  

  //fill endpoint
  drawPixel(
    Math.round(tx) * scale, // round for perfect pixels
    Math.round(ty) * scale, // thus no aliasing
    scale,
    scale
  ); // fill in one pixel, 1x1
}

/**
 * Get the pixel color of a source image given an x and y-axis pixel location
 * @param x x-axis pixel position
 * @param y y-axis pixel position
 * @param img source image
 * @returns object of type Color
 */
export const getPixelColor = (x: number, y: number, img: any) => {

  let canvas: any = document.createElement('canvas');
  let context = canvas.getContext('2d');

  canvas.width = img.width;
  canvas.height = img.height;
  context.drawImage(img, 0, 0);
 
  let d = context.getImageData(x, y, 1, 1).data;

  let color: Color = {
    color: `rgba(${d[0]},${d[1]}, ${d[2]}, ${d[3]/255})`,
    r: d[1],
    g: d[1],
    b: d[2],
    a: d[3]
  }

  return color
}

export function getFilledPixelIndices(arr: Uint8ClampedArray, val: number) {
  let indexes = [];
  for(let i = 0; i < arr.length; i+=4)
      if (arr[i] === val) {
        indexes.push(i);
      }
          
  return indexes;
}

/**
 * Generate a random bright color
 * @param a Alpha value for HSLA
 * @returns Randomized color
 */
export const generateRandomColor = (a: number): {h: number, s: number, l: number, color: Color} => {

  // hsl generates better and brighter colors
  let h = Math.floor((Math.random() * 360)); // All color angles
  let s = Math.floor((Math.random() * 10)) + 90; // 90-100% saturation
  let l = Math.floor((Math.random() * 20)) + 40; // 40-60% lightness

  const [gr, gg, gb] = hslToRgb(h/360, s/100, l/100);
  
  let RandomColor: Color = {color: `hsla(${h}, ${s}%, ${l}%, ${a})`, r: gr, g: gg, b: gb, a: a*255}

  return {
    h, s, l, color: RandomColor
  }
}

/**
 * get hsl color representation for hsl values.
 * @param hue Start index
 * @param a Alpha value for HSLA. Default 1
 * @returns Hue color
 */
 export const getHSLColor = (hue: number, saturation: number = 100, lightness: number = 50, alpha: number = 1.0): Color | null => {

  if (hue < 0 || hue > 360 || saturation < 0 || saturation > 100 || lightness < 0 || lightness > 100 || alpha < 0 || alpha > 1) {
    return null
  }

  // hsl generates better and brighter colors
  let h = Math.floor(hue);
  let s = Math.floor(saturation); // 90-100% saturation
  let l = Math.floor(lightness); // 40-60% lightness

  const [gr, gg, gb] = hslToRgb(h/360, s/100, l/100);
  
  let color: Color = {color: `hsla(${h}, ${s}%, ${l}%, ${alpha})`, r: gr, g: gg, b: gb, a: alpha*255}

  return color
  
}