import classNames from 'classnames'
import React, { PropsWithChildren, useCallback } from 'react'

interface CanvasActionProps {
  clicked: Function
  selected: boolean
}

const CanvasAction = (props: PropsWithChildren<CanvasActionProps>) => {

  const {selected, clicked} = props;

  const clickedHandler = useCallback(() => {
      console.log('Canvas Action Clicked')
      clicked()
    },
    [clicked],
  )

  return (
    <button type="button" className={classNames('py-2 px-4 my-2 border rounded-sm bg-gray-100 font-medium shadow-sm', {'font-bold': selected})} onClick={() => clickedHandler()}>
      {props.children}
    </button>
  )
}

export default CanvasAction
