import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Point = {
  startX: number,
  startY: number,
  endX: number,
  endY: number
}

export type ExternalTableType = {fileName: string, type: string, index: number}

export interface SavedPoints {
  [key: string]: {
    segmentations: Point[][],
    fillPoints: [number, number][]
  }
}

export interface SavedSegmentations {
  [key: string]: number[][]
}

export interface SavedPoint {
  path: Point[],
  fillPoint: [number, number]
}

export type SegmentationPath = [number, number][]

// Define a type for the slice state
interface BuilderState {
  currentFile: string | null
  currentSegmentation: SavedPoint | null
  currentMaskIndices: number[] | null
  savedSegmentationNames: string[]
  savedSegmentations: SavedPoints,
  savedMaskIndices:  {[key: string]: number[][]}
  fileNames: string[]
  fileIndex: number | null
  fileCount: number
  selectedIndex: {fileName: string, type: string, index: number} | null
  drawState: number
  imageScale: number
}

const initialState: BuilderState = {
  currentFile: null,
  currentSegmentation: null,
  currentMaskIndices: null,
  savedSegmentationNames: [],
  savedSegmentations: {},
  savedMaskIndices: {},
  fileNames: [],
  fileIndex: null,
  fileCount: 0,
  selectedIndex: null,
  drawState: 1, // default to fill tool
  imageScale: 1
};


/**
 * Redux slice for controlling the state of the training data builder.
 */
export const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    reinitialize: (state) => {
      state = Object.assign(state, initialState);
  },
    setFileNames: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.fileNames = action.payload
    },
    setFileCount: (
      state,
      action: PayloadAction<number>
    ) => {
      state.fileCount = action.payload
    },
    setSelectedIndex: (
      state,
      action: PayloadAction<{fileName: string, type: string, index: number} | null>
    ) => {
      state.selectedIndex = action.payload;
    },
    setCurrentSegmentationPath: (
      state,
      action: PayloadAction<{segmentation: SavedPoint | null, fileName: string | null}>
    ) => {
      state.currentFile = action.payload.fileName
      state.currentSegmentation = action.payload.segmentation
    },
    setCurrentMaskIndices: (
      state,
      action: PayloadAction<{indices: number[] | null, fileName: string | null}>
    ) => {
      state.currentFile = action.payload.fileName
      state.currentMaskIndices = action.payload.indices
    },
    addSegmentationPath: (
      state,
      action: PayloadAction<{segmentationPoints: Point[], fillPoint: [number, number], fileName: string}>
    ) => {

      // Save segmentation path and fill point.

      if (state.savedSegmentations[action.payload.fileName] !== undefined) {
        // exists
        const currentSegmentations: Array<Point[]> = state.savedSegmentations[action.payload.fileName]['segmentations'];
        const currentFillpoints: Array<[number, number]> = state.savedSegmentations[action.payload.fileName]['fillPoints'];

        state.savedSegmentations[action.payload.fileName] = {
          segmentations: [...currentSegmentations, action.payload.segmentationPoints],
          fillPoints: [...currentFillpoints, action.payload.fillPoint]
        }
      } else {
        // does not exist yet.
        state.savedSegmentations[action.payload.fileName] = {
          segmentations: [action.payload.segmentationPoints],
          fillPoints: [action.payload.fillPoint]
        }
      }

    },
    addMaskIndices: (state, action: PayloadAction<{maskIndices: number[], fileName: string}>) => {
      // Save indices of the current mask. If the file already has a mask, append it, otherwise initialize and save
      if (state.savedMaskIndices[action.payload.fileName] !== undefined) { // exists
        const currentMaskIndices: number[][] = state.savedMaskIndices[action.payload.fileName];
        state.savedMaskIndices[action.payload.fileName] = [...currentMaskIndices, action.payload.maskIndices]
      } else {
        state.savedMaskIndices[action.payload.fileName] = [action.payload.maskIndices]
      }
    },
    removeSegmentation: (
      state,
      action: PayloadAction<{index: number, fileName: string, type: string}>
    ) => {
      switch(action.payload.type) {
        case 'Fill':
          state.savedMaskIndices[action.payload.fileName].splice(action.payload.index, 1);
          break;  
        case 'Outline':
          state.savedSegmentations[action.payload.fileName]['segmentations'].splice(action.payload.index, 1);
          state.savedSegmentations[action.payload.fileName]['fillPoints'].splice(action.payload.index, 1);
          break;
        default:
          break;
      }
      
    },
    setFileIndex: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.fileIndex = action.payload
    },
    incrementFileIndex: (
      state
    ) => {
      if (state.fileIndex !== null) {
        state.fileIndex = Math.min(state.fileIndex + 1, (state.fileCount - 1 > 0 ? state.fileCount - 1  : 0));
      }
    },
    decrementFileIndex: (
      state
    ) => {
      if (state.fileIndex !== null) {
        state.fileIndex = Math.max(state.fileIndex - 1, 0);
      }
    },
    setDrawState: (
      state,
      action: PayloadAction<number>
    ) => {
      state.drawState = action.payload
    },
    setImageScale: (
      state,
      action: PayloadAction<number>
    ) => {
      state.imageScale = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  reinitialize,
  setFileNames,
  setFileCount,
  setFileIndex,
  addSegmentationPath,
  removeSegmentation,
  setCurrentSegmentationPath,
  incrementFileIndex,
  decrementFileIndex,
  setSelectedIndex,
  setDrawState,
  setCurrentMaskIndices,
  addMaskIndices,
  setImageScale
} = builderSlice.actions;

export default builderSlice.reducer;
