import { Fragment } from 'react';


const Uploads = (props: any) => {

    
    // const dispatch = useAppDispatch()
    // const data = useAppSelector((state) => state.data.value)

    /**
     * Get all files from S3 when authenticated state or current directory changes
     */
    //  useEffect(() => {
    //     // dispatch(getFiles('DATA/'));
    //     // setLoading(false);
  
    // }, [dispatch]);

    return (
        <Fragment>
            <h1 className="py-4 text-gray-700 font-bold text-3xl text-center w-full">DATA</h1>
            {/* <FileDrive data={data} dataLoading={loading} location="DATA/"/> */}
        </Fragment>

    )
}

export default Uploads
