import React, { Fragment, useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';

import { DocumentIcon, FolderIcon, PaperAirplaneIcon } from '@heroicons/react/solid';

import CustomTable, { ColumnType } from '../../components/Table/CustomTable';
import { DeliverableInterface, FlightInterface,  MapkitInterface, OPInterface } from '../../model/types';

import {
  getOperationalPeriodDB,
  listOPDeliverableDB,
  listOPFlightTypeDB,
  listOPMapkitDB,
  removeDeliverableDB,
  updateOperationalPeriodDB
} from '../../libs/dynamoLib';

import { statusNames as stsNm } from '../../helpers/labels'

import { supportedDeliverableType } from '../../helpers/supportedFileTypes';
import { FormItem } from '../../components/Form/CustomForm';

import * as yup from 'yup';
import DetailHeader from '../../components/Header/DetailHeader';
import CustomBreadcrumbs, { BreadcrumbItem } from '../../components/Breadcrumbs/CustomBreadcrumbs';
import { s3RemoveFile } from '../../libs/s3Lib';
import { setFields, setInitialValues, setLoading as setDetailsLoading } from '../../redux/reducers/detailReducer';
import { initializeTableItem, setLoading as setTableLoading } from '../../redux/reducers/tableReducer';
import { initializeUploader, setModalOpenState } from '../../redux/reducers/uploadReducer';

const statusNames = [
  'New',
  'Processing',
  'Completed'
]

const flightColumns: ColumnType[] = [
  {label: 'Number', name: "flightNum", type: "header"},
  {label: 'Name', name: "name", type: "text"},
  {label: 'Date', name: "date", type: "date"},
  {label: 'Created At', name: "createdAt", type: "date"},
  {label: 'Modified At', name: "modifiedAt", type: "date"}
]

const mapkitColumns: ColumnType[] = [
  {label: 'Name', name: "name", type: "header"},
  {label: 'Created At', name: "createdAt", type: "date"},
  {label: 'Modified At', name: "modifiedAt", type: "date"}
]

const deliverableColumns: ColumnType[] = [
  {label: 'Name', name: "name", type: "header"},
  {label: 'Created At', name: "createdAt", type: "date"},
  {label: 'Modified At', name: "modifiedAt", type: "date"}
]

const validationSchema = yup.object({
  name: yup
      .string(),
  date: yup
      .string()
      .required(),
  status: yup
      .string()
      .required()
});

const formData: FormItem[] = [
  {
      label: 'Name',
      name: 'name',
      type: 'text',
      id: 'name',
      autocomplete: null,
      placeholder: 'Optional',
  },
  {
    label: 'Date',
    name: 'date',
    type: 'datepicker',
    id: 'date',
    autocomplete: null,
    placeholder: 'Required',
  },
  {
      label: 'Status',
      name: 'status',
      type: 'select',
      id: 'status',
      autocomplete: null,
      placeholder: null,
      opt: [
          {
              label: "New",
              name: 0
          }, {
              label: "In Progress",
              name: 1
          }, {
              label: "Done",
              name: 2
          }
      ]
  }
]


const OPDetails = () => {
    
  const params: {fireId: string, opId: string} = useParams();

  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const detailsLoading: boolean = useSelector((state: RootState) => state.detail.loading);

  const [fireId, setFireId] = useState<string>();
  const [opId, setOpId] = useState<string>();
  const [opName, setOpName] = useState<string | null>(null);
  const [opDate, setOpDate] = useState<string>('');

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const flightRowClicked = (data: any) => {
    history.push(`/fire/${data.fireId}/op/${data.opId}/flight/${data.flightId}`)
  }

  const getOP = useCallback(() => {
    if (fireId && opId) {
      dispatch(setDetailsLoading(true));
      getOperationalPeriodDB(fireId, opId)
        .then((res: OPInterface) => {
          dispatch(setFields([
            {
              label: 'Name',
              value: res.name,
              type: 'text'
            },
            {
              label: 'Date',
              value: dayjs(res.date).format('YYYY/MM/DD'),
              type: 'text'
            },
            {
              label: 'Status',
              value: stsNm.get(res.status),
              type: 'status'
            }
          ]))
        
          return res
        })
        .then((res: OPInterface) => {
          setOpName(res.name);
          setOpDate(res.date);
  
          dispatch(setInitialValues({
            name: res.name,
            date: dayjs(res.date).format('YYYY/MM/DD'),
            status: res.status
          }));
  
        })
        .then(() => {
          dispatch(setDetailsLoading(false))
        })
        .catch(err => {
          console.error(err);
          dispatch(setDetailsLoading(false))
        })
    }
  
    
    },[fireId, opId, dispatch]
  )

  const updateOPHandler = (values: any) => {
    if (fireId && opId) {
      dispatch(setDetailsLoading(true))
      const data = {
        name: values.name,
        status: parseInt(values.status),
        date: values.date,
      }

      updateOperationalPeriodDB(fireId, opId, data)
          .then(() => {
            getOP()
          })
          .then(() => {
            dispatch(setDetailsLoading(false))
          })
          .catch(err => {
              console.error(err);
          })
    }  
  }

  useEffect(() => {
    if (opId) {
      dispatch(setTableLoading({
        key: "irscan",
        loading: true
      }));
      listOPFlightTypeDB(opId, 1)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "irscan",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })
    }
  }, [opId, dispatch])

  useEffect(() => {
    if (opId) {
      dispatch(setTableLoading({
        key: "vscan",
        loading: true
      }));
      listOPFlightTypeDB(opId, 0)
        .then((res: any) => {
            dispatch(initializeTableItem({
              key: "vscan",
              data: res.Items,
              sortBy: "modifiedAt",
              sortDirection: "DESC",
            }));
        })
        .catch((err:any) => {
          console.error(err)
        })
    }
  }, [opId, dispatch])

  useEffect(() => {
    if (opId) {
      dispatch(setTableLoading({
        key: "mapkit",
        loading: true
      }));
      listOPMapkitDB(opId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "mapkit",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })
    }
  }, [opId, dispatch])

  useEffect(() => {
    if (opId) {
      dispatch(setTableLoading({
        key: "deliverable",
        loading: true
      }));
      listOPDeliverableDB(opId)
        .then((res: any) => {
          dispatch(initializeTableItem({
              key: "deliverable",
              data: res.Items,
              sortBy: "modifiedAt",
              sortDirection: "DESC",
            }));
        })
        .catch((err:any) => {
          console.error(err)
        })
    }
  }, [opId, dispatch])

  useEffect(() => {
    setFireId(params.fireId);
    setOpId(params.opId);
    setBreadcrumbs([
      {
        name: 'Fire',
        to: `/fire/${params.fireId}`
      },
      {
        name: 'Operational Period',
        to: `/fire/${params.fireId}/op/${params.opId}`
      }
    ])
    
  }, [params.fireId, params.opId])

  const openUploadModalHandler = (dest: string) => {
  
    dispatch(
      initializeUploader({
        tableKey: dest,
        validFileTypes: supportedDeliverableType,
        dbPath: `/${dest}`,
        s3Path: `fire/${fireId}/support/`,
        verificationPath: `/op/${opId}/${dest}`,
        payloadData: {fireId: fireId, opId: opId},
      })
    );

    dispatch(setModalOpenState(true));

  }
  
  useEffect(() => {
    if (fireId && opId) {
      getOP()
    }
  }, [fireId, opId, getOP])

  const deleteDeliverableHandler = (opId: string, tableItemId: string, s3Key: string) => {
    if (opId)
      removeDeliverableDB(opId, tableItemId)
        .then(() => {
          s3RemoveFile(s3Key)
        })
        .catch(err => {
          console.error(err);
        })
  }

  return (
    <Fragment>
      <CustomBreadcrumbs crumbs={breadcrumbs}/>
      <div className="flex">
        <div className="flex flex-col w-0 flex-1">
        <main className="py-10">
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{opName}</h1>
                <p className="text-sm font-medium text-gray-500">{dayjs(opDate).format('YYYY/MM/DD')}</p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => history.push(`/fire/${fireId}/op/${opId}/flight/create`)}
              >
                Add Flight
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => history.push(`/fire/${fireId}/op/${opId}/mapkit/create`)}
              >
                Add Mapkit
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => openUploadModalHandler('deliverable')}
              >
                Add Deliverable
              </button>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              
              <DetailHeader
                submitHandler={(values: any) => updateOPHandler(values)}
                title='Operational Period Details'
                subtitle=''
                expectedFields={["Date", "Status"]}
                validationSchema={validationSchema}
                formData={formData}
              />
              { !detailsLoading && opId ? (
                <Fragment>
                  <section>
                    <CustomTable
                      tableName="Infrared Scan Flights"
                      identifier="irscanId"
                      HeaderIcon={PaperAirplaneIcon}
                      columns={flightColumns}
                      statusNames={statusNames}
                      rowClickedHandler={(item: FlightInterface) => flightRowClicked(item)}
                      showActions={false}
                      usePagination={false}
                      tableKey="irscan"
                      defaultSortBy="flightNum"
                    />
                  </section>
                  <section>
                    <CustomTable
                      tableName="Visual Scan Flights"
                      identifier="vscanId"
                      HeaderIcon={PaperAirplaneIcon}
                      columns={flightColumns}
                      statusNames={statusNames}
                      rowClickedHandler={(item: FlightInterface) => flightRowClicked(item)}
                      showActions={false}
                      usePagination={false}
                      tableKey="vscan"
                      defaultSortBy="flightNum"
                    />
                  </section>
                  <section>
                    <CustomTable
                      tableName="Map Kits"
                      identifier="mapkitId"
                      HeaderIcon={FolderIcon}
                      columns={mapkitColumns}
                      statusNames={statusNames}
                      rowClickedHandler={(item: MapkitInterface) => history.push(`/fire/${fireId}/op/${opId}/mapkit/${item.mapkitId}`)}
                      usePagination={false}
                      showActions={false}
                      tableKey="mapkit"
                      defaultSortBy="modifiedAt"
                    />
                  </section>
                  <section>
                    <CustomTable
                      tableName="Deliverable"
                      identifier="deliverableId"
                      HeaderIcon={DocumentIcon}
                      columns={deliverableColumns}
                      statusNames={statusNames}
                      rowClickedHandler={(item: DeliverableInterface) => null}
                      usePagination={false}
                      showActions={true}
                      tableKey="deliverable"
                      deleteData={(deliverableId: string, s3Key: string) => deleteDeliverableHandler(opId, deliverableId, s3Key)}
                      defaultSortBy="modifiedAt"
                    />
                  </section>
                </Fragment>
              ) : null}
              
            </div>
          </div>
        </main>
            
        </div>
      </div>
    </Fragment>
  )
}

export default OPDetails
