import React, { Fragment, useCallback, useEffect, useState } from 'react'
import * as yup from 'yup';
import { DocumentIcon } from '@heroicons/react/solid';

import { useParams } from 'react-router-dom';
import CustomTable, {ColumnType} from '../../components/Table/CustomTable';
import { MapkitInterface } from '../../model/types';

import { getMapKitDB, listAGIsDB, listARCsDB, listOrthosDB, listPDFMapsDB, listShapefilesDB, removeAGIDB, removeARCDB, removeOrthoDB, removePDFMapDB, removeShapefileDB, updateMapkitDB } from '../../libs/dynamoLib';

import { supportedAgiType, supportedArcType, supportedOrthoType, supportedPDFMapType, supportedShapeFileType } from '../../helpers/supportedFileTypes';
import { s3RemoveFile } from '../../libs/s3Lib';
import DetailHeader from '../../components/Header/DetailHeader';
import { FormItem } from '../../components/Form/CustomForm';
import CustomBreadcrumbs, { BreadcrumbItem } from '../../components/Breadcrumbs/CustomBreadcrumbs';
import { useDispatch } from 'react-redux';
import { initializeTableItem, setLoading as setLoadingTable } from '../../redux/reducers/tableReducer';
import { setLoading as setLoadingMapkit, setInitialValues, setFields } from '../../redux/reducers/detailReducer';
import { initializeUploader, setModalOpenState } from '../../redux/reducers/uploadReducer';


const columns: ColumnType[] = [
  {label: 'Name', name: "name", type: "header"},
  {label: 'Created At', name: "createdAt", type: "date"},
  {label: 'Modified At', name: "modifiedAt", type: "date"},
]

const validationSchema = yup.object({
  name: yup
    .string(),
  });

const formData: FormItem[] = [
  {
      label: 'Name',
      name: 'name',
      type: 'text',
      id: 'name',
      autocomplete: null,
      placeholder: 'Optional',
  }
]
  

const MapkitDetails = () => {
    
  const params: {opId: string, mapkitId: string, fireId: string} = useParams();

  
  const dispatch = useDispatch()
  
  const [ fireId, setFireId ] = useState<string>();
  const [ opId, setOpId ] = useState<string>();
  const [ mapkitId, setMapkitId ] = useState<string>();
  const [ name, setName ] = useState<string>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  
  const openModalHandler = (dest: string, validFileTypes: string[]) => {
    dispatch(
      initializeUploader({
        tableKey: dest,
        validFileTypes: validFileTypes,
        dbPath: `/${dest}`,
        s3Path: `mapkit/${mapkitId}/${dest}/`,
        verificationPath: `/mapkit/${mapkitId}/${dest}`,
        payloadData: {mapkitId: mapkitId, fireId: fireId, opId: opId},
      })
    );
    dispatch(setModalOpenState(true));
  }

  useEffect(() => {

    if (params.mapkitId && params.opId && params.fireId) {

      setFireId(params.fireId);
      setOpId(params.opId);
      setMapkitId(params.mapkitId);
  
      setBreadcrumbs([
        {
          name: 'Fire',
          to: `/fire/${params.fireId}`
        },
        {
          name: 'Operational Period',
          to: `/fire/${params.fireId}/op/${params.opId}`
        },
        {
          name: 'Mapkit',
          to: `/fire/${params.fireId}/op/${params.opId}/mapkit/${params.mapkitId}`
        }
      ])
    }
    

  }, [params.mapkitId, params.opId, params.fireId])

  const getMapkit = useCallback(() => {
    if (opId && mapkitId) {
      getMapKitDB(opId, mapkitId)
        .then((res: MapkitInterface) => {
          if (res.name) {
            setName(res.name);
            dispatch(setFields([
              {
                label: 'Name',
                value: res.name,
                type: 'text'
              }
            ]))
          }
          dispatch(setInitialValues({
            name: res.name
          }))
        })
        .then(() => {
          dispatch(setLoadingMapkit(false))
        })
        .catch(err => {
          dispatch(setLoadingMapkit(false))
          console.error(err);
        })
    }
    
  }, [opId, mapkitId, dispatch])


  const updateMapkitHandler = useCallback((values: any) => {
    if (opId && mapkitId) {
      const data = {
        name: values.name
      }
      updateMapkitDB(opId, mapkitId, data)
          .then(() => {
            getMapkit()
          })
          .catch(err => {
              console.error(err);
          })
    }
  }, [getMapkit, mapkitId, opId])


  useEffect(() => {
    if (mapkitId) {
      dispatch(setLoadingTable({key: 'shapefile', loading: true}))
      dispatch(setLoadingTable({key: 'ortho', loading: true}))
      dispatch(setLoadingTable({key: 'pdfmap', loading: true}))
      dispatch(setLoadingTable({key: 'arc', loading: true}))
      dispatch(setLoadingTable({key: 'agi', loading: true}))

      listShapefilesDB(mapkitId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "shapefile",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })

      
      listOrthosDB(mapkitId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "ortho",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })

      listPDFMapsDB(mapkitId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "pdfmap",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })

      listARCsDB(mapkitId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "arc",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })

      listAGIsDB(mapkitId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "agi",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err:any) => {
          console.error(err)
        })
    }
  }, [mapkitId, dispatch])

 
  const deleteDataHandler = (mapkitId: string, tableItemKey: string, s3Key: string, deleteRecordFn: Function) => {
    console.log('Deleting Item')
    deleteRecordFn(mapkitId, tableItemKey)
      .then(() => {
        s3RemoveFile(s3Key)
      })
      .catch((err: any) => {
        console.error(err);
      })
  }

  useEffect(() => {
    getMapkit();
  }, [getMapkit])

  return (
    <Fragment>
      {/* {mapkitId && fireId && opId ? (
        <UploadModal
          s3Path={`mapkit/${mapkitId}/${dest}/`}
          open={modalOpen}
          setOpen={setModalOpen}
          dest={"/" + dest}
          payloadData={{mapkitId: mapkitId, fireId: fireId, opId: opId}}
          validFileTypes={validFileTypes}
          verificationPath={`/mapkit/${mapkitId}/${dest}`}
          tableKey={dest}
        />
      ) : null} */}
      <CustomBreadcrumbs crumbs={breadcrumbs}/>
      <div className="flex">
        <div className="flex flex-col w-0 flex-1">
          <main className="py-10">
            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">{name}</h1>
                  {/* <p className="text-sm font-medium text-gray-500">{opId}</p> */}
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                {/* <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Edit
                </button> */}
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => openModalHandler('ortho', supportedOrthoType)}
                >
                  Add Ortho Files
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => openModalHandler('pdfmap', supportedPDFMapType)}
                >
                  Add PDF Map Files
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => openModalHandler('shapefile', supportedShapeFileType)}
                >
                  Add Shape Files
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => openModalHandler('agi', supportedAgiType)}
                >
                  Add AGI Files
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => openModalHandler('arc', supportedArcType)}
                >
                  Add ARC Files
                </button>
              </div>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                <DetailHeader
                  submitHandler={(values: any) => updateMapkitHandler(values)}
                  title='Mapkit Details'
                  subtitle=''
                  expectedFields={["Name"]}
                  validationSchema={validationSchema}
                  formData={formData}
                />
                { mapkitId ? (
                  <Fragment>
                    <section>
                      <CustomTable
                        tableName="Orthographic"
                        identifier="orthoId"
                        HeaderIcon={DocumentIcon}
                        columns={columns}
                        rowClickedHandler={(item: any) => null}
                        usePagination={false}
                        showActions={true}
                        deleteData={(id: string, s3Key: string) => deleteDataHandler(mapkitId, id, s3Key, removeOrthoDB)}
                        tableKey="ortho"
                        defaultSortBy="modifiedAt"
                      />
                    </section>
                    <section>
                      <CustomTable
                        tableName="PDF Maps"
                        identifier="pdfmapId"
                        HeaderIcon={DocumentIcon}
                        columns={columns}
                        rowClickedHandler={(item: any) => null}
                        usePagination={false}
                        showActions={true}
                        deleteData={(id: string, s3Key: string) => deleteDataHandler(mapkitId, id, s3Key, removePDFMapDB)}
                        tableKey="pdfmap"
                        defaultSortBy="modifiedAt"
                      />
                    </section>
                    <section>
                      <CustomTable
                        tableName="Shape File"
                        identifier="shapefileId"
                        HeaderIcon={DocumentIcon}
                        columns={columns}
                        rowClickedHandler={(item: any) => null}
                        usePagination={false}
                        showActions={true}
                        deleteData={(id: string, s3Key: string) => deleteDataHandler(mapkitId, id, s3Key, removeShapefileDB)}
                        tableKey="shapefile"
                        defaultSortBy="modifiedAt"
                      />
                    </section>
                    <section>
                      <CustomTable
                        tableName="Agi"
                        identifier="agiId"
                        HeaderIcon={DocumentIcon}
                        columns={columns}
                        rowClickedHandler={(item: any) => null}
                        usePagination={false}
                        showActions={true}
                        deleteData={(id: string, s3Key: string) => deleteDataHandler(mapkitId, id, s3Key, removeAGIDB)}
                        tableKey="agi"
                        defaultSortBy="modifiedAt"
                      />
                    </section>
                    <section>
                      <CustomTable
                        tableName="Arc"
                        identifier="arcId"
                        HeaderIcon={DocumentIcon}
                        columns={columns}
                        rowClickedHandler={(item: any) => null}
                        usePagination={false}
                        showActions={true}
                        deleteData={(id: string, s3Key: string) => deleteDataHandler(mapkitId, id, s3Key, removeARCDB)}
                        tableKey="arc"
                        defaultSortBy="modifiedAt"
                      />
                    </section>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </main>  
        </div>
      </div>
    </Fragment>
  )
}

export default MapkitDetails
