import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';


import CustomForm, { FormItem } from '../../components/Form/CustomForm';
import Uploader from '../../components/Uploader/Uploader';
import { addFlightDB, listFlightsDB } from '../../libs/dynamoLib';
import { FlightInterface, FlightReq } from '../../model/types';
import {supportedVScanType, supportedIRScanType} from '../../helpers/supportedFileTypes'
import dayjs from 'dayjs';
import { AppDispatch } from '../../redux/store';
import { initializeUploader } from '../../redux/reducers/uploadReducer';
import { useDispatch } from 'react-redux';
import { createNotification, dismissNotification, pushNotification } from '../../redux/reducers/notificationReducer';

interface StepInterface {
    id: number
    label: string
    name: string
    status: 'current' | 'next' | 'complete'
}

interface FlightFields {
    name: string
    date: Date
    flightNum: number
    flightType: string
    flightNotes: string
}

// const SupportedFileTypes = {
//     'vscan': supportedVScanType,
//     'irscan': supportedIRScanType
// }

const FlightTypes = [
    {label: "Visual Scan", dest: 'vscan', validFileTypes: supportedVScanType},
    {label: "Infrared Scan", dest: 'irscan', validFileTypes: supportedIRScanType}
  ]

/**
 * 
 * Allow the user to create a fire. A successful creation will add the data to DynamoDB
 * And will redirect to the created fire
 * 
 * @returns A form with a field for name and date.
 */
const CreateFlight = () => {

    const params: {fireId: string, opId: string} = useParams();
    const history = useHistory();
    
    const dispatch = useDispatch<AppDispatch>();

    const [opId, setOpId] = useState<string>('')
    const [fireId, setFireId] = useState<string>('')

    const [initialValues, setInitialValues] = useState<FlightFields>({
        name: '',
        date: new Date(),
        flightNum: 1,
        flightType: 'irscan',
        flightNotes: ''
    })

    

    const [loadingFlight, setLoadingFlight] = useState<boolean>(true);

    const [flightDetails, setFlightDetails] = useState<FlightReq>();
    const [flightType, setFlightType] = useState<'irscan' | 'vscan'>();
    const [flightId, setFlightId] = useState<string>('');

    const [success, setSuccess] = useState(false);

    const [ steps, setSteps ] = useState<StepInterface[]>([
        { id: 0, label: 'Step 1', name: 'Flight details', status: 'current' },
        { id: 1, label: 'Step 2', name: 'Add Files', status: 'next' },
    ])
    const [ step, setStep ] = useState(0);

    const formData: FormItem[] = [ 
        {
            label: 'Flight Number',
            name: 'flightNum',
            type: 'number',
            id: 'flightNum',
            autocomplete: null,
            placeholder: null,
        },
        {
            label: 'Flight Type',
            name: 'flightType',
            type: 'select',
            id: 'flightType',
            autocomplete: null,
            placeholder: null,
            opt: [
                {label: "Visual Scan", name: "vscan"},
                {label: "Infrared Scan", name: "irscan"},
                
            ]
        },
        {
            label: 'Date',
            name: 'date',
            type: 'datepicker',
            id: 'date',
            autocomplete: null,
            placeholder: "Optional"
        },
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            id: 'name',
            autocomplete: null,
            placeholder: "Optional"
        },
        {
            label: 'Notes',
            name: 'flightNotes',
            type: 'textarea',
            id: 'flightNotes',
            autocomplete: null,
            placeholder: "Optional"
        },
        
    ]

    const validationSchema = yup.object({
        name: yup
            .string(),
        date: yup
            .string()
            .required(),
        flightNum: yup
            .number()
            .required(),
        flightType: yup
            .string()
            .required(),
        flightNotes: yup
            .string(),
    });

    const resetFormDataHandler = () => {
        setStep(0);
        setSteps([
            { id: 0, label: 'Step 1', name: 'Flight details', status: 'current' },
            { id: 1, label: 'Step 2', name: 'Add Files', status: 'next' },
        ]);
        setSuccess(false);
        setInitialValues(old => {return {...old, flightNum: old.flightNum + 1}})
    }

    const submitFormHandler = (values: any) => {

        const payload: FlightReq = {
            fireId: fireId,
            opId: opId,
            flightNum: values.flightNum,
            flightType: values.flightType === "vscan" ? 0 : 1,
            flightNotes: values.flightNotes,
            name: values.name,
            date: new Date(values.date).toISOString(),
        }

        setFlightType(values.flightType)
        setFlightDetails(payload)
        
        addFlightDB(payload)
            .then((res: FlightInterface) => {
                setFlightId(res.flightId);
                setStep(1);
                let nextSteps: StepInterface[] = [...steps];
                nextSteps[1].status = 'current'
                nextSteps[0].status = 'complete'


                dispatch(
                    initializeUploader({
                      tableKey: `${values.flightType}s`,
                      validFileTypes: FlightTypes[payload.flightType].validFileTypes,
                      dbPath: `/${values.flightType}`,
                      s3Path: `fire/${fireId}/flight/${values.flightType}/${res.flightId}/`,
                      verificationPath: `/op/${opId}/flight/type/${payload.flightType}`,
                      payloadData: {fireId: fireId, opId: opId, flightId: res.flightId},
                    })
                );

                setSteps(nextSteps);     
            })
            .catch(err => {
                console.error(err.message);
            })

            
    }

    
    useEffect(() => {
        
        setOpId(params.opId);
        setFireId(params.fireId);
  
    }, [params.opId, params.fireId])

    useEffect(() => {
        if (opId) {
            listFlightsDB(opId)
                .then((res) => {
                    const data: Array<any> = res.Items;
                    
                    if (res.Count > 0) {
                        data.sort((item_a: FlightInterface, item_b: FlightInterface) =>  parseInt(item_b.flightNum) - parseInt(item_a.flightNum)); // Reverse Order, [0] is largest 
                        setInitialValues(old => {
                            return {
                                ...old,
                                flightNum: typeof data[0].flightNum === "string" ?  (parseInt(data[0].flightNum) + 1) : (data[0].flightNum + 1)
                            }
                        })
                    }
                })
                .then(() => {
                    setLoadingFlight(false);
                })
                .catch(err => {
                    const ErrorNotification = createNotification(
                        "Form Submission",
                        `Form data submitted.`,
                        "upload",
                        true
                    )
                    dispatch(pushNotification(ErrorNotification))
                    setTimeout(() => dispatch(dismissNotification(ErrorNotification)), 5000);
                    console.error(err);
                })
        }
    }, [opId, dispatch])


    return (
        <Fragment>
            <div className="p-6 m-9 bg-white shadow sm:rounded-lg">
                <nav aria-label="Progress">
                    <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                        {steps.map((step) => (
                            <li key={step.name} className="md:flex-1">
                                {step.status === 'complete' ? (
                                <div
                                    className="group pl-4 py-2 flex flex-col border-l-4 border-indigo-500 hover:border-indigo-700 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                >
                                    <span className="text-xs text-indigo-500 font-semibold tracking-wide uppercase group-hover:text-indigo-700">
                                        {step.label}
                                    </span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </div>
                                ) : step.status === 'current' ? (
                                <div
                                    className="pl-4 py-2 flex flex-col border-l-4 border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                    aria-current="step"
                                >
                                    <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase">{step.label}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </div>
                                ) : (
                                <div
                                    className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                >
                                    <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                                        {step.label}
                                    </span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </div>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
            <div className="mt-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                {
                    step === 0 && !loadingFlight && (
                        <CustomForm
                            title="Create a New Flight"
                            subtitle="Let’s get started by filling in the information below to create a new flight. You can add files next, or add them later."
                            formData={formData}
                            validationSchema={validationSchema}
                            initialValues={initialValues}
                            submitHandler={(values: any) => submitFormHandler(values)}
                            cancelHandler={() => history.push(`/fire/${fireId}/op/${opId}`)}
                            submitButtonText={'Create and Continue'}
                            loading={loadingFlight}
                        />
                    ) 
                }

                {
                    step === 1 && flightType && (
                        <Fragment>
                        { 
                            flightDetails && 
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                            Flight Information
                                        </h2>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Flight Number</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{flightDetails.flightNum}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Flight Type</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{flightDetails.flightType === 0 || flightDetails.flightType === 1 ? FlightTypes[flightDetails.flightType].label : ""}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Date</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{dayjs(flightDetails.date).format('YYYY/MM/DD')}</dd>
                                            </div>
                                            {flightDetails.name && <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Name</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{flightDetails.name}</dd>
                                            </div>}
                                            {flightDetails.flightNotes && <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Notes</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{flightDetails.flightNotes}</dd>
                                            </div>}
                                        </dl>
                                    </div>
                                    </div>
                                    </section>

                          }
                        {flightDetails && 
                            <div className="border my-2 bg-white shadow sm:rounded-lg">
                                <Uploader onSuccessHandler={() => setSuccess(true)}/>
                            </div>
                        }
                        </Fragment>
                    )
                }

                {
                    step === 1 && success && (
                        <div className="pb-10">
                            <button
                                type="submit"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => {history.push(`/fire/${fireId}/op/${opId}/flight/${flightId}`);}}
                            >
                                Continue
                            </button>
                            <button
                                type="button"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => resetFormDataHandler()}
                            >
                                Add another Flight
                            </button>
                            
                        </div>
                    ) 
                }
            </div>
        </Fragment>
    )
}

export default CreateFlight
