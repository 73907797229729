export interface CustomSliderProps {
    min: number
    max: number
    value: number
    setValue: Function
}

const CustomSlider = (props: any) => {

    // const [value, setValue] = useState<number>(50)

    const { label, min, max, value, setValue } = props;

    const changeHandler = (e: any) => {
        e.preventDefault()
        setValue(e.target.value)
    }

    return (
        <div className="p-2 flex justify-center">
            <label className="pr-2 text-lg" htmlFor={label}>{label}</label>
            <div className='inline-flex'>
                <div className='text-sm px-1'>
                    {min}
                </div>
                <input id={label} name={label} type="range" min={min} max={max} value={value} onChange={(e) => changeHandler(e)} />
                <div className='text-sm px-1'>
                    {max}
                </div>
                <div className="inline-flex content-center justify-center pl-5">
                    <button type="button" className="border rounded-l-sm w-10" onClick={() => setValue((oldVal: number) => oldVal-1)}>-</button>
                    <input type='text' value={value} onChange={(e) => setValue(e.target.value)} className='text-sm border w-10 p-1 text-center' />
                    <button type="button" className="border rounded-r-sm w-10" onClick={() => setValue((oldVal: number) => oldVal+1)}>+</button>
                </div>
                
            </div>
        </div>
        
    )
}

export default CustomSlider
