import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';

import { SavedPoints, removeSegmentation, setSelectedIndex, ExternalTableType } from '../../redux/reducers/builderReducer'
import { XIcon } from '@heroicons/react/solid';

interface TableRowItem {
    name: string
    type: 'Fill' | 'Outline'
    index: number
}

const HBViewTable = () => {

    const savedSegmentations: SavedPoints = useSelector((state: RootState) => state.builder.savedSegmentations);
    const savedMaskIndices: {[key: string]: number[][]}  = useSelector((state: RootState) => state.builder.savedMaskIndices);
    const selectedIndex: ExternalTableType | null = useSelector((state: RootState) => state.builder.selectedIndex);

    const dispatch = useDispatch<AppDispatch>();

    const [tableData, setTableData] = useState<Array<any>>([])

    const checkboxClickedHandler = useCallback((fileName: string, type: string, index: number) => {
      dispatch(setSelectedIndex({fileName: fileName, type: type, index: index}))
    }, [dispatch])

    const removePathHandler = useCallback((fileName: string, type: string, index: number) => {
        dispatch(removeSegmentation({fileName: fileName, type: type, index: index}))
        if (index === selectedIndex?.index) {
          dispatch(setSelectedIndex(null));
        }

      }, [dispatch, selectedIndex])

    useEffect(() => {
        
        const newTableData: Array<TableRowItem> = []

        Object.keys(savedSegmentations).forEach((fieldname: string) => {

          const segmentations = savedSegmentations[fieldname]['segmentations'];

          for (let i = 0; i < segmentations.length; i++) {
            newTableData.push({
              name: fieldname,
              type: 'Outline',
              index: i
            })
          }
        })

        Object.keys(savedMaskIndices).forEach((fieldname: string) => {

          const savedIndices = savedMaskIndices[fieldname];
          
          for (let j = 0; j < savedIndices.length; j++) {
            newTableData.push({
              name: fieldname,
              type: 'Fill',
              index: j
            })
          }
        })

        setTableData(newTableData);

    }, [savedSegmentations, savedMaskIndices])


    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Select</span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      File Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Index
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tableData && tableData.map((item: TableRowItem) => (
                    <tr key={`${item.name}-${item.type}-${item.index}`}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <input
                          type="radio"
                          value={`${item.name}-${item.type}-${item.index}`}
                          checked={
                            (selectedIndex?.index === item.index)
                            && (selectedIndex?.type === item.type)
                            && (selectedIndex?.fileName === item.name)
                          }
                          onChange={() => checkboxClickedHandler(item.name, item.type, item.index)}
                        ></input>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.type}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.index}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          type="button"
                          onClick={() => removePathHandler(item.name, item.type, item.index)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <XIcon className='h-5 w-5'/>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
}

export default HBViewTable