import { useContext, createContext } from "react";


export type AuthContextType = {
  user: any
  org: string | null
  authenticated: boolean
  authenticating: boolean
  setUser: (user: any) => void
  setOrg: (org: string) => void
  setAuthenticated: (authenticated: boolean) => void
  setAuthenticating: (authenticating: boolean) => void
}

export const AuthContext = createContext<AuthContextType>(
  {
    user: null,
    org: null,
    authenticated: false,
    authenticating: true,
    setUser: (auth) => console.warn('no auth provider'),
    setOrg: (auth) => console.warn('no auth provider'),
    setAuthenticated: (auth) => console.warn('no auth provider'),
    setAuthenticating: (auth) => console.warn('no auth provider'),
  }
);

export const useAuthContext = () => useContext(AuthContext);