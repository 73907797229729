import { Fragment, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { DocumentIcon, FolderIcon } from "@heroicons/react/solid";

import CustomTable, { ColumnType } from "../../components/Table/CustomTable";
import {
  FireInterface,
  FireReq,
  OPInterface,
  SupportInterface,
} from "../../model/types";
import {
  getFireDB,
  listOperationalPeriodsDB,
  listSupportsDB,
  removeSupportDB,
  updateFireDB,
} from "../../libs/dynamoLib";

import { supportedSupportType } from "../../helpers/supportedFileTypes";
import DetailHeader from "../../components/Header/DetailHeader";
import {
  fireCoordinateNotation as fcCoord,
  RegionAbbreviation as rAbbr,
  statusNames as stsNm,
} from "../../helpers/labels";
import { FormItem } from "../../components/Form/CustomForm";
import CustomBreadcrumbs, {
  BreadcrumbItem,
} from "../../components/Breadcrumbs/CustomBreadcrumbs";
import { s3RemoveFile } from "../../libs/s3Lib";
import {
  setFields,
  setInitialValues,
  setLoading as setDetailsLoading,
} from "../../redux/reducers/detailReducer";
import {
  setLoading as setTableLoading,
  initializeTableItem,
} from "../../redux/reducers/tableReducer";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { initializeUploader, setModalOpenState } from "../../redux/reducers/uploadReducer";

dayjs.extend(calendar);

const stats = ["New", "Processing", "Completed"];

const opColumns: ColumnType[] = [
  { label: "Name", name: "name", type: "header" },
  { label: "Date", name: "date", type: "date" },
  { label: "Status", name: "status", type: "status" },
  { label: "Modified At", name: "modifiedAt", type: "date" },
];

const supportColumns: ColumnType[] = [
  { label: "Name", name: "name", type: "header" },
  { label: "Created At", name: "createdAt", type: "date" },
  { label: "Modified At", name: "modifiedAt", type: "date" },
];

const validationSchema = yup.object({
  number: yup.string().min(3).required(),
  name: yup.string(),
  date: yup.string().required(),
  region: yup.string().required(),
  status: yup.string().required(),
  coordinatenotation: yup.string().required(),
});

const formData: FormItem[] = [
  {
    label: "Number",
    name: "number",
    type: "text",
    id: "number",
    autocomplete: null,
    placeholder: "Required",
  },
  {
    label: "Name",
    name: "name",
    type: "text",
    id: "name",
    autocomplete: null,
    placeholder: "Optional",
  },
  {
    label: "Region",
    name: "region",
    type: "select",
    id: "region",
    autocomplete: null,
    placeholder: null,
    opt: [
      {
        label: "British Columbia",
        name: "bc",
      },
      {
        label: "Alberta",
        name: "ab",
      },
      {
        label: "Saskatchewan",
        name: "sk",
      },
      {
        label: "Manitoba",
        name: "mb",
      },
      {
        label: "Ontario",
        name: 'on'
      },
      {
        label: "Yukon",
        name: "yk",
      }
    ],
  },
  {
    label: "Coordinate Notation",
    name: "coordinatenotation",
    type: "select",
    id: "coordinatenotation",
    autocomplete: null,
    placeholder: null,
    opt: [
      { label: "Decimal degrees (DD)", name: "dd" },
      { label: "Degrees decimal minutes (DDM)", name: "ddm" },
      { label: "Degrees-minutes-seconds (DMS)", name: "dms" },
      { label: "Global Area Reference System (GARS)", name: "gars" },
      { label: "GEOREF (World Geographic Reference System)", name: "georef" },
      { label: "Universal Transverse Mercator (UTM)", name: "utm" },
      { label: "United States National Grid (USNG)", name: "usng" },
      { label: "Military Grid Reference System (MGRS)", name: "mgrs" },
    ],
  },
  {
    label: "Date",
    name: "date",
    type: "datepicker",
    id: "date",
    autocomplete: null,
    placeholder: "Required",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    id: "status",
    autocomplete: null,
    placeholder: null,
    opt: [
      {
        label: "New",
        name: 0,
      },
      {
        label: "Processing",
        name: 1,
      },
      {
        label: "Completed",
        name: 2,
      },
    ],
  },
];

export default function FireDetails() {
  const history = useHistory();

  const params: { fireId?: string } = useParams();

  const dispatch: AppDispatch = useDispatch();

  const [fireId, setFireId] = useState<string>();

  const [fireName, setFireName] = useState<string>("");
  const [fireNumber, setFireNumber] = useState<string>("");

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const opClickedHandler = (opData: OPInterface) => {
    history.push(`/fire/${fireId}/op/${opData.opId}`);
  };

  const getFireDetails = useCallback((fireId) => {
    dispatch(setDetailsLoading(true));
    getFireDB(fireId)
      .then((res: FireInterface) => {
        setFireName(res.name);
        setFireNumber(res.number);
        dispatch(
          setFields([
            {
              label: "Fire Number",
              value: res.number,
              type: "text",
            },
            {
              label: "Name",
              value: "",
              type: "text",
            },
            {
              label: "Region",
              value: rAbbr.get(res.region),
              type: "text",
            },
            {
              label: "Date",
              value: dayjs(res.date).format("YYYY/MM/DD"),
              type: "text",
            },
            {
              label: "Status",
              value: stsNm.get(res.status),
              type: "status",
            },
            {
              label: "Coordinate Notation",
              value: fcCoord.get(res.coordinatenotation),
              type: "text",
            },
          ])
        );

        dispatch(
          setInitialValues({
            number: res.number,
            name: res.name,
            region: res.region,
            status: res.status,
            coordinatenotation: res.coordinatenotation,
            date: dayjs(res.date).format("YYYY/MM/DD"),
          })
        );

        dispatch(
          initializeUploader({
            tableKey: 'support',
            validFileTypes: supportedSupportType,
            dbPath: '/support',
            s3Path: `fire/${fireId}/support/`,
            verificationPath: `/fire/${fireId}/support`,
            payloadData: { fireId: fireId },
          })
        );

      })
      .then(() => {
        dispatch(setDetailsLoading(false));
      })
      .catch((err) => {
        console.error(err);
        dispatch(setDetailsLoading(false));
      });
  }, [dispatch]);

  useEffect(() => {
    if (params.fireId) {
      setFireId(params.fireId);
      setBreadcrumbs([
        {
          name: "Fire",
          to: `/fire/${params.fireId}`,
        },
      ]);

      getFireDetails(params.fireId);
    }
  }, [params.fireId, getFireDetails]);

  useEffect(() => {
    if (fireId) {
      dispatch(setTableLoading({key: 'support', loading: true}));
      listSupportsDB(fireId)
        .then((res: any) => {
          dispatch(initializeTableItem({
              key: "support",
              data: res.Items,
              sortBy: "modifiedAt",
              sortDirection: "DESC",
            })
          );
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }, [fireId, dispatch]);

  useEffect(() => {
    if (fireId) {
      dispatch(setTableLoading({key: 'op', loading: true}));
      listOperationalPeriodsDB(fireId)
        .then((res: any) => {
          dispatch(initializeTableItem({
            key: "op",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }, [fireId, dispatch]);

  const updateFireHandler = useCallback(
    (values: any) => {
      if (fireId) {
        dispatch(setDetailsLoading(true));

        const params: FireReq = {
          number: values.number,
          name: values.name,
          region: values.region,
          date: values.date,
          status: parseInt(values.status),
          coordinatenotation: values.coordinatenotation,
        };

        updateFireDB(fireId, params)
          .then(() => {
            dispatch(
              setFields([
                {
                  label: "Fire Number",
                  value: params.number,
                  type: "text",
                },
                {
                  label: "Name",
                  value: "",
                  type: "text",
                },
                {
                  label: "Region",
                  value: rAbbr.get(params.region),
                  type: "text",
                },
                {
                  label: "Date",
                  value: dayjs(params.date).format("YYYY/MM/DD"),
                  type: "text",
                },
                {
                  label: "Status",
                  value: stsNm.get(params.status),
                  type: "status",
                },
                {
                  label: "Coordinate Notation",
                  value: fcCoord.get(params.coordinatenotation),
                  type: "text",
                },
              ])
            );
            dispatch(
              setInitialValues({
                number: params.number,
                name: params.name,
                region: params.region,
                status: params.status,
                coordinatenotation: params.coordinatenotation,
                date: dayjs(params.date).format("YYYY/MM/DD"),
              })
            );
          })
          .then(() => {
            dispatch(setDetailsLoading(false));
          })
          .catch((err) => {
            console.error(err);
            dispatch(setDetailsLoading(false));
          });
      }
    },
    [fireId, dispatch]
  );

  useEffect(() => {
    if (fireId) { 
      dispatch(setTableLoading({key: 'support', loading: true}));
      listSupportsDB(fireId)
        .then((res) => {
          dispatch(initializeTableItem({
            key: "support",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },[fireId, dispatch])

  const deleteDataHandler = (
    fireId: string,
    tableItemId: string,
    s3Key: string
  ) => {
    console.log("Deleting Item");
    removeSupportDB(fireId, tableItemId)
      .then((res) => {
        s3RemoveFile(s3Key);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      <CustomBreadcrumbs crumbs={breadcrumbs} />
      <div className="flex">
        <div className="flex flex-col w-0 flex-1">
          <main className="py-10">
            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {fireNumber}
                  </h1>
                  <p className="text-sm font-medium text-gray-500">
                    {fireName}
                  </p>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <Link
                  to={`${fireId}/op/create`}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Add Operational Period
                </Link>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => {
                    dispatch(setModalOpenState(true))
                  }}
                >
                  Add Supporting Material
                </button>
              </div>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                <DetailHeader
                  submitHandler={(values: any) => updateFireHandler(values)}
                  title="Fire Details"
                  subtitle=""
                  expectedFields={[
                    "Fire Number",
                    "Region",
                    "Date",
                    "Status",
                    "Coordinate Notation",
                  ]}
                  validationSchema={validationSchema}
                  formData={formData}
                />
                {fireId ? (
                  <Fragment>
                    <section>
                      <CustomTable
                        tableName="Operational Periods"
                        identifier="opId"
                        HeaderIcon={FolderIcon}
                        columns={opColumns}
                        statusNames={stats}
                        rowClickedHandler={(item: OPInterface) =>
                          opClickedHandler(item)
                        }
                        usePagination={false}
                        showActions={false}
                        tableKey="op"
                        defaultSortBy="date"
                      />
                    </section>

                    <section>
                      <CustomTable
                        tableName="Supporting Materials"
                        identifier="supportId"
                        HeaderIcon={DocumentIcon}
                        columns={supportColumns}
                        statusNames={stats}
                        rowClickedHandler={(item: SupportInterface) => null}
                        showActions={true}
                        usePagination={false}
                        deleteData={(tableId: string, s3Key: string) =>
                          deleteDataHandler(fireId, tableId, s3Key)
                        }
                        tableKey="support"
                        defaultSortBy="name"
                      />
                    </section>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
