import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { DocumentIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import CustomTable, {ColumnType} from '../../components/Table/CustomTable';
import { FlightInterface } from '../../model/types';
import { getFlightDB, getIRScanDB, getVScanDB, removeIRScanDB, removeVScanDB, updateFlightDB } from '../../libs/dynamoLib';
import { supportedIRScanType, supportedVScanType } from '../../helpers/supportedFileTypes';

import dayjs from 'dayjs';
import DetailHeader from '../../components/Header/DetailHeader';
import * as yup from 'yup';
import { FormItem } from '../../components/Form/CustomForm';
import CustomBreadcrumbs, { BreadcrumbItem } from '../../components/Breadcrumbs/CustomBreadcrumbs';
import { useDispatch } from 'react-redux';
import { setFields, setInitialValues, setLoading } from '../../redux/reducers/detailReducer';
import { initializeTableItem, setLoading as setLoadingTable } from '../../redux/reducers/tableReducer';
import { s3RemoveFile } from '../../libs/s3Lib';
import { initializeUploader, setModalOpenState } from '../../redux/reducers/uploadReducer';

const statusNames = [
  'New',
  'Processing',
  'Completed'
]

const columns: ColumnType[] = [
  {label: 'Name', name: "name", type: "header"},
  {label: 'Created At', name: "createdAt", type: "date"},
  {label: 'Modified At', name: "modifiedAt", type: "date"},
]

const FlightTypes = [
  {label: "Visual Scan", dest: 'vscan', validFileTypes: supportedVScanType},
  {label: "Infrared Scan", dest: 'irscan', validFileTypes: supportedIRScanType}
]

const validationSchema = yup.object({
  number: yup
    .string()
    .required(),
  name: yup
    .string(),
  date: yup
    .string()
    .required(),
  notes: yup
    .string()
});

const formData: FormItem[] = [
  {
      label: 'Number',
      name: 'number',
      type: 'text',
      id: 'number',
      autocomplete: null,
      placeholder: 'Required',
  },
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    id: 'name',
    autocomplete: null,
    placeholder: 'Optional',
},
  {
    label: 'Date',
    name: 'date',
    type: 'datepicker',
    id: 'date',
    autocomplete: null,
    placeholder: 'Required',
  }
  ,
  {
    label: 'Notes',
    name: 'flightNotes',
    type: 'textarea',
    id: 'flightNotes',
    autocomplete: null,
    placeholder: 'Required',
  }
]

const FlightDetails = () => {
    
  const params: {fireId: string, opId: string, flightId: string} = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [fireId, setFireId] = useState<string>();
  const [opId, setOpId] = useState<string>();
  const [flightId, setFlightId] = useState<string>();
  
  // const [name, setname] = useState<string>('');
  const [date, setDate] = useState<string>();
  const [flightNum, setFlightNum] = useState<string | null>(null);
  const [flightType, setFlightType] = useState<number | null>(null);
  
  const [dest, setDest] = useState<string>();


  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const getFlight = useCallback((opId, flightId) => {
    getFlightDB(opId, flightId)
      .then((res: FlightInterface) => {
        setFlightNum(res.flightNum);
        setFlightType(res.flightType);
        setDest(FlightTypes[res.flightType].dest);
        setDate(res.date);
        return res;
      })
      .then((res: FlightInterface) => {
        dispatch(setFields(
          [
            {
              label: 'Number',
              value: res.flightNum,
              type: 'text'
            },
            {
              label: 'Name',
              value: res.name,
              type: 'text'
            },
            {
              label: 'Date',
              value: dayjs(res.date).format('YYYY/MM/DD'),
              type: 'text'
            },
            {
              label: 'Type',
              value: FlightTypes[res.flightType].label,
              type: 'text'
            },
            {
              label: 'Notes',
              value: res.flightNotes,
              type: 'text'
            }
          ]
        ))
        return res;
      })
      .then((res: FlightInterface) => {
        dispatch(setInitialValues({
          number: res.flightNum,
          name: res.name,
          date: dayjs(res.date).format('YYYY/MM/DD'),
          type: res.flightType,
          flightNotes: res.flightNotes,
        }))

        // s3Path={`fire/${fireId}/flight/${dest}/${flightId}/`}
        // open={modalOpen}
        // setOpen={setModalOpen}
        // dest={"/" + dest}
        // payloadData={{fireId: fireId, opId: opId, flightId: flightId}}
        // validFileTypes={validFiles}
        // verificationPath={`/op/${opId}/flight/type/${flightType}`}
        // tableKey={`${dest}s`}
      })
      .then(() => {
        dispatch(setLoading(false))
      })
      .catch(err => {
        console.error(err);
        dispatch(setLoading(false))
      })
    
  }, [dispatch])

  const rowClickedHandler = (data: any) => {
    // console.log('clicked');
  }
  
  const updateFlightHandler = (values: any) => {
    if (fireId && opId && flightId) {

      const data = {
        flightNum: values.number,
        name: values.name,
        date: values.date,
        flightType: values.flight,
        flightNotes: values.flightNotes
      }

      updateFlightDB(opId, flightId, data)
          .then(() => {
            getFlight(opId, flightId)
          })
          .catch(err => {
              console.error(err);
          })
    }  
  }

  const deleteDataHandler = (flightId: string, tableItemId: string, s3Key: string) => {
    if (flightId)
      if (flightType === 0) {
        removeVScanDB(flightId, tableItemId)
          .then(() => {
            s3RemoveFile(s3Key)
          })
          .catch(err => {
            console.error(err);
          })
      } else {
        removeIRScanDB(flightId, tableItemId)
          .then(() => {
            s3RemoveFile(s3Key)
          })
          .catch(err => {
            console.error(err);
          })
      }
  }

  useEffect(() => {
    // Set IDs
    setFireId(params.fireId);
    setOpId(params.opId);
    setFlightId(params.flightId);

    // Set Bread crumbs
    setBreadcrumbs([
      { name: 'Fire', to: `/fire/${params.fireId}` },
      { name: 'Operational Period', to: `/fire/${params.fireId}/op/${params.opId}` },
      { name: 'Flight', to: `/fire/${params.fireId}/op/${params.opId}/flight/${params.flightId}` }
    ])

    getFlight(params.opId, params.flightId);

  }, [params.fireId, params.opId, params.flightId, getFlight])

  useEffect(() => {
    if (flightId && flightType !== undefined) {
      if (flightType === 0) {
        dispatch(setLoadingTable({key: "vscans", loading: true}));
        getVScanDB(flightId)
          .then((res: any) => {
              dispatch(initializeTableItem({
                key: "vscans",
                data: res.Items,
                sortBy: "modifiedAt",
                sortDirection: "DESC",
              }));
          })
          .catch((err:any) => {
            console.error(err)
          })
      } else {
        dispatch(setLoadingTable({key: "irscans", loading: true}));
        getIRScanDB(flightId)
          .then((res: any) => {
              dispatch(initializeTableItem({
                key: "irscans",
                data: res.Items,
                sortBy: "modifiedAt",
                sortDirection: "DESC",
              }));
          })
          .catch((err:any) => {
            console.error(err)
          })
      }
    }
  }, [flightId, flightType, dispatch])

  const OpenAddImageModal = useCallback(() => {
    if (flightType) {
      const dest = FlightTypes[flightType].dest
      dispatch(
        initializeUploader({
          tableKey: `${dest}s`,
          validFileTypes: FlightTypes[flightType].validFileTypes,
          dbPath: `/${dest}`,
          s3Path: `fire/${fireId}/flight/${dest}/${flightId}/`,
          verificationPath: `/op/${opId}/flight/type/${flightType}`,
          payloadData: {fireId: fireId, opId: opId, flightId: flightId},
        })
      );
      dispatch(setModalOpenState(true))

    }
    
  }, [flightId, fireId, opId, flightType, dispatch])

  return (
    <Fragment>

      {/* Breadcrumbs */}
      <CustomBreadcrumbs crumbs={breadcrumbs}/>

      <div className="flex">
        <div className="flex flex-col w-0 flex-1">
          <main className="py-10">
            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div>
                  {flightNum && <h1 className="text-2xl font-bold text-gray-900">Flight {flightNum}</h1>}
                  {date && <p className="text-sm font-medium text-gray-500">{dayjs(date).format('YYYY/MM/DD')}</p>}
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => history.push(`/fire/${fireId}/op/${opId}/flight/create`)}
                >
                  New Flight
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  onClick={() => OpenAddImageModal()}
                >
                  Add Images
                </button>
              </div>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                
                <DetailHeader
                  submitHandler={(values: any) => updateFlightHandler(values)}
                  title='Flight Details'
                  subtitle=''
                  expectedFields={["Number", "Name", "Date", "Type", "Notes"]}
                  validationSchema={validationSchema}
                  formData={formData}
                />

                <section>
                  {flightId && dest ? (
                    <CustomTable
                      tableName="Images"
                      identifier={`${dest}Id`}
                      HeaderIcon={DocumentIcon}
                      columns={columns}
                      statusNames={statusNames}
                      rowClickedHandler={(item: FlightInterface) => rowClickedHandler(item)}
                      usePagination={false}
                      showActions={true}
                      tableKey={`${dest}s`}
                      deleteData={(tableItemId: string, s3Key: string) => deleteDataHandler(flightId, tableItemId, s3Key)}
                      defaultSortBy="modifiedAt"
                    />
                  ) : null}
                  
                </section>
              </div>
            </div>
          </main>  
        </div>
      </div>
    </Fragment>
  )
}

export default FlightDetails
