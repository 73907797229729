import { Fragment, useEffect, useState } from "react";
import { Auth } from "aws-amplify";

import LeftNavbar from "./components/Navbar/LeftNavbar";

// import { setAuthenticated, setAuthenticating, setUser } from './redux/reducers/userReducer'
import { useAppDispatch } from "./hooks/hooks";
import { AuthContext } from "./libs/contextLib";

// import TopBar from "./components/Navbar/TopBar";
import UnAuthRoutes from "./Routes/UnauthenticatedRoutes";
import AuthRoutes from "./Routes/AuthenticatedRoutes";
import TopBar from "./components/Navbar/TopBar";
import classNames from "classnames";
import CustomNotification from "./components/Overlay/CustomNotification";
import { AppDispatch } from "./redux/store";

import UploadModal from "./components/Uploader/UploadModal";
import { createNotification, dismissNotification, pushNotification } from "./redux/reducers/notificationReducer";
// import { useLocation } from "react-router-dom";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

function App() {

    const dispatch: AppDispatch = useAppDispatch()

    const [ org, setOrg ] = useState<string | null>(null)
    const [ user, setUser ] = useState<any>(null);
    const [ authenticated, setAuthenticated ] = useState<boolean>(false);
    const [ authenticating, setAuthenticating ] = useState<boolean>(true);

    const [isOpen, setIsOpen] = useState<boolean>(true)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((res: any) => {
                const groups: string[] | null = res.signInUserSession.accessToken.payload['cognito:groups']

                if (groups !== null && groups.length > 0) {
                    // const isAdmin = groups.find((group: string) => group === 'Admin');
                    
                    const NonAdminGroups = groups.filter((group: string) => group !== 'Admin');
                    if (NonAdminGroups && NonAdminGroups.length > 0) {
                        setOrg(NonAdminGroups[0])
                    }
                    else if (NonAdminGroups.length === 0) {
                        const AuthNotification = createNotification(
                            "Authentication Issue",
                            `Something went wrong when during authentication. Please contact your administrator. [Error 0001]`,
                            "error",
                            true
                          )
                          dispatch(pushNotification(AuthNotification))
                          setTimeout(() => dispatch(dismissNotification(AuthNotification)), 5000);
                    }
                }

                setAuthenticated(true);
                setUser(res.attributes.email);
                setAuthenticating(false);
            })
            .catch(err => {
                setAuthenticated(false);
                setAuthenticating(false)
            })
    }, [dispatch]);

    useEffect(() => {
        if (windowDimensions.width > 640) {
            setIsOpen(true)
        }
    }, [windowDimensions])

    return (
        <AuthContext.Provider value={{ user, authenticated, authenticating, org, setUser, setAuthenticated, setAuthenticating, setOrg }}>
            <div className="flex flex-row w-full min-h-screen">
                {
                    !authenticating ? (
                        authenticated ? (
                            <Fragment>
                                <div className="fixed h-screen">
                                    <LeftNavbar isOpen={isOpen} setIsOpen={setIsOpen}/>
                                </div>
                                
                                <div className={classNames("flex flex-col w-full bg-gray-100", {"ml-8": !isOpen, "ml-64": isOpen})}>                        
                                    <CustomNotification />
                                    <UploadModal />
                                    <TopBar setSidebarOpen={false}/>
                                    <div>
                                        <AuthRoutes/>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <div className="flex flex-col w-full bg-gray-100 pt-20 items-center">
                                <UnAuthRoutes/>
                            </div>
                        )
                    ) : (
                        <div className="flex flex-col w-full bg-gray-100 pt-20 items-center">
                            <p className="text-sm font-bold">Loading... Please wait.</p>
                        </div>

                    )
                }
            </div>
        </AuthContext.Provider>
    );
}

export default App;
