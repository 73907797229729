import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, OfficeBuildingIcon, UserIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../libs/contextLib'
import { Auth } from 'aws-amplify'

const TopBar = (props: any) => {

    const { setAuthenticated, user, org } = useAuthContext();
    const history = useHistory()

    const handleLogout = () => {
        Auth.signOut().then((res: any) => {
            setAuthenticated(false);
            history.push('/');
        })
        .catch(err => {
            console.error(err.message);
        })
    }
    
    return (
            <div className="relative z-10 flex-shrink-0 flex h-12 bg-white border-b">
              <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="flex-1 flex">
               
                </div>
                <div className="ml-4 flex items-center md:ml-6">
                  <div className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                    <OfficeBuildingIcon className="h-5 w-5 rounded-full"/>
                    <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                      <span className="sr-only">Org</span>{org ? org : "No Organization Set"}
                    </span>
                  </div>

                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                            {/* <img
                              className="h-8 w-8 rounded-full"
                              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              alt=""
                            /> */}
                            <UserIcon className="h-5 w-5 rounded-full"/>
                            <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                              <span className="sr-only">Open user menu for </span>{user}
                            </span>
                            <ChevronDownIcon
                              className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          
                        </div>
                        
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {/* <Menu.Item>
                              {({ active }) => (
                                <button type="button"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full'
                                  )}
                                >
                                  Your Profile
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button type="button"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full'
                                  )}
                                >
                                  Settings
                                </button>
                              )}
                            </Menu.Item> */}
                            <Menu.Item>
                              {({ active }) => (
                                <button type="button"
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full'
                                  )}
                                  onClick={() => handleLogout()}
                                >
                                  Logout
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
    )
}

export default TopBar
