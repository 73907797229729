import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface DetailState {
  fields: any;
  initialValues: {[key: string]: any};
  loading: boolean;
}

const initialState: DetailState = {
  fields: [],
  initialValues: {},
  loading: true,
};

export const tableSlice = createSlice({
  name: "detail",
  initialState,
  reducers: {
    setFields: (
      state,
      action: PayloadAction<any>
    ) => {
      state.fields = action.payload
    },
    setInitialValues: (
      state,
      action: PayloadAction<any>
    ) => {
      state.initialValues = action.payload
    },
    setLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFields,
  setInitialValues,
  setLoading,
} = tableSlice.actions;

export default tableSlice.reducer;
