import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Auth } from "aws-amplify";
import { Link, useHistory } from 'react-router-dom'
import { Form, Field, ErrorMessage, Formik  } from 'formik';

import { useAuthContext } from '../../libs/contextLib';

const NewPassword = () => {

    const history = useHistory()
    const { user } = useAuthContext();

    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        if (!user) {
            history.push('/login')
        }
    }, [user, history]);

    function handleSubmit(newPassword: string) {
        Auth.completeNewPassword(
            user,       // the Cognito User Object
            newPassword // the new password
        ).then(() => {
            history.push('/');
            setError(null);
        }).catch(e => {
            setError(e.message);
        });
    }

    const validationSchema = yup.object({
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
        confirmpassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
        });
    
        return (
            <Formik
                initialValues={{ email: '', password: '', confirmpassword: '', }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    handleSubmit(values.password);
                }}
            >
                <div className="flex justify-center items-center mt-8">
                    <div className="w-full max-w-md">
                        <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                            <div>
                                <h1 className="text-center text-xl font-bold pb-6">
                                    New Password
                                </h1>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    Password
                                </label>
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                />
                                <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="password" />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmpassword">
                                    Confirm Password
                                </label>
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="confirmpassword"
                                    type="password"
                                    autoComplete="confirm-new-password"
                                />
                                <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="confirmpassword" />
                            </div>
                            <div className="flex items-center justify-between">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                    Confirm
                                </button>
                                <Link className="inline-block align-baseline font-bold text-sm text-gray-600 hover:text-gray-700" to="/login">
                                    Back to Login
                                </Link>
                            </div>
                            {
                        error ? <div className="text-red-500 pt-5">{error}</div> : null
                    }
                        </Form>
                    </div>
               
                </div>
             
              
            </Formik>
        )
}

export default NewPassword
