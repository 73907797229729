import JSZip from 'jszip';
import FileSaver from 'file-saver';

import dayjs from 'dayjs'
import { DataType, namedBlob } from '../model/types';
import { s3DownloadFile, s3ListFiles, s3RemoveFile, s3UploadFile } from './s3Lib';

export async function deleteFile(key: any) {
  await s3RemoveFile(key)
}

export const save = (file: Blob, name: string) => {
    FileSaver.saveAs(file, name);
}

export const zipFiles = (files: namedBlob[]) => {
    
     
    let zip = new JSZip();

    files.forEach((file: namedBlob)=> {
        zip.file(file.name, file.blob); 
    });
    
    zip.generateAsync({type: "blob"}).then(function(content: Blob) {
        save(content, `download_${dayjs()}.zip`);
    });

}


export const download = (selected: DataType[]) => {

    let s3Result: namedBlob[] = []
    let promises: any = []

    if (!selected || selected.length === 0) {
        throw new Error("Error: Something went wrong when downloading files.")
    }

    else if (selected.length === 1 && selected[0].isFile) {
        s3Download(selected[0].key)
            .then((res: any) => {
                const f: namedBlob = res[0]
                save(f.blob, f.name);
            })
            .catch(err => {
                console.error(err);
            })
    }

    else {
        selected.forEach((item: any) => {
            promises.push(
                s3Download(item.key)
                    .then(res => {
                        res.forEach((item: namedBlob) => {
                            if (item.blob.type !== 'application/x-directory') {
                                s3Result.push(item)
                            }
                        })
                    })
                    .catch(err => {
                        console.error(err);
                    })
                )
        });


        // Assure that results is filled correctly before zip
        Promise.all(promises)
            .then((res) => {
                zipFiles(s3Result)
            })
            .catch(err => {
                console.error(err);
            });
    }
}


export async function s3Download(path: string) {
  const splitPath = path.split('/');
  let result: namedBlob[] = [];
  let promises: any = []
  // if file, else is folder
  if (splitPath[splitPath.length-1] !== '') {
    promises.push(
      s3DownloadFile(path)
        .then((res: any) => {
          result.push({
            name: path,
            blob: res.Body
          })
        })
    )

  } else {
    const listedFiles = await s3ListFiles(path)
    listedFiles.forEach((file: any) => {
      promises.push(
        s3DownloadFile(file.key)
          .then((res: any) => {
            result.push({
              name: file.key,
              blob: res.Body
            });
          })
      )
    });
  }
  await Promise.all(promises)
  return result;
}

export async function s3BulkDownload(s3Keys: string[]) {
  let result: namedBlob[] = [];
  let promises: any = []

  s3Keys.forEach((key: string) => {
    promises.push(
      s3DownloadFile(key)
        .then((res: any) => {
          result.push({
            name: key,
            blob: res.Body
          })
        })
    )
  })
  
  await Promise.all(promises);

  zipFiles(result)
  
  return result;
}

  
export async function uploadFile(file: File, key: string, index: number, progressHandler: Function) {

  return s3UploadFile(file, key, index, progressHandler)
    .then(res => {
      return res;
    })
    .catch(err => {
        console.error(err);
        throw new Error('Something went wrong!')
    })
}

export async function downloadFile(key: string) {

  return s3DownloadFile(key)
    .then(res => {
      return res;
    })
    .catch(err => {
        console.error(err);
        throw new Error('Something went wrong!')
    })
}

export async function downloadFiles(path: string) {

  const files = await s3ListFiles(path)
  let promises: any = []
  const fileBlobs: any = []

  files.forEach((file: any) => {
    promises.push(
      s3DownloadFile(file.key)
      .then((res: any) => {
        fileBlobs.push(res)
      })
      .catch(err => {
          console.error(err);
          throw new Error('Something went wrong!')
      })
    )
    
  })

  await Promise.all(promises)
    .then((res) => {
        return fileBlobs
    })
    .catch(err => {
        console.error(err);
    });
}