import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";



export type FileUploadProp = {
  file: File,
  key: string
}

export interface newUploadPayload {
  tableKey: string
  validFileTypes: Array<string>
  dbPath: string
  s3Path: string
  verificationPath: string
  payloadData?: any
}

export interface UploadState {
  uploadState: string
  validFileTypes: Array<string>
  tableKey: string
  dbPath: string
  s3Path: string
  verificationPath: string
  payloadData?: any
  modalOpen: boolean
}

const initialState: UploadState = {
  uploadState: "Waiting for new files",
  validFileTypes: [],
  tableKey: "",
  dbPath: "",
  s3Path: "",
  verificationPath: "",
  payloadData: {},
  modalOpen: false
};



export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    initializeUploader: (
      state,
      action: PayloadAction<newUploadPayload>
    ) => {  
      state.uploadState = "Waiting for new files";
      state.tableKey = action.payload.tableKey;
      state.validFileTypes = action.payload.validFileTypes;
      state.dbPath = action.payload.dbPath;
      state.s3Path = action.payload.s3Path;
      state.verificationPath = action.payload.verificationPath;
      state.payloadData = action.payload.payloadData;
      state.modalOpen = false;
    },
    setUploadFiles: (
      state
    ) => {  
      state.uploadState = "New Files Added"
    },
    setModalOpenState: (
      state,
      action: PayloadAction<boolean>
    ) => {  
      state.uploadState = "New Files Added"
      state.modalOpen = action.payload;
    },
    setUploadStart: (
      state,
    ) => {  
      state.uploadState = "Upload in progress"
    },
    setUploadDone: (
      state
    ) => {  
      state.uploadState = "Waiting for new files"
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initializeUploader,
  setModalOpenState,
  setUploadFiles,
  setUploadStart,
  setUploadDone,
} = uploadSlice.actions;

export default uploadSlice.reducer;
