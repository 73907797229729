import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';


import CustomForm, { FormItem } from '../../components/Form/CustomForm';
import { addMapKitDB, getOperationalPeriodDB } from '../../libs/dynamoLib';
import {  MapkitReq, OPInterface } from '../../model/types';


/**
 * 
 * Allow the user to create a fire. A successful creation will add the data to DynamoDB
 * And will redirect to the created fire
 * 
 * @returns A form with a field for name and date.
 */
const CreateMapkit = () => {

    const params: {fireId: string, opId: string} = useParams();
    const history = useHistory();

    const [ loading, setLoading ] = useState(true)

    const [ initialValues, setInitialValues ] = useState({
        name: '',
    })


    useEffect(() => {
        
        if (params.opId && params.fireId) {
            getOperationalPeriodDB(params.fireId, params.opId)
            .then((res: OPInterface) => {           
                setInitialValues({
                    name: `${res.name} - Mapkit`,
                });
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            })
        }
        

    }, [params.fireId, params.opId ])

    const formData: FormItem[] = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            id: 'name',
            autocomplete: null,
            placeholder: "Required"
        }
    ]

  

    const validationSchema = yup.object({
        name: yup
            .string()
            .required(),
    });

    const submitHandler = (values: any) => {
        const payload: MapkitReq = {
            fireId: params.fireId,
            opId: params.opId,
            name: values.name,
        }

        addMapKitDB(payload)
            .then(res => {
                history.push(`/fire/${params.fireId}/op/${params.opId}/mapkit/${res.mapkitId}`);
            })
            .catch(err => {
                console.error(err.message);
            })
    }

    return (
        <div className="mt-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            {!loading ? (
                <CustomForm
                    title="Create Map kit"
                    subtitle="Let’s get started by filling in the information below to create a new Map Kit."
                    formData={formData}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    submitHandler={(values: any) => submitHandler(values)}
                    cancelHandler={() => history.push(`/fire/${params.fireId}`)}
                    loading={false}
                />
            ) : <p>Loading...</p>}
            
        </div>
    )
}

export default CreateMapkit
