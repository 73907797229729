import { API } from 'aws-amplify';

import { AgiReq, ArcReq, DeliverableReq, FireReq, FlightReq, FlightStatusReq, IRScanReq, MapkitReq, OPReq, OrthoReq, PdfMapReq, ShapefileReq, SupportReq, VScanReq } from "../model/types";


export const removeRecordDB = async(dest: string) => {
    return API.del("fireai", dest, {});
}

// --------------------------------------------------------------
// |                fire
// --------------------------------------------------------------
export async function listFiresDB() {
    return API.get("fireai", "/fire", {});
}

export async function getFireDB(id: string) {
    return API.get("fireai", `/fire/${id}`, {});
}

export async function addFireDB(data: FireReq) {
    return API.post("fireai", "/fire", {body: data});
}

export async function updateFireDB(fireId: string, data: any) {
    return API.put("fireai", `/fire/${fireId}`, {body: data});
}

export async function removeFireDB(id: string) {
    return API.del("fireai", `/fire/${id}`, {});
}

export async function listFireOPDB(fireId: string) {
    return API.get("fireai", `/fire/${fireId}/op`, {});
}


// --------------------------------------------------------------
// |                operational period
// --------------------------------------------------------------
export async function listOperationalPeriodsDB(fireId: string) {
    return API.get("fireai", `/fire/${fireId}/op`, {});
}

export async function getOperationalPeriodDB(fireId: string, opId: string) {
    return API.get("fireai", `/fire/${fireId}/op/${opId}`, {});
}

export async function addOperationalPeriodDB(data: OPReq) {
    return API.post("fireai", `/op`, {body: data});
}

export async function updateOperationalPeriodDB(fireId: string, opId: string, data: any) {
    return API.put("fireai", `/fire/${fireId}/op/${opId}`, {body: data});
}

export async function removeOperationalPeriodDB(fireId: string, opId: string) {
    return API.del("fireai", `/fire/${fireId}/op${opId}`, {});
}

export async function listOPFlightDB(opId: string) {
    return API.get("fireai", `/op/${opId}/flight`, {});
}

export async function listOPFlightTypeDB(opId: string, type: 0 | 1) {
    return API.get("fireai", `/op/${opId}/flight/type/${type}`, {});
}

export async function listOPMapkitDB(opId: string) {
    return API.get("fireai", `/op/${opId}/mapkit`, {});
}

export async function listOPDeliverableDB(opId: string) {
    return API.get("fireai", `/op/${opId}/deliverable`, {});
}

// --------------------------------------------------------------
// |                flight
// --------------------------------------------------------------
export async function addFlightDB(data: FlightReq) {
    return API.post("fireai", "/flight", {body: data});
}

export async function listFlightsDB(opId: string) {
    return API.get("fireai", `/op/${opId}/flight`, {});
}

export async function getFlightDB(opId: string, flightId: string) {
    return API.get("fireai", `/op/${opId}/flight/${flightId}`, {});
}

export async function updateFlightDB(opId: string, flightId: string, data: any) {
    return API.put("fireai", `/op/${opId}/flight/${flightId}`, {body: data});
}

export async function removeFlightDB(opId: string, flightId: string) {
    return API.del("fireai", `/op/${opId}/flight/${flightId}`, {});
}

export async function getFlightScans(flightId: string, dest: string) {
    return API.get("fireai", `/flight/${flightId}/${dest}`, {});
}

export async function listFlightsHBView() {
    return API.get("fireai", `/flights/`, {});
}

// export async function getFlightScans(flightId: string, dest: string) {
//     return API.get("fireai", `/flight/${flightId}/${dest}`, {});
// }


// --------------------------------------------------------------
// |                Flight Status
// --------------------------------------------------------------
export async function addFlightStatusDB(data: FlightStatusReq) {
    return API.post("fireai", "/flight-status", {body: data});
}

export async function getFlightStatusDB(flightId: string, statusId: string) {
    return API.get("fireai", `/flight/${flightId}/flight-status/${statusId}`, {});
}

export async function listFlightsStatusDB(flightId: string) {
    return API.get("fireai", `/flight/${flightId}/flight-status`, {});
}

export async function listFlightsStatusByStatusDB(statusCode: number) {
    return API.get("fireai", `/flight-status/status/${statusCode}`, {});
}

export async function updateFlightStatusDB(flightId: string, statusId: string, data: any) {
    return API.put("fireai", `/flight/${flightId}/flight-status/${statusId}`, {body: data});
}

export async function removeFlightStatusDB(flightId: string, statusId: string) {
    return API.del("fireai", `/flight/${flightId}/flight-status/${statusId}`, {});
}


// --------------------------------------------------------------
// |                mapkit
// --------------------------------------------------------------

export async function getMapKitDB(opId: string, mapkitId: string) {
    return API.get("fireai", `/op/${opId}/mapkit/${mapkitId}`, {});
}

export async function addMapKitDB(data: MapkitReq) {
    return API.post("fireai", "/mapkit", {body: data});
}

export async function updateMapkitDB(opId: string, mapkitId: string, data: any) {
    return API.put("fireai", `/op/${opId}/mapkit/${mapkitId}`, {body: data});
}

export async function removeMapKitDB(opId: string, mapkitId: string) {
    return API.del("fireai", `/op/${opId}/mapkit/${mapkitId}`, {});
}

export async function removeMapKitItemDB(mapkitId: string, dest: string, dest_id: string) {
    return API.del("fireai", `/mapkit/${mapkitId}/${dest}/${dest_id}`, {});
}



// --------------------------------------------------------------
// |                irscan
// --------------------------------------------------------------
export async function listIRScansDB() {
    return API.get("fireai", "/irscan", {});
}

export async function getIRScanDB(flightId: string) {
    return API.get("fireai", `/flight/${flightId}/irscan`, {});
}

export async function addIRScanDB(data: IRScanReq) {
    return API.post("fireai", "/irscan", {body: data});
}

export async function removeIRScanDB(flightId: string, irscanId: string) {
    return API.del("fireai", `/flight/${flightId}/irscan/${irscanId}`, {});
}

// --------------------------------------------------------------
// |                ortho
// --------------------------------------------------------------
export async function listOrthosDB(mapkitId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/ortho`, {});
}

export async function getOrthoDB(mapkitId: string, orthoId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/ortho/${orthoId}`, {});
}

export async function addOrthoDB(data: OrthoReq) {
    return API.post("fireai", "/ortho", {body: data});
}

export async function removeOrthoDB(mapkitId: string, orthoId: string) {
    return API.del("fireai", `/mapkit/${mapkitId}/ortho/${orthoId}`, {});
}

// --------------------------------------------------------------
// |                pdfmap
// --------------------------------------------------------------
export async function listPDFMapsDB(mapkitId: string, ) {
    return API.get("fireai", `/mapkit/${mapkitId}/pdfmap`, {});
}

export async function getPDFMapDB(mapkitId: string, pdfmapId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/pdfmap/${pdfmapId}`, {});
}

export async function addPDFMapDB(data: PdfMapReq) {
    return API.post("fireai", "/pdfmap", {body: data});
}

export async function removePDFMapDB(mapkitId: string, pdfmapId: string) {
    return API.del("fireai", `/mapkit/${mapkitId}/pdfmap/${pdfmapId}`, {});
}

// --------------------------------------------------------------
// |                shapefile
// --------------------------------------------------------------
export async function listShapefilesDB(mapkitId: string, ) {
    return API.get("fireai", `/mapkit/${mapkitId}/shapefile`, {});
}

export async function getShapefileDB(mapkitId: string, shapefileId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/shapefile/${shapefileId}`, {});
}

export async function addShapefileDB(data: ShapefileReq) {
    return API.post("fireai", "/shapefile", {body: data});
}

export async function removeShapefileDB(mapkitId: string, shapefileId: string) {
    return API.del("fireai", `/mapkit/${mapkitId}/shapefile/${shapefileId}`, {});
}

// --------------------------------------------------------------
// |                vscan
// --------------------------------------------------------------
export async function listVScansDB() {
    return API.get("fireai", "/vscan", {});
}

export async function getVScanDB(flightId: string) {
    return API.get("fireai", `/flight/${flightId}/vscan`, {});
}

export async function addVScanDB(data: VScanReq) {
    return API.post("fireai", "/vscan", {body: data});
}

export async function removeVScanDB(flightId: string, vscanId: string) {
    return API.del("fireai", `/flight/${flightId}/vscan/${vscanId}`, {});
}

// --------------------------------------------------------------
// |                agi
// --------------------------------------------------------------
export async function listAGIsDB(mapkitId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/agi`, {});
}

export async function getAGIDB(mapkitId: string, agiId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/agi/${agiId}`, {});
}

export async function addAGIDB(data: AgiReq) {
    return API.post("fireai", "/agi", {body: data});
}

export async function removeAGIDB(mapkitId: string, agiId: string) {
    return API.del("fireai", `/mapkit/${mapkitId}/agi/${agiId}`, {});
}


// --------------------------------------------------------------
// |                arc
// --------------------------------------------------------------
export async function listARCsDB(mapkitId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/arc`, {});
}

export async function getARCDB(mapkitId: string, arcId: string) {
    return API.get("fireai", `/mapkit/${mapkitId}/arc/${arcId}`, {});
}

export async function addARCDB(data: ArcReq) {
    return API.post("fireai", "/arc", {body: data});
}

export async function removeARCDB(mapkitId: string, arcId: string) {
    return API.del("fireai", `/mapkit/${mapkitId}/arc/${arcId}`, {});
}


// --------------------------------------------------------------
// |                deliverable
// --------------------------------------------------------------
export async function listDeliverablesDB(opId: string) {
    return API.get("fireai", `opId/${opId}/Deliverable`, {});
}

export async function getDeliverableDB(opId: string, id: string) {
    return API.get("fireai", `/op/${opId}/Deliverable/${id}`, {});
}

export async function addDeliverableDB(data: DeliverableReq) {
    return API.post("fireai", "/deliverable", {body: data});
}

export async function removeDeliverableDB(opId: string, deliverableId: string) {
    return API.del("fireai", `/op/${opId}/deliverable/${deliverableId}`, {});
}


// --------------------------------------------------------------
// |                supporting
// --------------------------------------------------------------
export async function listSupportsDB(fireId: string) {
    return API.get("fireai", `/fire/${fireId}/support`, {});
}

export async function getSupportDB(fireId: string, supportId: string) {
    return API.get("fireai", `/fire/${fireId}/support/${supportId}`, {});
}

export async function addSupportDB(data: SupportReq) {
    return API.post("fireai", "/support", {body: data});
}

export async function removeSupportDB(fireId: string, supportId: string) {
    return API.del("fireai", `/fire/${fireId}/support/${supportId}`, {});
}


// --------------------------------------------------------------
// |                General
// --------------------------------------------------------------
export async function listItemsDB(path: string) {
    return API.get("fireai", path, {});
}

export async function getItemDB(path: string) {
    return API.get("fireai", path, {});
}

export async function AddItemDB(path: string, Reqbody: any) {
    return API.post("fireai", path, {body: Reqbody});
}

export async function removeItemDB(path: string) {
    return API.del("fireai", path, {});
}