import React from 'react'


export interface CardInterface {
  name: string
  href: string
  icon: any
  amount: number
  footer: boolean
}

interface CardProps {
  cards: CardInterface[]
  loading: boolean
}

const CustomCardGroup = (props: CardProps) => {
    return (
        <div className="mx-auto">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                
                {/* Card */}
                {props.cards.map((card: any) => (
                  <div key={card.name} className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                            { !props.loading ? (
                              <dl>
                                <dt className="text-sm font-medium text-gray-500 truncate pb-2">{card.name}</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                                </dd>
                              </dl>
                            ) : (
                              <dl className="animate-pulse">
                                <dt className="text-sm font-medium text-gray-500 truncate pb-2"><div className="h-4 bg-gray-300 rounded w-3/4" /></dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900"><div className="h-4 bg-gray-300 rounded w-1/4" /></div>
                                </dd>
                              </dl>
                            )}
                        </div>
                      </div>
                    </div>
                    {
                      card.footer ? (
                        <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={card.href} className="font-medium text-cyan-700 hover:text-cyan-900">
                              View all
                            </a>
                          </div>
                        </div>
                      ) : (
                        null
                      )
                    }
                  </div>
                ))}
              </div>
            </div>
    )
}

export default CustomCardGroup

