import CustomTable, { ColumnType } from '../../components/Table/CustomTable'
import { useHistory } from 'react-router-dom'

import { listFlightsStatusByStatusDB, listFiresDB, listFlightsStatusDB, listOperationalPeriodsDB, listOPFlightTypeDB, getFlightDB, getOperationalPeriodDB } from '../../libs/dynamoLib'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { initializeTableItem, setLoading } from '../../redux/reducers/tableReducer'
import { ClockIcon, DocumentDuplicateIcon, DocumentReportIcon, FireIcon, PaperAirplaneIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { setSelectedFire, setSelectedFlight, setSelectedOP, setSelectorCol } from '../../redux/reducers/selectorReducer'

type TabType = {
  label: string,
  name: string,
  icon: any,
  current: boolean
}

const statusNames = [
  'Unprocessed',
  'Reprocessed',
  'Processed'
]

const fireColumns: ColumnType[] = [
  {label: 'Number', name: "number", type: "header"},
]

const flightStatusColumns: ColumnType[] = [
  {label: 'Flight Number', name: "flightNum", type: "header"},
  {label: 'Name', name: "name", type: "text"},
  {label: 'Modified at', name: "modifiedAt", type: "date"},
]

const opColumns: ColumnType[] = [
  {label: 'Name', name: "name", type: "header"},
]

const flightColumns: ColumnType[] = [
  {label: 'flightNum', name: "flightNum", type: "header"},
  {label: 'Name', name: "name", type: "text"},
  {label: 'Status', name: "status", type: "status"},
]

export default function HBViewFlightSelector() {

  const history   = useHistory();
  const dispatch  = useDispatch<AppDispatch>();
  
  const selectedFireId: string | null     = useSelector((state: RootState) => state.selector.selectedFire);
  const selectedOpId: string | null       = useSelector((state: RootState) => state.selector.selectedOP);
  const selectedFlightId: string | null   = useSelector((state: RootState) => state.selector.selectedFlight);
  const selectorCol: number               = useSelector((state: RootState) => state.selector.selectorCol);
  
  const [tabs, setTabs] = useState<TabType[]>([
    { label: 'Selector', name: 'selector', icon: DocumentDuplicateIcon, current: true },
    { label: 'Processed', name: 'processed', icon: DocumentReportIcon, current: false }
  ])

  const tabClickedHandler = (name: string) => {

    const newTabs = [...tabs]
    tabs.forEach((tab: TabType, i: number) => {
      if (tab.name === name) {
        newTabs[i].current = true
      } else {
        newTabs[i].current = false
      }
    })

    setTabs(newTabs)
  }


  const rowClickedHandler = (item: any, col: number) => {
 
    if (col === 1 && item.fireId) { // Fire Selected
      dispatch(setSelectedFire(item.fireId))
    } else if (col === 2 && item.opId) { // OP Selected
      dispatch(setSelectedOP(item.opId))
    } else if (col === 3 && item.flightId) { // Flight Selected
      dispatch(setSelectedFlight(item.flightId))
      history.push(`/hbview/builder/fire/${selectedFireId}/op/${selectedOpId}/flight/${item.flightId}/`)
    }
    dispatch(setSelectorCol(col))

  }

  const processTableRowClicked = (item: any) => {
    history.push(`/hbview/builder/fire/${item.fireId}/op/${item.opId}/flight/${item.flightId}/`)
  }

  useEffect(() => {

    dispatch(setLoading({key: 'fire', loading: true}));
    listFiresDB()
      .then((res: any) => {
        dispatch(initializeTableItem({
            key: "fire",
            data: res.Items,
            sortBy: "modifiedAt",
            sortDirection: "DESC",
          })
        );
      })
      .catch((err: any) => {
        console.error(err);
      });
      
  }, [dispatch])

  useEffect(() => {
    if (selectedFireId) {

      dispatch(setLoading({key: 'op', loading: true}));
      listOperationalPeriodsDB(selectedFireId)
        .then((res: any) => {
          dispatch(initializeTableItem({
              key: "op",
              data: res.Items,
              sortBy: "modifiedAt",
              sortDirection: "DESC",
            })
          );
        })
        .catch((err: any) => {
          console.error(err);
        });

    }
    
  }, [dispatch, selectedFireId])

  useEffect(() => {
    if (selectedOpId) {
      dispatch(setLoading({key: 'flight', loading: true}));
      listOPFlightTypeDB(selectedOpId, 1)
        .then((res: any) => {

          const data: Array<any> = [...res.Items]
          const promises: any = []
          res.Items.forEach((item: any, i: number) => {
            promises.push(listFlightsStatusDB(item.flightId)
              .then((statusRes:any) => {
                if (statusRes.Items.length === 1) {
                  data[i] = {
                    ...item,
                    ...statusRes.Items[0],
                    hasStatus: true
                  }
                } else if (statusRes.Items.length === 0) {
                  data[i] = {
                    ...item,
                    status: 0,
                    hasStatus: false
                  }
                } else {
                  console.warn("Warning: Multiple status records exist.");
                  data[i] = {
                    ...item,
                    ...statusRes.Items[0],
                    hasStatus: true
                  }
                }
              }))
          })

          Promise.all(promises).then(() => {
            dispatch(initializeTableItem({
                key: "flight",
                data: data,
                sortBy: "modifiedAt",
                sortDirection: "DESC",
              })
            );
          })

        })
        .catch((err: any) => {
          console.error(err);
        });
    }
    
  }, [dispatch, selectedOpId])

  useEffect(() => {

    if (tabs[1].current) {
      
      

      dispatch(setLoading({key: 'flightStatusProcessed', loading: true}));
      listFlightsStatusByStatusDB(2)
        .then((res: any) => {
          const flightPromises: any = []

          res.Items.forEach((status: any) => {
            flightPromises.push(
              getFlightDB(status.opId, status.flightId)
                .then(flightItem => {
                  const newItem = {
                    ...status,
                    ...flightItem
                  }
                  return newItem
                })
            )
          })
          
          Promise.all(flightPromises)
            .then(res => {
              
              const opPromises: any = []
              res.forEach((data: any) => {
                opPromises.push(
                  getOperationalPeriodDB(data.fireId, data.opId)
                    .then(OpItem => {
                      const newItem = {
                        ...data,
                        ...OpItem
                      }
                      return newItem
                    })
              )

              Promise.all(opPromises)
                .then((res: any) => {
                  dispatch(initializeTableItem({
                      key: "flightStatusProcessed",
                      data: res,
                      sortBy: "modifiedAt",
                      sortDirection: "DESC",
                    })
                  );
                  return res;
                })
            })
            
          })

        .catch((err: any) => {
          console.error(err);
        });
      })
    
    }
  }, [dispatch, tabs])
  
  const renderSelector = (
    <Fragment>
      <div className="mt-8 mx-auto grid gap-6 sm:px-6 max-w-7xl grid-flow-col-dense grid-cols-10">
          <div className={classNames("space-y-6 col-span-2", {"col-span-10": selectorCol === 0})}>
            <CustomTable
              tableName="Fires"
              identifier="fireId"
              HeaderIcon={FireIcon}
              columns={fireColumns}
              rowClickedHandler={(item: any) => rowClickedHandler(item, 1)}
              showActions={false}
              usePagination={false}
              tableKey="fire"
              defaultSortBy="modifiedAt"
              initialSelectedId={selectedFireId}

            />
          </div>
        { selectorCol > 0 && 
          <div className={classNames("space-y-6 col-span-2", {"col-span-8": selectorCol === 1})}>
          <CustomTable
            tableName="Operational Period"
            identifier="opId"
            HeaderIcon={ClockIcon}
            columns={opColumns}
            rowClickedHandler={(item: any) => rowClickedHandler(item, 2)}
            showActions={false}
            usePagination={false}
            tableKey="op"
            defaultSortBy="modifiedAt"
            initialSelectedId={selectedOpId}
          />
        </div>
        }
        { selectorCol > 1 && 
          <div className={classNames("space-y-6 col-span-2", {"col-span-6": selectorCol > 1})}>
            <CustomTable
              tableName="Flights"
              identifier="flightId"
              HeaderIcon={PaperAirplaneIcon}
              statusNames={statusNames}
              columns={flightColumns}
              rowClickedHandler={(item: any) => rowClickedHandler(item, 3)}
              showActions={false}
              usePagination={false}
              tableKey="flight"
              defaultSortBy="modifiedAt"
              initialSelectedId={selectedFlightId}

            />
          </div>
        } 
        </div>
      </Fragment>
  );

  const renderProcessed = (
    <div className="mt-8 mx-auto gap-6 sm:px-6 max-w-7xl">
      <CustomTable
        tableName="Processed Flights"
        identifier="id"
        HeaderIcon={PaperAirplaneIcon}
        columns={flightStatusColumns}
        rowClickedHandler={(item: any) => processTableRowClicked(item)}
        showActions={false}
        usePagination={false}
        tableKey="flightStatusProcessed"
        defaultSortBy="modifiedAt"
        // initialSelectedId={selectedFireId}
      />
    </div>
    
  );


  return (
    <div className="flex-1 overflow1-auto focus:outline-none">
      <main className="flex-1 relative pb-8 z-0 overflow-y-auto"> 
      <div>
          <div className="sm:hidden border-gray-300 border-b">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-none"
            >
              {tabs.map((tab: TabType) => (
                <option key={tab.name}>{tab.label}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200 px-2 bg-white">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab: TabType) => (
                  <button
                    key={tab.name}
                    className={classNames(
                      tab.current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                    onClick={() => tabClickedHandler(tab.name)}
                  >
                    <tab.icon
                      className={classNames(
                        tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                        '-ml-0.5 mr-2 h-5 w-5'
                      )}
                      aria-hidden="true"
                    />
                    <span>{tab.label}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        {tabs[0].current ? renderSelector : renderProcessed}
      </main>
    </div>
  )
}

