
import React from 'react'

import * as yup from 'yup';
import { Form, Field, ErrorMessage, Formik, useField, useFormikContext  } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';

import { createNotification, dismissNotification, pushNotification } from '../../redux/reducers/notificationReducer';

export interface FormItem {
    label: string
    name: string
    type: string
    id: string
    autocomplete: string | null
    placeholder: string | null
    opt?: FormOptionType[]
}

type FormOptionType = {
    label: string
    name: string | number
}

interface FormProps {
    title: string
    subtitle: string
    formData: FormItem[]
    submitHandler: Function,
    cancelHandler: Function,
    validationSchema: yup.ObjectSchema<any>,
    initialValues: any
    submitButtonText?: string
    loading: boolean
}

export const DatePickerField = ({ ...props }: any) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <DatePicker
        {...field}
        {...props}
            dateFormat="yyyy/MM/dd"
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val: any) => {
                setFieldValue(field.name, new Date(val));
            }}
            popperPlacement='right'
      />
    );
  };

export const CustomForm = (props: FormProps) => {

    const { title, subtitle, formData, validationSchema, cancelHandler, submitButtonText, initialValues } = props;
    const dispatch = useDispatch<AppDispatch>()
    
    return (

        <div className="mt-8">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={values => {
                    props.submitHandler(values);

                    const formSubmitNotification = createNotification(
                        "Form Submission",
                        `Form data submitted.`,
                        "upload",
                        true
                    )
                    dispatch(pushNotification(formSubmitNotification))
                    setTimeout(() => dispatch(dismissNotification(formSubmitNotification)), 5000);
                }}
            >
                <Form className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div className={classNames({'animate-pulse': props.loading})}>
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                                <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            {
                                props.loading && formData.map((_: any, index: number) => {
                                    return (
                                        <div key={`${index}-loading`} className="sm:col-span-4 mt-1">
                                            <label htmlFor={`${index}-loading`} className="block text-sm font-medium text-gray-700">
                                                <div className="h-4 bg-gray-300 rounded w-3/4" />
                                            </label>
                                            <div className="mt-2">
                                                <div className="h-8 bg-gray-300 rounded w-3/4" />
                                            </div>
                                        </div>
                                    )
                                })
                            } 
                            {
                                !props.loading && formData.map((dataItem: FormItem) => {
                                    if (dataItem.type === 'select' && dataItem.opt) {
                                        return (
                                            <div key={dataItem.id} className="sm:col-span-4">
                                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                    {dataItem.label}
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        as="select"
                                                        name={dataItem.name}
                                                        id={dataItem.id}
                                                        autoComplete={dataItem.autocomplete}
                                                    > 
                                                        {
                                                            dataItem.opt && dataItem.opt.map((op: FormOptionType) => {
                                                                return <option key={op.name} value={op.name}>{op.label}</option>
                                                            })
                                                        }
                                                    </Field>
                                                    <ErrorMessage className="text-red-500 text-xs p-1" component="p" name={dataItem.name} />
                                                </div>
                                            </div>
                                        )
                                    } else if (dataItem.type === 'datepicker') {
                                        return ( 
                                        <div key={dataItem.id} className="sm:col-span-4">
                                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                {dataItem.label}
                                            </label>
                                            <div className="mt-1">
                                                <DatePickerField
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    name={dataItem.name}
                                                    />
                                                <ErrorMessage className="text-red-500 text-xs p-1" component="p" name={dataItem.name} />
                                            </div>
                                        </div>  )     
                                    } else if (dataItem.type === 'textarea') {
                                        return ( 
                                            <div key={dataItem.id} className="sm:col-span-4">
                                                <label htmlFor={dataItem.name} className="block text-sm font-medium text-gray-700">
                                                    {dataItem.label}
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        as="textarea"
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        id={dataItem.id}
                                                        name={dataItem.name}
                                                        autoComplete={dataItem.autocomplete}
                                                        placeholder={dataItem.placeholder}
                                                    />
                                                    <ErrorMessage className="text-red-500 text-xs p-1" component="p" name={dataItem.name} />
                                                </div>
                                            </div>  
                                        )     
                                    } else if (dataItem.type === 'text') {
                                        return (
                                            <div key={dataItem.id} className="sm:col-span-4">
                                                <label htmlFor={dataItem.name} className="block text-sm font-medium text-gray-700">
                                                    {dataItem.label}
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        id={dataItem.id}
                                                        type={dataItem.type}
                                                        name={dataItem.name}
                                                        autoComplete={dataItem.autocomplete}
                                                        placeholder={dataItem.placeholder}
                                                    />
                                                    <ErrorMessage className="text-red-500 text-xs p-1" component="p" name={dataItem.name} />
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={dataItem.id} className="sm:col-span-4">
                                                <label htmlFor={dataItem.name} className="block text-sm font-medium text-gray-700 sm:col-span-3">
                                                    {dataItem.label}
                                                </label>
                                                <div className="mt-1 w-full inline-block sm:col-span-1">
                                                    <Field
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                                                        id={dataItem.id}
                                                        type={dataItem.type}
                                                        name={dataItem.name}
                                                        autoComplete={dataItem.autocomplete}
                                                        placeholder={dataItem.placeholder}
                                                    />
                                                    <ErrorMessage className="text-red-500 text-xs p-1" component="p" name={dataItem.name} />
                                                </div>
                                            </div>
                                        )
                                    }
                                
                                }
                            )}  
                            </div>
                        </div>
                    </div>
                
                    <div className="pt-5">
                        <div className="flex justify-end">
                        <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => cancelHandler()}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {submitButtonText ? submitButtonText : "Submit"}
                        </button>
                        </div>
                    </div>
                </Form>
              
            </Formik>
        </div>
         
    )
}

export default CustomForm
