import React from 'react';
import CustomModal from '../Modal/CustomModal';

import CustomForm from './CustomForm';

interface FormModalProps {
    title: string
    subtitle: string
    submitHandler: Function
    open: boolean
    setOpen: Function
    loading: boolean
    validationSchema: any
    initialValues: any
    formData: any
}

export const FormModal = (props: FormModalProps) => {

    const { title, subtitle, open, setOpen, submitHandler, formData, initialValues, validationSchema, loading } = props;

    const onSubmitHandler = (values: any) => {
        submitHandler(values);
        setOpen(false);
    }

    return (
        <CustomModal open={open} setOpen={(state: boolean) => setOpen(state)}>
            <CustomForm
                title={title}
                subtitle={subtitle}
                formData={formData}
                validationSchema={validationSchema}
                initialValues={initialValues}
                submitHandler={(values: any) => onSubmitHandler(values)}
                cancelHandler={() => setOpen(false)}
                loading={loading}
            />
        </CustomModal>
    )
}

export default FormModal
