import React, { Fragment, useState } from 'react'
import { FormItem } from '../Form/CustomForm';
import FormModal from '../Form/FormModal';

import { useSelector } from 'react-redux';

import { ExclamationCircleIcon, BeakerIcon, CheckCircleIcon } from '@heroicons/react/solid';
import { RootState } from '../../redux/store';

export interface DetailFieldProps {
    label: string
    value: any
    type?: string
}

interface DetailHeaderProps {
    title: string,
    subtitle: string,
    formData: FormItem[]
    expectedFields: string[]
    validationSchema: any
    submitHandler: Function
}

const getStatusIcons = (fieldValue: string) => {
    switch(fieldValue) {
        case 'New':
            return <div className='text-yellow-500 flex'><ExclamationCircleIcon className='h-5 w-5'/><div className='pl-2'>New</div></div>
        case 'Processing':
            return <div className='text-blue-500 flex'><BeakerIcon className='h-5 w-5'/><div className='pl-2'>Processing</div></div>
        case 'In Progress':
            return <div className='text-blue-500 flex'><BeakerIcon className='h-5 w-5'/><div className='pl-2'>In Progress</div></div>
        case 'Completed':
            return <div className='text-green-500 flex'><CheckCircleIcon className='h-5 w-5'/><div className='pl-2'>Completed</div></div>
        case 'Done':
            return <div className='text-green-500 flex'><CheckCircleIcon className='h-5 w-5'/><div className='pl-2'>Done</div></div>
        default:
            return null
    }
}

const DetailHeader = (props: DetailHeaderProps) => {

    const { title, subtitle, expectedFields, formData, validationSchema, submitHandler } = props;

    const fields: Array<any> = useSelector((state: RootState) => state.detail.fields);
    const initialValues: {[key: string]: any} = useSelector((state: RootState) => state.detail.initialValues);
    const loading: boolean = useSelector((state: RootState) => state.detail.loading);

    const [open, setOpen] = useState<boolean>(false);

    return (
        <Fragment>
            <FormModal
                title={title}
                subtitle={subtitle}
                submitHandler={(values: any) => submitHandler(values)}
                open={open}
                setOpen={(isOpen: boolean) => setOpen(isOpen)}
                loading={loading}
                validationSchema={validationSchema}
                initialValues={initialValues}
                formData={formData}
            />
            <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-2 sm:px-6 grid sm:grid-cols-2">
                    <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900 sm:col-span-1 self-center"
                    >
                        { title }
                    </h2>
                    {!loading ? <button
                        type="button"
                        className="sm:col-span-1 inline-flex items-center justify-center px-3 py-1 my-1 border w-20 justify-self-end border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                        onClick={() => setOpen(true)}
                    >
                        Edit
                    </button> : null}
                </div>
                {
                    !loading ? (
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                { 
                                    fields && fields.length > 0
                                        ? fields.map((field: DetailFieldProps, index: number) => {
                                            if (field.value) {
                                                return (
                                                    <div key={`details-field-${index}`} className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
                                                        {field.type && field.type === 'text' && <dd className="mt-1 text-sm text-gray-900">{field.value}</dd>}
                                                        {field.type && field.type === 'textarea' && <dd className="mt-1 text-sm text-gray-900">{field.value}</dd>}
                                                        {field.type && field.type === 'status' && <dd className="mt-1 text-sm text-gray-900 flex flex-row justify-items-start">{getStatusIcons(field.value)}</dd>}
                                                        {!field.type && <dd className="mt-1 text-sm text-gray-900">{field.value}</dd>}
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        }) : null
                                }
                            
                            </dl>
                        </div> 
                    ) : (
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 animate-pulse">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                { expectedFields && expectedFields.length > 0 ? expectedFields.map((Expectedfield: string, index: number) => {
                                    return (
                                        <div key={`loading-${index}`} className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">{Expectedfield}</dt>
                                            <dd className="mt-1 text-sm text-gray-900"><div className="h-4 bg-gray-300 rounded w-3/4" /></dd>
                                        </div>
                                    )
                                }) : null
                            }
                            </dl>
                        </div>
                    )
                }
            </div>
        </section>
        </Fragment>
    )
}

export default DetailHeader
