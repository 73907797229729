import { configureStore } from '@reduxjs/toolkit'

import tableReducer from './reducers/tableReducer'
import detailReducer from './reducers/detailReducer'
import notificationReducer from './reducers/notificationReducer'
import uploadReducer from './reducers/uploadReducer'
import selectorReducer from './reducers/selectorReducer'
import builderReducer from './reducers/builderReducer'

const store = configureStore({
  reducer: {
    detail: detailReducer,
    table: tableReducer,
    notification: notificationReducer,
    upload: uploadReducer,
    selector: selectorReducer,
    builder: builderReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;