import React, { PropsWithChildren } from 'react'
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from '../libs/contextLib';


const HummingbirdRoute = ({children, ...rest}: PropsWithChildren<any>) => {
    
    const { authenticated, authenticating, org } = useAuthContext();

    return (
        <Route {...rest}>
            {!authenticating && authenticated && org === "Hummingbird" ? (
                children
            ) : (
                <Redirect to={
                    `/fire`
                } />
            )}
        </Route>
    )
}

export default HummingbirdRoute
