import React, { useState } from 'react';
import { DocumentAddIcon } from '@heroicons/react/solid';
import CustomModal from '../Modal/CustomModal';

import Uploader from './Uploader';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpenState } from '../../redux/reducers/uploadReducer';

interface UploadmodalProps {
    // open: boolean
    // setOpen: Function
    // dest: string
    // payloadData: any
    // validFileTypes: string[]
    // s3Path: string
    // verificationPath: string
    // tableKey: string
}

export const UploadModal = (props: UploadmodalProps) => {

    const dispatch = useDispatch<AppDispatch>()

    const open = useSelector((state: RootState) => state.upload.modalOpen);

    const [success, setSuccess] = useState(false);

    const onContinueHandler = () => {
        dispatch(setModalOpenState(false));
    }

    const onSuccessHandler = () => {
        setSuccess(true);
    }
    
    return (
        <CustomModal open={open} setOpen={(state: boolean) => {setSuccess(false); dispatch(setModalOpenState(state))}}>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <DocumentAddIcon className="h-8 w-8 text-green-600" aria-hidden="true" />
            </div>
            <Uploader onSuccessHandler={() => onSuccessHandler()}/>
            {success ? <div className="flex justify-center">
                <button
                    className="py-2 px-4 bg-gray-50 border border-green-500 text-green-500 rounded-sm hover:underline hover:shadow-md hover:text-green-600 hover:border-green-600"
                    onClick={() => {setSuccess(false); onContinueHandler()}}
                >
                Continue
            </button>
            </div> : null}
        </CustomModal>
    )
}

export default UploadModal
