import { Route, Switch, Redirect } from "react-router-dom";

import UnauthenticatedRoute from '../hoc/UnauthenticatedRoute'

import Login from "../containers/Login/Login";
import NewPassword from "../containers/NewPassword/NewPassword";
import ResetPassword from "../containers/ResetPassword/ResetPassword";
import ResetPasswordConfirm from "../containers/ResetPassword/ResetPasswordConfirm";
import NotFound from "../containers/NotFound/NotFound"

export const UnAuthRoutes = () => {
  return (
    <Switch>    

      <UnauthenticatedRoute exact path="/login"><Login /></UnauthenticatedRoute>
      <Route exact path="/new-password"><NewPassword /></Route>
      <Route exact path="/reset"><ResetPassword /></Route>
      <Route exact path="/reset/confirm"><ResetPasswordConfirm /></Route>
      <Route exact path="/"><Redirect to='/login'/></Route>
      <Route><NotFound /></Route>
    </Switch>
  );
}

export default UnAuthRoutes;