import React, {  Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Storage } from "aws-amplify";
import CustomChartThreshold from '../../components/Chart/CustomChartThreshold'
import CustomSlider from '../../components/Slider/CustomSlider'

import CustomCanvas from '../../components/Canvas/CustomCanvas';

import { AppDispatch, RootState } from '../../redux/store'
import { decrementFileIndex, incrementFileIndex, setFileNames, setFileCount, reinitialize, setFileIndex, addSegmentationPath, SavedPoints, SavedPoint, Point, setDrawState, addMaskIndices, setCurrentMaskIndices, setCurrentSegmentationPath, setImageScale, SavedSegmentations } from '../../redux/reducers/builderReducer'

import * as yup from 'yup';
import * as imageTools from '../../tools/imageTools'
import HBViewTable from '../../components/Table/HBViewTable'
import { useHistory, useParams } from 'react-router-dom'
import {  addFlightStatusDB, getIRScanDB, listFlightsStatusDB, updateFlightStatusDB } from '../../libs/dynamoLib'
import { s3DownloadFile } from '../../libs/s3Lib'
import CustomBreadcrumbs, { BreadcrumbItem } from '../../components/Breadcrumbs/CustomBreadcrumbs'
import FormModal from '../../components/Form/FormModal';
import { FormItem } from '../../components/Form/CustomForm';
import { ml_s3_bucket } from '../../config';
import { createNotification, dismissNotification, pushNotification } from '../../redux/reducers/notificationReducer';
import { actionFill, actionLine, fillPixels, getHSLColor } from '../../components/Canvas/CanvasTools';
import CanvasAction from '../../components/Canvas/CanvasActions/CanvasAction';

const GeoTIFFImport = require('geotiff');


const HBViewBuilder = () => {

    const params: {fireId: string, opId: string, flightId: string} = useParams();

    const { fromBlob } = GeoTIFFImport;
    const dispatch = useDispatch < AppDispatch>()
  
    const history = useHistory()

    const formData: FormItem[] = [
        {
            label: 'Good for ML',
            id: 'good',
            name: 'good',
            type: 'checkbox',
            autocomplete: null,
            placeholder: null,
        },
        {
          label: 'Humid',
          id: 'humid',
          name: 'humid',
          type: 'checkbox',
          autocomplete: null,
          placeholder: null,
      },
        {
          label: 'Rocky',
          id: 'rocky',
          name: 'rocky',
          type: 'checkbox',
          autocomplete: null,
          placeholder: null,
        },
        {
          label: 'Mountainous',
          id: 'mountainous',
          name: 'mountainous',
          type: 'checkbox',
          autocomplete: null,
          placeholder: null,
        }
      ]

    const initialValues = {
        good: false,
        humid: false,
        rocky: false,
        mountainous: false
    };

    const validationSchema = yup.object({
        good: yup.boolean(),
        humid: yup.boolean(),
        rocky: yup.boolean(),
        mountainous: yup.boolean(),
    });
    
    const fileIndex: number | null = useSelector((state: RootState) => state.builder.fileIndex);
    const fileCount: number = useSelector((state: RootState) => state.builder.fileCount);
    const fileNames: string[] = useSelector((state: RootState) => state.builder.fileNames);
    
    const savedSegmentations: SavedPoints = useSelector((state: RootState) => state.builder.savedSegmentations);
    const savedMaskIndices: SavedSegmentations  = useSelector((state: RootState) => state.builder.savedMaskIndices);
    
    const currentFileName: string | null = useSelector((state: RootState) => state.builder.currentFile);
    const currentSegmentation: SavedPoint | null = useSelector((state: RootState) => state.builder.currentSegmentation);
    const currentMaskIndices: number[] | null = useSelector((state: RootState) => state.builder.currentMaskIndices);
    
    const drawState: number = useSelector((state: RootState) => state.builder.drawState);
    const imageScale: number = useSelector((state: RootState) => state.builder.imageScale);

    const [open, setOpen] = useState<boolean>(false);
    const [fileBlobs, setFileBlobs] = useState<Array<any>>([])
    const [imageArr, setImageArr] = useState<Int32Array[]>([])
    const [originalImageArr, setOriginalImageArr] = useState<Int32Array[]>([])
    
    const [data, setData] = useState<Uint8ClampedArray>()
    const [height, setHeight] = useState<number>()
    const [width, setWidth] = useState<number>()
    const [brightness, setBrightness] = useState<number>(0);    /* [-100 - 100] */
    const [contrast, setContrast] = useState<number>(0);        /* [-100 - 100] */
    const [threshold, setThreshold] = useState<number>(500);    /* [0 - 2**16 ?or 6000] */
    // const [brightnessGraph, setBrightnessGraph] = useState<Uint8ClampedArray>(new Uint8ClampedArray(256).fill(0));
    
    const [imageRowMax, setImageRowMax] = useState<Uint16Array>(new Uint16Array(0));
    const [imagesLoading, setImagesLoading] = useState<boolean>(true)
    const [isProcessing, setIsProcessing] = useState<boolean>(true)
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

    const jsonToCSV = useCallback((jsonData: any) => {

        const fields = Object.keys(jsonData[0])      
        const replacer = function(_: any, value: any) { return value === null ? '' : value }
        const csv: any = [
            fields.join(','),
            ...jsonData.map((row: any) => fields.map((fieldName: string) => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n');

        return csv
    },[])

    const uploadToS3 = useCallback(async(data: {key: string, blob: Blob}[], cb?: Function) => {

        const promises: any = []

        promises.push(data.forEach((item: {key: string, blob: Blob}) => {

            Storage.put(item.key, item.blob, {
                bucket: ml_s3_bucket,
                contentType: item.blob.type,
                progressCallback(progress: any) {
                    if (cb) {
                        cb(progress);
                    }
                },
            })
            .catch(err => {
                console.error(err);
            })
        }))

        await Promise.all(promises);

    },[])

    const compileSegmentationImage = useCallback(async() => {

        let outputBlobs: {key: string, blob: Blob}[] = []
    
        const canvasToBlobPromises: any = []

        const canvas: any = document.createElement('canvas');
        const context = canvas.getContext("2d");
        
        if (height && width){
            console.log(`Creating masks of size (hxw): ${height}x${width}` )
            canvas.height = height
            canvas.width = width
        }

        context.lineWidth = 1;
        context.lineCap = "round";
        context.lineJoin = "round";

        const segmentationPathFiles = Object.keys(savedSegmentations)
        const segmentationFillFiles = Object.keys(savedMaskIndices)

        const segmentationFiles = new Set([...segmentationPathFiles, ...segmentationFillFiles])
        
        let current_hue = 0
        let current_saturation = 100

        // For each unique file that has a segmentation
        segmentationFiles.forEach((fileName: string) => {
            
            // Fill background with black
            context.clearRect(0, 0, canvas.height, canvas.width);
            context.fillStyle = "black";
            context.fillRect(0, 0, canvas.width, canvas.height);

            // Reset used Colors. Use Hue because values are in HSL representation
            current_hue = 0
            current_saturation = 100

            // Add segmentation paths to the canvas
            if (savedSegmentations[fileName]) {
                const {segmentations, fillPoints} = savedSegmentations[fileName]
                console.log(`Drawing ${segmentations.length} paths for file ${fileName}`)

                // For each segmentation path in the file
                segmentations.forEach((path: Point[], index: number) => {
                    const color = getHSLColor(current_hue, current_saturation);
                    console.log(`Drawing path with hue ${current_hue} and saturation ${current_saturation}`)
                    if (color) {
                        // For each segment in the path
                        path.forEach((segment: Point) => {
                            actionLine(segment.startX, segment.startY, segment.endX, segment.endY, color, context)              
                        })
                        const canvasImageData = context.getImageData(0, 0, canvas.width, canvas.height)
                        const res = actionFill(fillPoints[index][0], fillPoints[index][1], color, canvasImageData, canvasImageData, null, true)
                        const newOffScreenImageData = res.imageData
                        context.putImageData(newOffScreenImageData, 0, 0);

                        current_hue = current_hue + 1
                        if (current_hue > 360) {
                            current_hue = 0
                            current_saturation = (current_saturation - 2) % 100
                        }
                    }
                })

            } 

            // Add segmentation fills to the canvas
            if (savedMaskIndices[fileName]) {
                let segmentations = savedMaskIndices[fileName]
                console.log(`Drawing ${segmentations.length} fills for file ${fileName}`)
                segmentations.forEach((indices: number[]) => {
                    const color = getHSLColor(current_hue, current_saturation);
                    console.log(`Drawing fill with hue ${current_hue} and saturation ${current_saturation}`)
                    if (color) {
                        fillPixels(canvas, indices, color)

                        current_hue = current_hue + 1
                        if (current_hue > 360) {
                            current_hue = 0
                            current_saturation = (current_saturation - 2) % 100
                        }
                    }
                })
            }
            
            // Take the drawn segmentations and push canvas to blob action to list of promises
            canvasToBlobPromises.push(
                new Promise((resolve, reject) => {
                    
                    canvas.toBlob((blob: any) => {
                        const nameAsPng = fileName.split('.').slice(0, fileName.split('.').length - 1).join('.') + '.png'
                        const blobObj = {
                            key: `${params.flightId}/masks/${params.flightId}-${nameAsPng}`,
                            blob: blob
                        }
                        outputBlobs.push(blobObj);
                        resolve(true);
                    }, 'image/png')
                    
                })
            );
        })

        // await all promises completed (success or fail)
        await Promise.all(canvasToBlobPromises);
        

        return outputBlobs

        
        
    },[savedSegmentations, savedMaskIndices, params.flightId, height, width])

    const buildCSVDataForExport = useCallback((formData: any) => {

        const segmentationPathFiles = Object.keys(savedSegmentations)
        const segmentationFillFiles = Object.keys(savedMaskIndices)

        const segmentationFiles = new Set([...segmentationPathFiles, ...segmentationFillFiles])

        let csvData: Array<any> = []

        segmentationFiles.forEach((fileName: string) => {
            csvData.push({
                "fileName": fileName,
                "fireId": params.fireId,
                "opId": params.opId,
                "flightId": params.flightId,
                "imageFile": `${params.flightId}-${fileName}`,
                "maskFile": `${params.flightId}-${fileName.split('.').slice(0, fileName.split('.').length - 1).join('.') + '.png'}`,
                "humid": formData.humid.toString(),
                "rocky": formData.rocky.toString(),
                "mountainous": formData.mountainous.toString(),
                "good_quality": formData.good ? 1 : 0,
                "num_segmentation_paths": savedSegmentations[fileName] ? savedSegmentations[fileName]['segmentations'].length : 0,
                "num_segmentation_fills": savedMaskIndices[fileName] ? savedMaskIndices[fileName].length : 0
            })
        })
        
        return csvData
        
    }, [savedSegmentations, savedMaskIndices, params]);

    const buildFileDataForExport = useCallback(async() => {

        let exports: {blob: Blob, key: string}[] = []
        let UniquefileBlobs = new Set(fileBlobs)


        const prepFiles = new Promise((resolve, reject) => {
            UniquefileBlobs.forEach((file: any) => {
                if (savedSegmentations[file['name']] || savedMaskIndices[file['name']]) {
                    exports.push({
                        key: `${params.flightId}/images/${params.flightId}-${file['name']}`,
                        blob: file.blob
                    })
                }
            })
            resolve(true);
        })
            
        await prepFiles;

        return exports

    }, [savedSegmentations, savedMaskIndices, params.flightId, fileBlobs]);

    const submitFormHandler = useCallback(async(values: any) => {

        
        // // Get the files (key and blob) for each of the bounding boxes
        let fileData: {key: string, blob: Blob}[] = await buildFileDataForExport()
        let maskData: {key: string, blob: Blob}[] = await compileSegmentationImage()

        // Create the CSV blob for this flight
        const csvObject = await buildCSVDataForExport(values);
        const csvBlob: Blob = new Blob([jsonToCSV(csvObject)], { type: 'text/csv;charset=utf-8;' });

        const exportData: {key: string, blob: Blob}[] = [...fileData, ...maskData, {
            key: `${params.flightId}/metadata.csv`,
            blob: csvBlob
        }]
        
        // CSV file:        flightId/flightId.csv
        // Image Files:     flightId/images/flightId-filename.type
        // Mask Files:      flightId/masks/flightId-filename.type
        uploadToS3(exportData)
            .then(() => {
                listFlightsStatusDB(params.flightId)
                    .then(res => {
                        if (res.Items && res.Items.length === 0) {
                            addFlightStatusDB({
                                flightId: params.flightId,
                                opId: params.opId,
                                fireId: params.fireId,
                                status: 2 // Processed (2 == green)
                            })
                        } else if (res.Items && res.Items.length === 1) {
                            updateFlightStatusDB(params.flightId, res.Items[0].id, {
                                status: 1 // Reprocessed (1 == blue)
                            })
                        } else {
                            console.warn('Something went wrong. Multiple records found.')
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        const dynamoErrorNotification = createNotification(
                            "Upload Error",
                            `Something went wrong when creating/updating the database`,
                            "error",
                            true
                        )
                        dispatch(pushNotification(dynamoErrorNotification))
                        setTimeout(() => dispatch(dismissNotification(dynamoErrorNotification)), 5000);
                    })
                
            })
            .then(() => {
                const uploadNotification = createNotification(
                    "Upload Success",
                    `Upload completed successfully. ${exportData.length} files uploaded. This includes the images, their respective masks, and a metadata csv.`,
                    "success",
                    true
                  )
                  dispatch(pushNotification(uploadNotification))
                  setTimeout(() => dispatch(dismissNotification(uploadNotification)), 5000);
            })
            .then(() => {
                history.push('hbview')
            })
            .catch(err => {
                console.error(err)

                const s3ErrorNotification = createNotification(
                    "Upload Error",
                    `Something went wrong when uploading files.`,
                    "error",
                    true
                )
                dispatch(pushNotification(s3ErrorNotification))
                setTimeout(() => dispatch(dismissNotification(s3ErrorNotification)), 5000);
            })


    },[buildFileDataForExport, compileSegmentationImage, buildCSVDataForExport, dispatch, history, jsonToCSV, params.fireId, params.flightId, params.opId, uploadToS3])

    const openFile = useCallback(async(file: Blob) => {
        try {
            const tiff = await fromBlob(file);
            const image = await tiff.getImage();

            

            setHeight(image.getHeight());
            setWidth(image.getWidth());

            const data: any = await image.readRasters({interleave: true});
            let min = 2**32
            let max = -min
            data.forEach((ele: number, index: number) => {
                if (ele < min) {
                    min = ele
                }
                if (ele > max) {
                    max = ele
                }
            });
            

            return {message: 'success', error: null, data: data, min, max}
        
        } catch(err) {
            console.error(err);
            return {message: 'error', error: err, data: null}
        }
    }, [fromBlob])

    const processFile = async(dataArr: any): Promise<{message: string, error: string | null, data: Int32Array}> => {
        try {

            // let tmp = new Int32Array(dataArr);
  
            // const {mean, std} = imageTools.getStandardDeviation(dataArr)
            // const minMaxAvg = imageTools.getMinMax(dataArr)

            // let offset = 0
            // let min = minMaxAvg.min
            // let max = minMaxAvg.max

            // if (minMaxAvg.min < 0) {
            //     offset = Math.abs(minMaxAvg.min)
            //     min = 0
            // }

            // if (max > mean && max > mean+(5*std)) {
            //     max = mean+(5*std)
            // }

            // max = max + offset

            // for (let i = 0; i < tmp.length ; i++) {
            //     tmp[i] = (
            //         imageTools.clamp((((tmp[i]+offset) - min) / (max - min)), 0, 1) * 245
            //     )
            // }

            // let expandedData: Uint16Array = imageTools.singleToRGBA(tmp)       
            // const data: Uint8ClampedArray = new Uint8ClampedArray(expandedData)

            // return {message: 'success', error: null, data: data}
            return {message: 'success', error: null, data: dataArr}

        } catch (err: any) {
            console.error(err);
            return {
                message: 'error',
                error: err,
                data: new Int32Array([])}
        }
    }

    const preprocessFiles = useCallback(async(fileArr: {name: string, blob: Blob}[]) => {

        let promises: any = []
        let fileNameArr: any = []
        let fileImageArr: any = []
        let originalImageArr: any = []

        if (fileArr.length > 0) {

            for (let i = 0; i < fileArr.length; i++) {

                const file: Blob = fileArr[i].blob
                const fileName: string = fileArr[i].name
                
                promises.push(
                    openFile(file)
                        .then(fileRes => {
                            let fileData = fileRes.data
                            

                            let ImgData: Int32Array = new Int32Array(fileData)
                            
                            if (fileRes.max && fileRes.min) {
                                for (let i = 0; i < ImgData.length ; i++) {
                                    ImgData[i] = ImgData[i] - fileRes.min 
                                }
                            }
                 

                            originalImageArr.push(ImgData)
                            processFile(ImgData)
                                .then(processedRes => {
                                    if (processedRes.error) {
                                        console.error('Something went wrong when processing file ', fileName);
                                    }
                                    if (processedRes.data && fileName) {
                                        fileNameArr.push(fileName)
                                        fileImageArr.push(new Int32Array(processedRes.data))  
                                    }
                                    return true
                                })
                    })
                )
            }
            
    
            Promise.all(promises)
                .then(res => {
                    setImageArr(fileImageArr);
                    setOriginalImageArr(originalImageArr)
                    dispatch(setFileNames(fileNameArr))
                })
                .catch(err => {
                    console.error(err);
                })

        }
    }, [dispatch, openFile])

    const startProcessing = useCallback((fileBlobs: {name: string, blob: Blob}[]) => {

        if (fileBlobs.length === 0) {
            console.error('No Files to process')
            return
        }

        dispatch(setFileIndex(0))
        dispatch(setFileCount(fileBlobs.length));

        // setFiles(newfileList);
        preprocessFiles(fileBlobs)
            .then(() => {
                setIsProcessing(false)
            });

    },[dispatch, preprocessFiles])


    const saveCurrentSegmentation = useCallback(() => {
        if (currentFileName) {

            switch (drawState) {
                case 0: // outline tool
                    if (currentSegmentation && currentSegmentation.path.length > 0) {
                        dispatch(addSegmentationPath({
                            segmentationPoints: currentSegmentation.path,
                            fillPoint: currentSegmentation.fillPoint,
                            fileName: currentFileName
                        }))
                        dispatch(setCurrentSegmentationPath({
                            segmentation: null,
                            fileName: currentFileName
                        }))   
                    }
                    
                  break;
              
                case 1: // fill tool
                    if (currentMaskIndices) {
                        dispatch(addMaskIndices({
                            maskIndices: currentMaskIndices,
                            fileName: currentFileName
                        }))
                        dispatch(setCurrentMaskIndices({
                            indices: null,
                            fileName: currentFileName
                        }))
                    }
    
                    break;

                default:
                    console.warn('Unknown State for draw tool')
                    break;
            }
            
            // Save to database. Get item and if exists, update, else create new
            

        }
        
    }, [drawState, currentSegmentation, currentMaskIndices, currentFileName, dispatch])

    useEffect(() => {

        // Handle key pressed event
        function handleKeyPress(e: any) {         
            var event = window.event ? window.event : e;
            if (event.shiftKey) {
                switch(event.code) {
                    case 'ArrowLeft': // Left key
                        e.preventDefault()
                        dispatch(decrementFileIndex());
                        break;
                    case 'ArrowRight': // Right key
                        e.preventDefault()
                        dispatch(incrementFileIndex());
                        break;
                    case 'Space': // Space key
                        e.preventDefault()
                        console.log('Saving drawing')
                        saveCurrentSegmentation();
                        break;
                    case 'Digit1': // 1 key
                        e.preventDefault()
                        console.log('Using to outline tool')
                        dispatch(setDrawState(0))
                        break;
                    case 'Digit2': // 2 key
                        e.preventDefault()
                        console.log('Using to fill tool')
                        dispatch(setDrawState(1))
                        break;
                    default:
                        break;
                }
            } else {
                if (event.code === 'Space') {
                    e.preventDefault()
                    console.log('Saving drawing')
                    saveCurrentSegmentation();
                }
            }
        }

        // Add event
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            return window.removeEventListener('keydown', handleKeyPress);
        }
    }, [dispatch, saveCurrentSegmentation]);

    useEffect(() => {

        dispatch(reinitialize());

        setBreadcrumbs([
            { name: 'Selector', to: `/hbview/` },
            { name: 'Builder', to: `/hbview/builder/fire/${params.fireId}/op/${params.opId}/flight/${params.flightId}/` }
        ])

        let promises: any = [];
        let results: any = [];

        // get blobs for flight
        getIRScanDB(params.flightId)
            .then((res: any) => {

                const data: Array<any> = res.Items;

                let s3Keys: string[] = []

                data.forEach((item:any) => {
                    s3Keys.push(item.s3Key)
                })

                s3Keys.forEach((key: string) => {
                    promises.push(
                        s3DownloadFile(key)
                            .then((res: any) => {
                                
                                results.push({
                                    name: key.split('/')[key.split('/').length-1],
                                    blob: res.Body
                                })
                            })
                    )
                })

                Promise.all(promises)
                    .then(() => {
                        results.sort((a: any, b: any) => {
                            if (a.name < b.name) {
                                return -1;
                            }
                            else if (a.name > b.name) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                            
                        })
                        setFileBlobs(results)
                        const loadingNotification = createNotification(
                            "Loading Images",
                            `Loading ${results.length} images. Depending on the number of photos, this may take some time.`,
                            "info",
                            true
                        )
                        dispatch(pushNotification(loadingNotification))
                        setTimeout(() => dispatch(dismissNotification(loadingNotification)), 5000);
                        setImagesLoading(false);
                    })
            })
            .catch((err: any) => {
                console.error(err);
            })
       
    }, [params.fireId, params.opId, params.flightId, dispatch]);

    useEffect(() => {
        if (fileBlobs.length > 0) {
            startProcessing(fileBlobs)
        }
      }, [fileBlobs, startProcessing]);

    useEffect(() => {
        const timer = setTimeout(() => {
            
            if (fileIndex !== null && imageArr[fileIndex]) {
                // let updatedData: Uint8ClampedArray = Uint8ClampedArray.from(imageArr[fileIndex])
                
                let img: Int32Array = imageArr[fileIndex]
                let dataInt32: Int32Array = imageTools.applyOutlierNormalization(img, 3)
                let expandedData: Uint16Array = imageTools.singleToRGBA(dataInt32)
                let dataUInt8Clamped: Uint8ClampedArray = Uint8ClampedArray.from(expandedData)
                dataUInt8Clamped = imageTools.applyContrast(dataUInt8Clamped, contrast);
                dataUInt8Clamped = imageTools.applyBrightness(dataUInt8Clamped, brightness); 
                dataUInt8Clamped = imageTools.applyThreshold(dataUInt8Clamped, originalImageArr[fileIndex], threshold);
            
                setData(dataUInt8Clamped);

            }
        }, 100); // Otherwise there is errors of loading multiple things
        return () => clearTimeout(timer);
      }, [imageArr, fileIndex, brightness, contrast, threshold, originalImageArr]);

      useEffect(() => {
        const timer = setTimeout(() => {
            if (fileIndex !== null && originalImageArr[fileIndex] && height && width) {
                setImageRowMax(imageTools.getMaxAxisOriginal(originalImageArr[fileIndex], height, width))        
                // setImageRowMaxOriginal(imageTools.getMaxAxisOriginal(originalData[fileIndex], height, width))        
            }
        }, 200);
        return () => clearTimeout(timer);
      }, [originalImageArr, fileIndex, height, width, threshold]);
    

    return (
        <Fragment>
            {/* <div className="m-10">
                <input type="file" id="file" multiple onChange={(e) => newfilesAdded(e)}/>
            </div>  */}
            <FormModal
                title="Submit"
                subtitle="Enter some details regarding the photos you processed."
                submitHandler={(values: any) => submitFormHandler(values)}
                open={open}
                setOpen={(isOpen: boolean) => setOpen(isOpen)}
                loading={imagesLoading || isProcessing}
                validationSchema={validationSchema}
                initialValues={initialValues}
                formData={formData}
            />
            <CustomBreadcrumbs crumbs={breadcrumbs}/>
            
            <div className="flex flex-wrap">
                <div className="p-2">
                        { imagesLoading  && <p>Loading Images...</p>}
                        { !imagesLoading && isProcessing && <p>Processing Images...</p>}
                        { !isProcessing && !data && <p>Applying filters to image...</p>}
                        
                        { !imagesLoading && !isProcessing && data && height && width ?  (
                            <Fragment>
                                <div className="w-full flex flex-row justify-between">
                                    <div className="inline-flex">
                                        <h2 className="font-semibold text-lg p-2 pt-3 align-middle items-center">Tool: </h2>
                                        <CanvasAction selected={drawState === 0} clicked={() => dispatch(setDrawState(0))}>Outline Tool</CanvasAction>
                                        <CanvasAction selected={drawState === 1} clicked={() => dispatch(setDrawState(1))}>Fill Tool</CanvasAction>
                                    </div>
                                    <div className="inline-flex">
                                        <h2 className="font-semibold text-lg p-2 pt-3 align-middle items-center">Scale: </h2>
                                        <CanvasAction selected={imageScale === 1} clicked={() => dispatch(setImageScale(1))}>1x</CanvasAction>
                                        <CanvasAction selected={imageScale === 2} clicked={() => dispatch(setImageScale(2))}>2x</CanvasAction>
                                    </div>
                                    

                                </div>
                                <div>
                                    {fileIndex !== null && fileNames[fileIndex] ? fileNames[fileIndex] : null}
                                </div>
                                <CustomCanvas data={data} height={height} width={width} />

                                <div className="inline-flex py-1 w-full justify-center">
                                    <button type="button" className="border border-l px-2 py-1" onClick={() => dispatch(decrementFileIndex())}>Back</button>
                                    <div className="border px-2 py-1"> Image {fileIndex !== null ? fileIndex + 1 : 'undefined'} of {fileCount} </div>
                                    <button type="button" className="border border-r px-2 py-1" onClick={() => dispatch(incrementFileIndex())}>Next</button>
                                </div>
                                <div className="inline-flex py-1 w-full justify-center">
                                    <div className="border px-2 py-1">
                                        {fileIndex !== null && fileNames[fileIndex] ? fileNames[fileIndex] : null}
                                    </div>
                                    <button
                                        type="button"
                                        className="border border-r px-2 py-1"
                                        onClick={() => saveCurrentSegmentation()}
                                    >
                                        Add
                                    </button>
                                </div>

                            </Fragment>
                            
                        ) : null}
                </div>

                <div className="flex">
                    <div className='p-2 h-full w-full'>
                        { !imagesLoading && !isProcessing && data && height && width ?  (
                            <Fragment>
                                <CustomChartThreshold data={imageRowMax} threshold={threshold} YMax={5000}/>
                            </Fragment>
                        ) : null}
                        { !imagesLoading && !isProcessing && data && height && width ?  (
                            <Fragment>
                                <CustomSlider label={'Brightness'} min={-100} max={100} value={brightness} setValue={setBrightness}/>
                                <CustomSlider label={'Contrast'} min={-100} max={100} value={contrast} setValue={setContrast}/>
                                <CustomChartThreshold data={imageRowMax} threshold={threshold} YMax={600}/>
                                <CustomSlider label={'Threshold'} min={0} max={9000} value={threshold} setValue={setThreshold}/>
                            </Fragment>
                        ) : null}
                    </div>
                </div>

                <div className="flex ">
                    <div className='p-2'>
                        { !imagesLoading && !isProcessing && data && height && width ?  (
                            <Fragment>
                                {savedSegmentations && <button
                                    type="button"
                                    className=" inline-flex items-center justify-center px-3 py-1 my-1 border w-20 justify-self-end border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                    onClick={() => setOpen(true)}
                                >
                                    Submit
                                </button>}
                                <HBViewTable />            
                            </Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default HBViewBuilder
