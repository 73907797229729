import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


import { HomeIcon } from '@heroicons/react/solid'
// import { AppAbbreviation } from '../../helpers/labels'


export interface BreadcrumbItem {
    name: string,
    to: string
}

interface CustomBreadcrumbsProps {
    crumbs: BreadcrumbItem[]
}

const CustomBreadcrumbs = (props: CustomBreadcrumbsProps) => {


    const [ pathArr, setPathArr ] = useState<BreadcrumbItem[]>([])

    useEffect(() => {
        if (props.crumbs) {
            let paths: BreadcrumbItem[] = props.crumbs;
            setPathArr(paths)
        }
        
    }, [props.crumbs])

    return (
            <div>
                <nav className="bg-white border-b border-gray-200 flex" aria-label="Breadcrumb">
                    <ol className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
                        <li className="flex">
                            <div className="flex items-center">
                                <Link to="/fire" className="text-gray-400 hover:text-gray-500">
                                    <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                    <span className="sr-only">Home</span>
                                </Link>
                            </div>
                        </li>
                        {pathArr && pathArr.length > 0 && pathArr.map((item: BreadcrumbItem, index: number) => (
                            <li key={`${item}-${index}`} className="flex">
                                <div className="flex items-center">
                                <svg
                                    className="flex-shrink-0 w-6 h-full text-gray-200"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                <Link
                                    to={item.to}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={index === pathArr.length ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                                </div>
                            </li>
                        ))}
                    </ol>
                    </nav>
            </div>
    )
}

export default CustomBreadcrumbs
