import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface BuilderState {
  selectedFire: string | null
  selectedOP: string | null
  selectedFlight: string | null
  selectorCol: number
}

const initialState: BuilderState = {
  selectedFire: null,
  selectedOP: null,
  selectedFlight: null,
  selectorCol: 0
};

export const builderSlice = createSlice({
  name: "selector",
  initialState,
  reducers: {
    reinitialize: (
      state
    ) => {
      state = initialState;
    },
    setSelectorCol: (
      state,
      action: PayloadAction<number>
    ) => {
      state.selectorCol = action.payload
    },
    setSelectedFire: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedFire = action.payload
    },
    setSelectedOP: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedOP = action.payload
    },
    setSelectedFlight: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedFlight = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  reinitialize,
  setSelectedFire,
  setSelectedOP,
  setSelectedFlight,
  setSelectorCol
} = builderSlice.actions;

export default builderSlice.reducer;
