import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'

import { deleteFile, uploadFile } from '../../libs/fileLib';
import { fileSize, fileTypeFromName } from '../../utils/fileUtils';

import { API } from 'aws-amplify';
import { useAuthContext } from '../../libs/contextLib';
import dayjs from 'dayjs';
import { setTableItem } from '../../redux/reducers/tableReducer';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { createNotification, dismissNotification, pushNotification } from '../../redux/reducers/notificationReducer';

type FileProp = {
    file: File,
    invalid: boolean
}

type FileUploadProp = {
    file: File,
    key: string
}

interface UploaderProps {
    onSuccessHandler?: Function
}

export const Uploader = (props: UploaderProps) => {

    const { onSuccessHandler } = props;

    let tableKey: string = useSelector((state: RootState) => state.upload.tableKey);
    let dbPath: string = useSelector((state: RootState) => state.upload.dbPath);
    let s3Path: string = useSelector((state: RootState) => state.upload.s3Path);
    let verificationPath: string = useSelector((state: RootState) => state.upload.verificationPath);
    let validFileTypes: Array<string> = useSelector((state: RootState) => state.upload.validFileTypes);
    let payloadData: any = useSelector((state: RootState) => state.upload.payloadData);

    const currentData = useSelector(
        (state: RootState) => state.table.data[tableKey]
      );

    const dispatch = useDispatch<AppDispatch>()

    // File states
    const [validFiles, setValidFiles] = useState<FileProp[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<FileProp[]>([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState<FileProp[]>([]);
    
    // const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
    const [totalUploadPercentage, setTotalUploadPercentage] = useState<number>(0);

    const [currentlyUploading, setCurrentlyUploading] = useState<FileUploadProp[]>([]);
    const [doneUploading, setDoneUploading] = useState<boolean>(false);

    
    const [uploadState, setUploadState] = useState<{state: string, color: string, percent: number}[]>([]);

    // const [uploadOpen, setUploadOpen] = useState<boolean>(false);

    const [UploaderState, setUploaderState] = useState<"Selecting" | "Uploading">("Selecting")

    // Errors and UI states
    const [dragging, setDragging] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { org } = useAuthContext();

    // File state change - Sync file states
    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file: FileProp[], current: FileProp) => {
            const x = file.find((item: FileProp) => item.file.name === current.file.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);
    
    }, [selectedFiles]);

    const validateFile = useCallback((file: File) => {

        let valid = false
        let t: string = ''

        
        if (file.type === '') { // Look at suffix type
            t = fileTypeFromName(file.name)
        } else { // parse out prefix type
            t = file.type.split('/')[file.type.split('/').length - 1]
        }

        if (validFileTypes.indexOf(t) !== -1) {
            valid = true;
        } else {
            console.error('File type "' + t + '" is not supported');
            setErrorMessage('File Unsupported');
        }
        
        return valid;
    }, [validFileTypes])


    
    const handleFiles = useCallback((files: FileList): void => {

        for (let i = 0; i < files.length; i++) {
            let newFile = {
                file: files[i],
                invalid: false
            }
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, newFile]);
                
            } else {
                newFile['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, newFile]);
                setUnsupportedFiles(prevArray => [...prevArray, newFile]);
            }     
        }

        setUploadState(Array.apply(null, Array(files.length)).map(() => ({
            state: 'Uploading',
            color: 'bg-blue-500',
            percent: 0
        })));

    }, [validateFile])

    const filesSelected = (event: any) => {
        if (event.target && event.target.files) {
            const files: FileList = event.target.files;
            if (files.length) {
                handleFiles(files);
            }
        }
        
    }

    const removeFileFromUploads = (name: string) => {
        const validFileIndex = validFiles.findIndex((e: FileProp) => e.file.name === name);
        validFiles.splice(validFileIndex, 1);

        setValidFiles([...validFiles]);
        setSelectedFiles([...validFiles])

        const unsupportedFileIndex = unsupportedFiles.findIndex((e: FileProp) => e.file.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }

    const handleDragEnter = (e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer && e.dataTransfer.files) {
            setDragging(false);
        }
    };

    const handleDragOver = (e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.dataTransfer && e.dataTransfer.files) {
            setDragging(true);
        }
    };

    const handleDrop = (e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer) {
            setDragging(false);
            const files: FileList = e.dataTransfer.files;
            if (files.length) {
                handleFiles(files);
            }
        }
    };

    const progressHandler = (progressEvent: any, fileIndex: number) => {
        
        let percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
        
        if (percent === 100 && uploadState[fileIndex] && uploadState[fileIndex].state !== 'Done') {

            setUploadState((prevState: any) => {
                let newState = [...prevState]
                newState[fileIndex] = {
                    state: 'Done',
                    color: 'bg-green-500',
                    percent: percent
                }
                return newState;
            });
            setTotalUploadPercentage((prevPercent: number) => prevPercent + (100.0 / uploadState.length))

        } else {
            setUploadState((prevState: any) => {
                let newState = [...prevState]
                newState[fileIndex] = {
                    state: 'Uploading',
                    color: 'bg-blue-500',
                    percent: percent
                }
                return newState;
            });
        }
    }

    const sortUploads = useCallback((files: FileProp[]) : FileUploadProp[] => {
        const updatedFiles: FileUploadProp[] = []
        const oldFiles: FileProp[] = [...files]
        oldFiles.forEach((obj: FileProp) => {
            // const type = fileTypeFromName(obj.file.name).toUpperCase()
            
            const newObj = {
                file: obj.file,
                key: (obj.file.name).replace(' ', '')
            }
            
            updatedFiles.push(newObj)
        })

        return updatedFiles

    }, [])

    const UploadAndCreateRecord = (key: string, name: string, file: File, index: number) => {
        
        uploadFile(file, key, index, progressHandler)
            .then(() => {
                const payload = {
                    body: {
                        ...payloadData, // fireId, flightId, opId, etc
                        name: name,
                        s3Key: key
                    },
                    headers: {},
                };

                API.post('fireai', dbPath, payload)
                    .catch(err => {
                        console.error(err.message)
                        deleteFile(key);
                    })
            })
    }


    const getExistingFiles = async (verificationPath: string) => {
        
        // Check if the file exists
        return API.get('fireai', verificationPath, {})
            .then((res: any) => {
                return res;
            })
    }

    const uploadFiles = () => {
        if (org) {
            // setShowUploadProgress(true);

            setUploaderState('Uploading')

            const uploadNotification = createNotification(
                "Upload Started",
                `Your upload of ${validFiles.length} files has started. Please do not leave the page.`,
                "upload",
                true
            )
            dispatch(pushNotification(uploadNotification))
            setTimeout(() => dispatch(dismissNotification(uploadNotification)), 5000);

            const uploadFiles: FileUploadProp[] = sortUploads(validFiles)
            
            setCurrentlyUploading(uploadFiles)

            let recordArr: Array<any> = []
            console.log('Attempting to upload');
            getExistingFiles(verificationPath)
                .then((existingFileRecords: any)=> {

                    let promises: any = [];
                    uploadFiles.forEach((obj: FileUploadProp, index: number) => {
            
                        const fileKey = org + '/' + s3Path + obj.key;
                        
                        const fileName = fileKey.split('/')[fileKey.split('/').length - 1];
                        const fileBlob = obj.file
        
                        let exists = null;

                        if (existingFileRecords && existingFileRecords.Items && existingFileRecords.Items.length > 0) {
                            exists = existingFileRecords.Items.find((fileRecord: any) => {
                                return fileRecord.name === fileName && fileRecord.s3Key === fileKey
                            })
                        }

                        if (exists) {

                            const newFileModifiedDate = dayjs(fileBlob.lastModified)
                            const existingRecordDate = dayjs(exists.modifiedAt)

                            // IF the existing record is before the new file, update.
                            //  ELSE do nothing, existing is newer
                            if (existingRecordDate.isBefore(newFileModifiedDate)) {
                                promises.push(
                                    UploadAndCreateRecord(fileKey, fileName, fileBlob, index)
                                )
                                recordArr.push({
                                    ...payloadData,
                                    name: fileName,
                                    key: fileKey,
                                    createdAt: new Date().toISOString(),
                                    modifiedAt: new Date().toISOString()
                                })
                            } else {
                                setUploadState((prevState: any) => {
                                    prevState[index] = {
                                        state: 'Newer upload found.',
                                        color: 'bg-red-500',
                                        percent: 100
                                    }
                                    return prevState;
                                });
                            }
                        } else {
                            // If the file does not exist, then add it as normal and set version to 1
                            promises.push(
                                UploadAndCreateRecord(fileKey, fileName, fileBlob, index)
                            )
                            recordArr.push({
                                ...payloadData,
                                name: fileName,
                                key: fileKey,
                                createdAt: new Date().toISOString(),
                                modifiedAt: new Date().toISOString()
                            })
                        }
                    })

                    return promises
                })
                .then((promises: any) => {
                    Promise.all(promises)
                        .then(() => {
                            if (tableKey && recordArr && recordArr.length > 0) {
                                if (currentData) {
                                    dispatch(setTableItem({key: tableKey, data: [...currentData, ...recordArr]}))
                                } else {
                                    dispatch(setTableItem({key: tableKey, data: recordArr}))
                                }
                            }
                        })         
                        .then(() => {
                            setValidFiles([]);
                            setSelectedFiles([]);
                            setUnsupportedFiles([]);
                        })
                        .then(() => {
                            console.log('All requests have been made');          
                            setDoneUploading(true);            
                        })
                })
                .catch(err => {
                    console.error(err);

                    const errorNotification = createNotification(
                        "Upload Unsuccessful",
                        "Something went wrong when uploading files.",
                        "fail",
                        true
                    );
                    dispatch(pushNotification(errorNotification));
                    setTimeout(() => dispatch(dismissNotification(errorNotification)), 5000);
                }) 
        } else {
            setErrorMessage('Error: Unauthenticated.');
            const errorNotification = createNotification(
                "Unauthenticated",
                "You attempted to do something you are not authorized to do. If this is a mistake, please contact your administrator.",
                "fail",
                true
            );
            dispatch(pushNotification(errorNotification));
            setTimeout(() => dispatch(dismissNotification(errorNotification)), 5000);
        }
    }

    useEffect(() => {
        if (doneUploading && (Math.round((totalUploadPercentage + Number.EPSILON) * 100) / 100) >= 99.99) {
            console.log('Success');
            const uploadNotification = createNotification(
                "Upload Completed",
                "Upload has completed.",
                "success",
                true
            );
            dispatch(pushNotification(uploadNotification));
            setTimeout(() => dispatch(dismissNotification(uploadNotification)), 5000);
            
            if (onSuccessHandler) {
                onSuccessHandler();
            }

            setDoneUploading(false)
        }
    }, [totalUploadPercentage, doneUploading, dispatch, onSuccessHandler])

    const renderFileSelect = (
        <div className="w-full m-4">
            <div className="w-full px-4">
                {
                    unsupportedFiles.length === 0 && validFiles.length ? (
                        <button className="btn btn-blue inline-flex items-center" onClick={() => uploadFiles()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                            <span>Upload Files</span>
                        </button>
                    ) : ''
                } 
                {
                    unsupportedFiles.length ? (
                        <p className="text-red-500">Please remove all unsupported files.</p>
                    ) : ''
                }
                <div
                    className={classNames("my-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md", {"border-blue-500" : dragging})}
                    onDrop={(e: any) => handleDrop(e)}
                    onDragOver={(e: any)  => handleDragOver(e)}
                    onDragEnter={(e: any)  => handleDragEnter(e)}
                    onDragLeave={(e: any)  => handleDragLeave(e)}
            
                >
                    <div className="space-y-2 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </svg>
                        <div className="flex text-md text-gray-600 justify-center">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                                <span>Upload files</span>
                                <input id="file-upload" name="file-upload" type="file" multiple className="sr-only" onChange={(e: any) => filesSelected(e)}/>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-sm text-gray-500 text-center">
                            Valid file types: {<strong>{validFileTypes.join(', ')}</strong>}
                        </p>
                    </div>
                </div>
            </div>
            <div className="file-display-container w-full px-4">
                {
                    validFiles.map((data, i) => (
                        <div className="w-full flex flex-row justify-between my-3 h-6" key={`${data.file.name}-${i}`} >
                            <div className="flex flex-row justify-start" onClick={!data.invalid ? () => null : () => removeFileFromUploads(data.file.name)}>
                                <div className="h-6 w-6 mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" className=" text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </div>
                                <div className="file-name px-1">
                                    {fileTypeFromName(data.file.name)}
                                </div>
                                <span className="file-name px-1 text-blue-500">
                                    {data.file.name}
                                </span>
                                <span className="file-size px-1">
                                    ({fileSize(data.file.size)})
                                </span>
                                {
                                    data.invalid ? (
                                        <span className='text-red-500 pl-1 flex flex-row'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div className="px-1">
                                                {errorMessage}
                                            </div>
                                        </span>
                                    ) : null
                                }
                            </div>
                            <div className="flex flex-row">
                                <div className='pl-1 flex cursor-pointer' onClick={() => removeFileFromUploads(data.file.name)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        )
                    )
                }
            </div>
        </div>
    )

    const renderUploadProgress = (
    
            <div className="flex flex-col justify-center items-center w-full  bg-white">
                <div className="w-full py-5 text-center text-xl">
                    File(s) Uploading...
                </div>
                <div className="w-full overflow-y-scroll">   
                    {currentlyUploading && currentlyUploading.length > 0 && uploadState && uploadState.map((state: {state: string, color: string, percent: number}, index: number) => {
                        return (
                            <div key={`loader-${index}`} className="grid grid-cols-1 w-full px-5 py-2 lg:grid-flow-col-dense lg:grid-cols-8">
                                {
                                    currentlyUploading[index].file.name ? <div className="col-span-1 lg:col-span-4 px-2">
                                        {currentlyUploading[index].file.name}
                                    </div> : <p>loading...</p>
                                }
                                <div className="col-span-1 w-full justify-between lg:col-span-4 flex items-center">
                                    <div className="bg-gray-200 w-full rounded-md">
                                        <div 
                                            className={classNames("rounded-md text-center items-center text-white font-bold col-span-2 text-xs py-1", state.color)}
                                            style={{width: `${state.percent}%`}}>
                                            <p>{state.state}</p>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        )
                    })}
                </div>  
        </div>
    )

    return (
        <div className="flex flex-col justify-center items-center w-full">
            {UploaderState === 'Selecting' ? renderFileSelect : null}
            {UploaderState === 'Uploading' ? renderUploadProgress : null}
        </div>
    )
}

export default Uploader
