import React from 'react'

// import Uploader from '../../components/Uploader/Uploader'
// import { useHistory } from 'react-router';

const Upload = (props: any) => {

    // const history = useHistory()

    return (
        <p>Upload</p>
        // <Uploader path="DATA/" postUploadHandler={() => history.push("/drive")}/>
    )
}

export default Upload
