export const statusNames = new Map<number, string>();
statusNames.set(0, "New");
statusNames.set(1, "Processing");
statusNames.set(2, "Completed");


export const fireCoordinateNotation = new Map<string, string>();
fireCoordinateNotation.set("dd", "Decimal degrees (DD)");
fireCoordinateNotation.set("ddm","Degrees decimal minutes (DDM)");
fireCoordinateNotation.set("dms","Degrees-minutes-seconds (DMS)");
fireCoordinateNotation.set("gars","Global Area Reference System (GARS)");
fireCoordinateNotation.set("georef","GEOREF (World Geographic Reference System)");
fireCoordinateNotation.set("utm","Universal Transverse Mercator (UTM)");
fireCoordinateNotation.set("usng","United States National Grid (USNG)");
fireCoordinateNotation.set("mgrs","Military Grid Reference System (MGRS)");

export const RegionAbbreviation = new Map<string, string>();
RegionAbbreviation.set("bc", "British Columbia");
RegionAbbreviation.set("ab", "Alberta");
RegionAbbreviation.set("sk", "Saskatchewan");
RegionAbbreviation.set("mb", "Manitoba");
RegionAbbreviation.set("on", "Ontario");
RegionAbbreviation.set("yk", "Yukon");

export const AppAbbreviation = new Map<string, string>();
AppAbbreviation.set("fire", "Fire");
AppAbbreviation.set("op", "Operational Period");
AppAbbreviation.set("flight", "Flight");
AppAbbreviation.set("irscan", "Infrared Scan");
AppAbbreviation.set("vscan", "Visual Scan");
