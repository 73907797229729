import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface NotificationItemState {
  id: number
  label: string
  message: string
  type: string
  createdAt: string
  useTimeout: boolean
}

interface NotificationState {
  notifications: Array<NotificationItemState>
  show: boolean
}

const initialState: NotificationState = {
  notifications: [],
  show: true
};

export const createNotification = (label: string, message: string, type: string, useTimeout: boolean) => {
  const newNotification: NotificationItemState = {
    id: Date.now(),
    label: label,
    type: type,
    message: message,
    createdAt: new Date().toISOString(),
    useTimeout: useTimeout
  }

  return newNotification
}

const removeNotification = (item: NotificationItemState, notificationArr: Array<NotificationItemState>) => {
  
  const tmpNArr: Array<NotificationItemState> = [...notificationArr];

  const itemIndex: number = tmpNArr.indexOf(item);

  tmpNArr.splice(itemIndex, 1);

  return tmpNArr
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    pushNotification: (
      state,
      action: PayloadAction<NotificationItemState>
    ) => {  
      if (state.notifications.length === 0) {
        state.show = true;
      }

      state.notifications.push(action.payload);
    },
    popNotification: (
      state
    ) => {
      if (state.notifications.length > 0) {
        state.notifications.pop();
      }
      if (state.notifications.length === 0) {
        state.show = false;
      }
      
    },
    dismissNotification: (
      state,
      action: PayloadAction<NotificationItemState>
    ) => {
      const notifications = removeNotification(action.payload, state.notifications);
      state.notifications = notifications;
      if (state.notifications.length === 0) {
        state.show = false;
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  pushNotification,
  popNotification,
  dismissNotification
} = notificationSlice.actions;

export default notificationSlice.reducer;
