import {
  FireIcon
} from '@heroicons/react/solid'

import { FireInterface } from '../../model/types'

import CustomCardGroup, { CardInterface } from '../../components/Card/CardGroup/CustomCardGroup'
import CustomTable, { ColumnType } from '../../components/Table/CustomTable'
import { useHistory } from 'react-router-dom'

import { listFiresDB } from '../../libs/dynamoLib'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/store'
import { initializeTableItem, setLoading } from '../../redux/reducers/tableReducer'

const statusNames = [
  'New Fire',
  'Mapping in progress',
  'Mapping completed'
]

const columns: ColumnType[] = [
  {label: 'Number', name: "number", type: "header"},
  {label: 'Date', name: "date", type: "date"},
  {label: 'Region', name: "region", type: "region"},
  {label: 'Modified At', name: "modifiedAt", type: "date"},
]

export default function FireList() {

  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  
  const rowClickedHandler = (item: FireInterface) => {
    history.push(`/fire/${item.fireId}`)
  }

  const [stats, setStats] = useState<CardInterface[]>([])

  const fires: FireInterface[] = useSelector((state: RootState) => state.table.data['fire']);
  const loadingFires: boolean = useSelector((state: RootState) => state.table.loading['fire']);

  useEffect(() => {
    
    let stats: CardInterface[] = [
      { name: 'New Fire', href: '#', icon: FireIcon, amount: 0, footer: false },
      { name: 'Mapping in Progress', href: '#', icon: FireIcon, amount: 0, footer: false },
      { name: 'Mapping Complete', href: '#', icon: FireIcon, amount: 0, footer: false }
  ]
    
    if (fires && fires.length > 0) {
      fires.forEach((fire: FireInterface) => {
        stats[fire.status].amount += 1
    })}

    setStats(stats)
  
  }, [fires])

  useEffect(() => {
    dispatch(setLoading({key: 'fire', loading: true}));
    listFiresDB()
      .then((res: any) => {
        dispatch(initializeTableItem({
          key: "fire",
          data: res.Items,
          sortBy: "modifiedAt",
          sortDirection: "DESC",
        }));
      })
      .catch((err: any) => {
        console.error(err);
      })
  }, [dispatch])

  return (
    <div className="flex-1 overflow1-auto focus:outline-none">
      <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
        
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <CustomCardGroup
              cards={stats}
              loading={loadingFires}
            />
            <CustomTable
              showActions={false}
              tableName="Fires"
              identifier="fireId"
              HeaderIcon={FireIcon}
              statusNames={statusNames}
              columns={columns}
              rowClickedHandler={(item: FireInterface) => rowClickedHandler(item)}
              usePagination={false}
              tableKey="fire"
              defaultSortBy="modifiedAt"
            />
          </div>
        </div>
      </main>
    </div>
  )
}
