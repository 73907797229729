import React from 'react'
import * as yup from 'yup';


import CustomForm, { FormItem } from '../../components/Form/CustomForm';
import { FireReq } from '../../model/types';

import { addFireDB } from '../../libs/dynamoLib';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';


/**
 * 
 * Allow the user to create a fire. A successful creation will add the data to DynamoDB
 * And will redirect to the created fire
 * 
 * @returns A form with a field for name and date.
 */
const CreateFire = () => {

    const history = useHistory()

    const [error, setError] = useState<any>(null)

    const formData: FormItem[] = [
        {
            label: 'Number',
            name: 'number',
            type: 'text',
            id: 'number',
            autocomplete: null,
            placeholder: 'Required',
        },
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            id: 'name',
            autocomplete: null,
            placeholder: 'Optional',
        },
        {
            label: 'Region',
            name: 'region',
            type: 'select',
            id: 'region',
            autocomplete: null,
            placeholder: null,
            opt: [
                {
                    label: "British Columbia",
                    name: "bc",
                  },
                  {
                    label: "Alberta",
                    name: "ab",
                  },
                  {
                    label: "Saskatchewan",
                    name: "sk",
                  },
                  {
                    label: "Manitoba",
                    name: "mb",
                  },
                  {
                    label: "Ontario",
                    name: 'on'
                  },
                  {
                    label: "Yukon",
                    name: "yk",
                  }
            ]
        },
        {
            label: 'Coordinate Notation',
            name: 'coordinatenotation',
            type: 'select',
            id: 'coordinatenotation',
            autocomplete: null,
            placeholder: null,
            opt: [
                {label: "Decimal degrees (DD)", name: "dd"},
                {label: "Degrees decimal minutes (DDM)", name: "ddm"},
                {label: "Degrees-minutes-seconds (DMS)", name: "dms"},
                {label: "Global Area Reference System (GARS)", name: "gars"},
                {label: "GEOREF (World Geographic Reference System)", name: "georef"},
                {label: "Universal Transverse Mercator (UTM)", name: "utm"},
                {label: "United States National Grid (USNG)", name: "usng"},
                {label: "Military Grid Reference System (MGRS)", name: "mgrs"}
            ]
        },
        {
            label: 'Date',
            name: 'date',
            type: 'datepicker',
            id: 'date',
            autocomplete: null,
            placeholder: 'Required',
        },
    ]

    const initialValues = {
        number: '',
        name: '',
        region: 'bc',
        date: new Date(),
        coordinatenotation: 'dd'
    }

    const validationSchema = yup.object({
        number: yup
            .string()
            .min(3)
            .required(),
        name: yup
            .string(),
        date: yup
            .string(),
        region: yup
            .string()
            .required(),
        coordinatenotation: yup
            .string()
            .required(),
    });

    const submitHandler = (values: any) => {

        const payload: FireReq = {
            number: values.number,
            name: values.name,
            region: values.region,
            status: 0,
            date: new Date(values.date).toISOString(),
            coordinatenotation: values.coordinatenotation,
        }


        addFireDB(payload)
            .then(res => {
                setError(null)
                history.push('/fire');
            })
            .catch(err => {
                setError(err.response.data.message)
            })   
    }

    // useEffect(() => {
    //     console.log(error)
    // }, [error])

    return (
        <div className="mt-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <CustomForm
                title="Create Fire"
                subtitle="Let’s get started by filling in the information below to create a new fire project."
                formData={formData}
                validationSchema={validationSchema}
                initialValues={initialValues}
                submitHandler={(values: any) => submitHandler(values)}
                cancelHandler={() => history.push('/fire')}
                loading={false}
            />
            { error ? <p className="text-sm font-medium text-red-600 text-right pt-2">{error}</p> : null}
        </div>
    )
}

export default CreateFire
