import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, XAxis, YAxis, LineChart, Line, CartesianGrid } from 'recharts';

interface CustomChartThresholdProps {
  data: Uint8ClampedArray | Uint16Array
  threshold: number
  YMax: number
}

type GraphType = {
  "index": number
  "threshold": number
  "value": number
}

const CustomChart = (props: CustomChartThresholdProps) => {

  // const [maxBrightness, setMaxBrightness] = useState<number>(0)
  // const [minBrightness, setMinBrightness] = useState<number>(2*32)
  // const [graphBrightness, setGraphBrightness] = useState<Array<number>>(new Array(256))

  const [graphData, setGraphData] = useState<GraphType[]>([])
      
  const { data, threshold, YMax } = props;

      useEffect(() => {
        if (data && threshold) {
          let localGraphData: GraphType[] = []
          data.forEach((value: number, index: number) => {
            localGraphData[index] = {index, threshold, value}
          })
          setGraphData(localGraphData);
        }
      }, [data, threshold])


      return (
        <div className="h-80 w-full">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={1024} height={512} data={graphData}>
              <XAxis dataKey="index" />
              <YAxis  domain={[0, YMax]} allowDataOverflow/>
              <CartesianGrid strokeDasharray="3 3" />
              {/* <Tooltip /> */}
              {/* <Bar dataKey="value" fill="#000000" /> */}
              <Line type="monotone" dataKey="value" stroke="#222222" strokeWidth={2} dot={false}/>
              <Line type="monotone" dataKey="threshold" stroke="red" strokeWidth={2} dot={false}/>
            </LineChart>
          </ResponsiveContainer>
        </div>
      );

}

export default CustomChart;
