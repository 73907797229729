export const supportedShapeFileType = [
    'dbf', 'shx', 'shp', 'prj', 'gdb', 'kml', 'lyr', 'cpg', 'dbf', 'sbn', 'sbx', 'zop', 'x-zip-compressed'
]

export const supportedPDFMapType = [
    'jpg', 'jpeg', 'mxd', 'pdf', 'xls'
]

export const supportedOrthoType = [
    'tif', 'tiff', 'kmz', 'kml'
]

export const supportedAgiType = [
    'psx', 'x-zip-compressed', 'xml'
]

export const supportedDeliverableType = [
    'pdf', 'jpg', 'jpeg', 'png'
]

export const supportedIRScanType = [
    'tif', 'tiff', 'jpg', 'jpeg'
]

export const supportedVScanType = [
    'jpg', 'jpeg', 'png'
]

export const supportedArcType = [
    'mxd'
]

export const supportedSupportType = [
    'jpg', 'jpeg', 'jpg', 'png', 'x-zip-compressed'
]