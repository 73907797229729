import { Storage } from "aws-amplify";

/**
 * Upload a file to a specified location in S3
 * @param file File to be uploaded
 * @param key key location to upload the file
 * @param cb callback function that is executed to show upload progress
 * @returns A promise resolving the object on success
 */
export async function s3UploadFile(file: File, key: string, index: number, cb?: Function) {
    return await Storage.put(key, file, {
      contentType: file.type,
      progressCallback(progress: any) {
          if (cb) {
              cb(progress, index);
          }
      },
    });
  }

/**
 * Get a file from S3. Does not receive a blob
 * @param path S3 key pathing to file
 * @returns  A promise
 */
export async function s3GetFile(path: string) {
    return await Storage.get(path)
}

/**
 * Download a file from S3. Receives a blob
 * @param path S3 key pathing to file
 * @returns  A promise
 */
export async function s3DownloadFile(key: string) {
    return await Storage.get(key, {
        download: true,
    })
}

/**
 * List files from S3 conforming to path
 * @param path S3 key pathing to file
 * @returns  A promise
 */
export async function s3ListFiles(path: string) {
    return await Storage.list(path)
}

/**
 * Remove a file from S3
 * @param key S3 key pathing to file
 * @returns A promise
 */
export async function s3RemoveFile(key: string) {
    return await Storage.remove(key);
}