import React, { PropsWithChildren } from 'react'
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from '../libs/contextLib';


const UnauthenticatedRoute = ({children, ...rest}: PropsWithChildren<any>) => {
    const { authenticated } = useAuthContext();

    return (
        <Route {...rest}>
            {!authenticated ? (
                children
            ) : (
                <Redirect to="/login" />
            )}
        </Route>
    )
}

export default UnauthenticatedRoute
