import React, { useEffect } from 'react'
import * as yup from 'yup';


import CustomForm, { FormItem } from '../../components/Form/CustomForm';

import { getFireDB } from '../../libs/dynamoLib';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

interface InitialValueInterface {
    number: string
    name: string
    region: string
    date: string
    coordinatenotation: string
}

/**
 * 
 * Allow the user to Edit a fire. A successful creation will add the data to DynamoDB
 * And will redirect to the created fire
 * 
 * @returns A form with a field for name and date.
 */
const EditFire = () => {

    const history = useHistory()
    const params: {fireId?: string} = useParams();
    
    const [error, setError] = useState<any>(null)
    const [fireId, setFireId] = useState<string>()
    const [initialValues, setInitialValues] = useState<InitialValueInterface>({
        number: '',
        name: '',
        region: '',
        date: '',
        coordinatenotation: ''
    })
    const [loadingFire, setLoadingFire] = useState<boolean>(true);

    const formData: FormItem[] = [
        {
            label: 'Number',
            name: 'number',
            type: 'text',
            id: 'number',
            autocomplete: null,
            placeholder: 'Required',
        },
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            id: 'name',
            autocomplete: null,
            placeholder: 'Optional',
        },
        {
            label: 'Region',
            name: 'region',
            type: 'select',
            id: 'region',
            autocomplete: null,
            placeholder: null,
            opt: [
                {
                    label: "British Columbia",
                    name: "bc",
                  },
                  {
                    label: "Alberta",
                    name: "ab",
                  },
                  {
                    label: "Saskatchewan",
                    name: "sk",
                  },
                  {
                    label: "Manitoba",
                    name: "mb",
                  },
                  {
                    label: "Ontario",
                    name: 'on'
                  },
                  {
                    label: "Yukon",
                    name: "yk",
                  }
            ]
        },
        {
            label: 'Coordinate Notation',
            name: 'coordinatenotation',
            type: 'select',
            id: 'coordinatenotation',
            autocomplete: null,
            placeholder: null,
            opt: [
                {label: "Decimal degrees (DD)", name: "dd"},
                {label: "Degrees decimal minutes (DDM)", name: "ddm"},
                {label: "Degrees-minutes-seconds (DMS)", name: "dms"},
                {label: "Global Area Reference System (GARS)", name: "gars"},
                {label: "GEOREF (World Geographic Reference System)", name: "georef"},
                {label: "Universal Transverse Mercator (UTM)", name: "utm"},
                {label: "United States National Grid (USNG)", name: "usng"},
                {label: "Military Grid Reference System (MGRS)", name: "mgrs"}
            ]
        },
        {
            label: 'Date',
            name: 'date',
            type: 'datepicker',
            id: 'date',
            autocomplete: null,
            placeholder: 'Required',
        },
    ]


    useEffect(() => {
        setFireId(params.fireId)
    }, [params.fireId])

    useEffect(() => {
        if (fireId) {
            getFireDB(fireId)
                .then(res => {
                    setInitialValues({
                        number: res.number,
                        name: res.name,
                        region: res.region,
                        date: dayjs(res.date).format('YYYY/MM/DD'),
                        coordinatenotation: res.coordinatenotation
                    })
                    
                })
                .then(() => {
                    setLoadingFire(false);
                    setError(null);
                })
                .catch(err => {
                    console.error(err);
                    setError(err.message);
                })
        }
    }, [fireId])

    

    const validationSchema = yup.object({
        number: yup
            .string()
            .min(3)
            .required(),
        name: yup
            .string(),
        date: yup
            .string()
            .required(),
        region: yup
            .string()
            .required(),
        status: yup
            .string()
            .required(),
        coordinatenotation: yup
            .string()
            .required(),
    });

    const submitHandler = (values: any) => {
        if (fireId) {

            // const payload = {
            //     number: values.number,
            //     name: values.name,
            //     date: values.date,
            //     status: values.status,
            //     region: values.region,
            //     coordinatenotation: values.coordinatenotation,
            // }

            // updateFireDB(fireId, payload)
            //     .then(res => {
            //         console.log(res)
            //         setError(null)
            //         history.push('/fire');
            //     })
            //     .catch(err => {
            //         setError(err.message)
            //     })

        } else {
            console.error('FireId not defined')
        }
        
    }

    return (
        <div className="mt-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
           { !loadingFire && <CustomForm
                title="Edit Fire"
                subtitle={`Editing ${fireId}`}
                formData={formData}
                validationSchema={validationSchema}
                initialValues={initialValues}
                submitHandler={(values: any) => submitHandler(values)}
                cancelHandler={() => history.push(`/fire/${fireId}`)}
                loading={loadingFire}
            />}
            { error ? <p className="text-sm font-medium text-red-600 text-right pt-2">Error: Something went wrong. Please try again.</p> : null}
        </div>
    )
}

export default EditFire
