import React, { PropsWithChildren } from 'react'
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuthContext } from '../libs/contextLib';


const AuthenticatedRoute = ({children, ...rest}: PropsWithChildren<any>) => {
    
    const { pathname, search } = useLocation();
    const { authenticated } = useAuthContext();

    return (
        <Route {...rest}>
            {authenticated ? (
                children
            ) : (
                <Redirect to={
                    `/login?redirect=${pathname}${search}`
                } />
            )}
        </Route>
    )
}

export default AuthenticatedRoute
