import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup';
import { Form, Field, ErrorMessage, Formik  } from 'formik';

import { Auth } from "aws-amplify";
import { useAuthContext } from '../../libs/contextLib';


export const Login = (props: any) => {

    const [errorMessage, setErrorMessage] = useState(null);
    const { authenticated, setUser, setAuthenticated, setAuthenticating } = useAuthContext();
    const history = useHistory()

    function handleSubmit(email: string, password: string) { 
        Auth.signIn(email, password)
            .then((user) => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setUser(user);
                    history.push('/new-password')
                } else {
                    setUser(user.attributes.email);
                    setAuthenticated(true);
                    history.push('/');
                }
            }).catch((e: any) => {
                console.error(e.message);
                setErrorMessage(e.message);
                setAuthenticated(false);
                setAuthenticating(false);
            });
    }

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
    });

    return (
        !authenticated ? (
            <Fragment>
                <Formik
                    initialValues={{ email: '', password: '', }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        handleSubmit(values.email, values.password);
                    }}
                >
                    <div className="flex justify-center items-center mt-8">
                        <div className="w-full max-w-xs">
                            <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div>
                                    <h1 className="text-center text-xl font-bold pb-6">
                                        Login
                                    </h1>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                        Email
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        name="email"
                                        type="email"
                                        autoComplete="username"
                                    />
                                    <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="email" />
                                </div>
                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                        Password
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                    />
                                    <ErrorMessage className="text-red-500 text-xs p-1" component="p" name="password" />
                                </div>
                                <div className="flex items-center justify-between">
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                        Sign In
                                    </button>
                                    <Link className="inline-block align-baseline font-bold text-sm text-gray-600 hover:text-gray-700" to="/reset">
                                        Forgot Password?
                                    </Link>
                                </div>
                                {
                                errorMessage ? 
                                    <p className="text-red-500 text-xs px-1 pt-5 text-center">
                                        {errorMessage}
                                    </p>
                                    : null
                                }
                                {/* <div className="flex items-center justify-center">
                                    
                                </div> */}
                            </Form>
                        </div>
                    </div>
                </Formik>
        { process.env.REACT_APP_VERSION ? (
            <div className="px-3 text-xs text-gray-300">
                Version: { process.env.REACT_APP_VERSION}
            </div>
        ) : null}
      

      </Fragment>
        
        ) : (
            null
        )
        
    )

};
 
export default Login;