import React, { useRef, useEffect, useState, useCallback, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ExternalTableType, Point, SavedPoints, SavedSegmentations, setCurrentMaskIndices, setCurrentSegmentationPath } from '../../redux/reducers/builderReducer';
import { AppDispatch, RootState } from '../../redux/store';

import {
  imageDataToImageURL,
  actionLine,
  actionFill,
  generateRandomColor,
  fillPixels
} from './CanvasTools'

// import * as imageTools from '../../tools/imageTools';

type Coordinate = {
  x: number;
  y: number;
};

interface CanvasProps {
  data: Uint8ClampedArray,
  width: number
  height: number
}

type Color = {
  color: string,
  r: number,
  g: number,
  b: number,
  a: number,
}


const Canvas = (props: CanvasProps) => {
  
  const { data, width, height } = props;

  const imageRef = useRef(null)
  const onScreenCanvasRef = useRef(null)
  const offScreenCanvasRef = useRef(null)

  const dispatch = useDispatch<AppDispatch>();

  const savedSegmentations: SavedPoints    = useSelector((state: RootState) => state.builder.savedSegmentations);
  const savedMaskIndices: SavedSegmentations    = useSelector((state: RootState) => state.builder.savedMaskIndices);
  const currentMaskIndices: number[] | null    = useSelector((state: RootState) => state.builder.currentMaskIndices);
  const selectedIndex: ExternalTableType | null = useSelector((state: RootState) => state.builder.selectedIndex);
  const fileNames: string[]                = useSelector((state: RootState) => state.builder.fileNames);
  const fileIndex: number | null           = useSelector((state: RootState) => state.builder.fileIndex);

  const imageScale: number    = useSelector((state: RootState) => state.builder.imageScale);
  
  // Draw state, 0: segmentation, 1: fill
  const drawState: number = useSelector((state: RootState) => state.builder.drawState);

  const [isDragging, setIsDragging]                 = useState<boolean>(false)
  const [currentImageData, setCurrentImageData]             = useState<ImageData>()
  const [startMousePosition, setStartMousePosition] = useState<Coordinate | undefined>(undefined);

  const [lastPoint, setLastPoint]               = useState<[number, number] | null>(null)   
  
  const [currentPointsArr, setCurrentPointsArr] = useState<Point[]>([])   

  // const [offScreenCanvas, setOffScreenCanvas]   = useState<any>()   
  
  // const [offScreenImg, setOffScreenImg]         = useState<any>(new Image())

  // const [imageStatistics, setImageStatistics]         = useState<{mean: number | null, std: number | null}>({mean: null, std: null})

  /**
   * Get the x and y coordinates of the mouse with reference to the canvas
   */
  const getCoordinates = useCallback((event: MouseEvent): {x: number, y: number, offsetX: number, offsetY: number} | undefined => {
    if (!onScreenCanvasRef.current) {
      return
    }
    const canvas: any = onScreenCanvasRef.current;
    const offScreenCanvas: any = offScreenCanvasRef.current

    let trueRatio = canvas.offsetWidth/offScreenCanvas.width;

    let mouseX = Math.floor(event.offsetX/trueRatio);
    let mouseY = Math.floor(event.offsetY/trueRatio);
    // return {x: Math.floor((event.pageX - canvas.offsetLeft) / trueRatio), y: Math.floor((event.pageY - canvas.offsetTop) / trueRatio) };
    return {
      x: mouseX,
      y: mouseY,
      offsetX: event.offsetX,
      offsetY: event.offsetY
    };
  },[]);

  /**
   * Draw the supplied path on the canvas with the supplied color
   */
  const drawPath = useCallback((cvs: any, path: Point[], fillPoint: [number, number], color: Color, ratio: number = 1) => {

    if (!onScreenCanvasRef.current || path.length === 0) {
      return
    }

    const ctx = cvs.getContext('2d');
    

    if (ctx) {
      for (let index = 0 ; index < path.length ; index++ ) {
          const {startX, startY, endX, endY} = path[index]          
          actionLine(startX, startY, endX, endY, color, ctx, ratio)
      }
      const canvasImageData = ctx.getImageData(0, 0, cvs.width, cvs.height);
      const res = actionFill(fillPoint[0], fillPoint[1], color, canvasImageData, canvasImageData, null, true)
      const newOffScreenImageData = res.imageData
      ctx.putImageData(newOffScreenImageData, 0, 0);
    }
  }, [])

  /**
   * Draw the saved segmentation paths
   */
  const drawSavedSegmentations = useCallback(() => {

    // Check if needed values exist
    if (
      fileIndex !== null
      && fileNames[fileIndex]
    ) {

    
      // let defaultColor: Color = {color: 'rgba(255, 0, 0, 0.8)', r: 255, g: 0, b: 0, a: 170}
      let highlightedColor: Color = {color: 'rgba(255, 100, 0, 0.8)', r: 255, g: 100, b: 0, a: 170}
      const offScreenCanvas = offScreenCanvasRef.current
      
      // Segmentations drawn with the outline tool
      if (savedSegmentations[fileNames[fileIndex]]) {
        const segmentations: Point[][]       = savedSegmentations[fileNames[fileIndex]]['segmentations']
        const fillPoints: [number, number][] = savedSegmentations[fileNames[fileIndex]]['fillPoints']

        segmentations.forEach((path: Point[], index: number) => {
          let {color} = generateRandomColor(0.8);
          const [x, y] = fillPoints[index];   
          if (
            selectedIndex !== null
            && index === selectedIndex.index 
            && selectedIndex?.type === 'Outline'
            && selectedIndex?.fileName === fileNames[fileIndex]
          ) {
            color = highlightedColor
          } 
          drawPath(offScreenCanvas, path, [x, y], color)
          
        })

      }

      if (savedMaskIndices[fileNames[fileIndex]]) {
        const segmentationIndices: number[][] = savedMaskIndices[fileNames[fileIndex]]        
        segmentationIndices.forEach((segmentations: number[], index: number) => {

          let {color} = generateRandomColor(0.8);
          if (
            selectedIndex !== null
            && index === selectedIndex.index 
            && selectedIndex?.type === 'Fill'
            && selectedIndex?.fileName === fileNames[fileIndex]
          ) {
            color = highlightedColor
          }
          fillPixels(offScreenCanvas, segmentations, color)
        })

      }
    }
  }, [fileNames, fileIndex, savedSegmentations, selectedIndex, savedMaskIndices, drawPath]);

  /**
   * Update the image source to the new imageUrl
   * Param:
   *  imgURL (string): Image url to update
   */
  const UpdateImage = useCallback((imgURL: string) => {
    
    const im: any = imageRef.current

    if (im !== null && imgURL !== undefined) {
      im.src = imgURL
    }
  }, [])

  const drawCanvas = useCallback((onScreenCTX: any, img: any) => {
    onScreenCTX.imageSmoothingEnabled = false;
    onScreenCTX.drawImage(img, 0, 0, width, height)
  }, [width, height])

  const renderCanvasImage = useCallback((source: any) => {
    const offScreenImg = new Image()
    offScreenImg.src = source;
    offScreenImg.onload = () => {
      const onScreenCanvas: any = onScreenCanvasRef.current
      
      if (onScreenCanvas) {
        const onScreenCTX = onScreenCanvas.getContext("2d");
        onScreenCTX.clearRect(0, 0, width, height);
        drawCanvas(onScreenCTX, offScreenImg);
      }
    }
  }, [width, height, drawCanvas])


  /**
   * Clear the canvases of all content.
   */
   const clearCanvases = () => {
    
    const offScreenCanvas: any = offScreenCanvasRef.current;
    const onScreenCanvas: any = onScreenCanvasRef.current;

    if (offScreenCanvas) {
      const onScreenContext = onScreenCanvas.getContext('2d');
      onScreenContext.clearRect(0, 0, onScreenCanvas.width, onScreenCanvas.height);
    }

    if (offScreenCanvas) {
      const offScreenContext = offScreenCanvas.getContext("2d");
      offScreenContext.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
    }
  }

  const getFillStart = useCallback((points: Array<Point>): {x: number, y: number} => {
    // Select a pixel inside the segment

    let minx = 2**16;
    let miny = 2**16;
    let maxx = -1;
    let maxy = -1;
    
    points.forEach(point => {
      
      // X axis
      if (point.startX < minx) {
        minx = point.startX;
      }
      if (point.startX > maxx) {
        maxx = point.startX;
      }

      // Y axis
      if (point.startY < miny) {
        miny = point.startY;
      }
      if (point.startY > maxy) {
        maxy = point.startY;
      }
    })

    

    let center_x = Math.floor((minx + maxx) / 2)
    let center_y = Math.floor((miny + maxy) / 2)
    
    

    return {
      x: center_x,
      y: center_y
    }

  },[])

  const startDrag = useCallback((event: MouseEvent) => {

    if (!isDragging) {
      const mouse = getCoordinates(event)
      if (mouse) {

        let offScreenCanvas: any = offScreenCanvasRef.current

        switch (drawState) {
          case 0:
            
            setIsDragging(true);
            setStartMousePosition(mouse);
            setLastPoint([mouse.x, mouse.y]);
            setCurrentPointsArr([])
            clearCanvases();
            drawSavedSegmentations();
            break;
        
          case 1:

            setIsDragging(false);
            setCurrentPointsArr([])

            clearCanvases();
            drawSavedSegmentations();

            // Produce an image from the off screen canvas
            const osContext: any = offScreenCanvas.getContext('2d');
            const offScreenImageData = osContext.getImageData(
                                          0, 0,
                                          offScreenCanvas.width,
                                          offScreenCanvas.height
                                        ); 


            const fillColor = {color: 'rgba(255, 0, 0, 0.8)', r: 255, g: 0, b: 0, a: 180}

            // TODO: Slider or number for offset
            let thresholdOffset: number | null = 5

            if (currentImageData && fileIndex !== null) {
              
              let filledPixels: number[] = []
              try {
                let res = actionFill(mouse.x, mouse.y, fillColor, currentImageData, offScreenImageData, thresholdOffset)
                filledPixels = res.fillPoints;
                // if shift is pressed, fill in previous current and update current to include both new and old indices
                if (event.shiftKey && currentMaskIndices && currentMaskIndices.length > 0 && fileIndex !== null) {
                  filledPixels = [...currentMaskIndices, ...filledPixels]
                }

                dispatch(setCurrentMaskIndices({indices: filledPixels, fileName: fileNames[fileIndex]}))
                
                fillPixels(offScreenCanvas, filledPixels, fillColor)

              } catch(err) {
                console.error(err)
              }
            } 
      
            break;
            
          default:
            break;
        }

        const source = offScreenCanvas.toDataURL();
        renderCanvasImage(source);

      }
    } else {
      console.warn("Warning: Something strange happened when starting your segmentation")
    }
  }, [isDragging, drawState, fileNames, fileIndex, currentMaskIndices, currentImageData, drawSavedSegmentations, dispatch, getCoordinates, renderCanvasImage])

  /**
   * Called during mouse move event on the canvas
   * If the mouse is dragging, then get the coordinates of the mouse and add the new mouse position to the
   * points arr. Draw a new line to the new coordinate
   */
  const dragging = useCallback((event: MouseEvent) => {
    if (isDragging && drawState === 0) { // 0 == outline tool
      const mouse = getCoordinates(event);
      const lineColor = {color: 'rgba(255, 0, 0, 0.5)', r: 255, g: 0, b: 0, a: 128}

      const offScreenCanvas: any = offScreenCanvasRef.current;

      if (startMousePosition && mouse) {
        if (lastPoint && lastPoint.length === 2) {
          if (Math.abs(mouse.x-lastPoint[0]) > 1 || Math.abs(mouse.y-lastPoint[1]) > 1) {

            const context = offScreenCanvas.getContext("2d");


            actionLine(lastPoint[0], lastPoint[1], mouse.x, mouse.y, lineColor, context, 1)

            const newPoint: Point = {
              startX: lastPoint[0],
              startY: lastPoint[1],
              endX: mouse.x,
              endY: mouse.y
            }

            setLastPoint([mouse.x, mouse.y]);

            

            setCurrentPointsArr((prev: Point[]) => [...prev, newPoint])
    
            const source = offScreenCanvas.toDataURL();
            renderCanvasImage(source);

          } 
        }
      }
    } 
  }, [startMousePosition, isDragging, lastPoint, drawState, getCoordinates, renderCanvasImage])


  const exitDrag = useCallback((event: MouseEvent) => {
    if (isDragging) {

      const mouse = getCoordinates(event);
      const lineColor = {color: 'rgba(255, 0, 0, 0.5)', r: 255, g: 0, b: 0, a: 128}
      const fillColor = {color: 'rgba(255, 0, 0, 0.5)', r: 255, g: 0, b: 0, a: 128}
      const offScreenCanvas: any = offScreenCanvasRef.current;

      if (startMousePosition && fileIndex !== null && mouse) {

        if (lastPoint && lastPoint.length === 2) {
      

          const context = offScreenCanvas.getContext("2d");

          actionLine(mouse.x, mouse.y, startMousePosition.x, startMousePosition.y, lineColor, context)

          const finalPoint: Point = {
            startX: mouse.x,
            startY: mouse.y,
            endX: startMousePosition.x,
            endY: startMousePosition.y
          }


          const points: Point[] = [...currentPointsArr, finalPoint]

          // area should not be closed. Flood fill

          const {x, y} = getFillStart(points)
          
          const osContext: any = offScreenCanvas.getContext('2d');
          const offScreenImageData = osContext.getImageData(
                                        0, 0,
                                        offScreenCanvas.width,
                                        offScreenCanvas.height
                                      ); 

          const res = actionFill(x, y, fillColor, offScreenImageData, offScreenImageData, null, true)
          
          let newOffScreenImageData = res.imageData;
          osContext.putImageData(newOffScreenImageData, 0, 0);

          dispatch(setCurrentSegmentationPath({segmentation: {path: points, fillPoint: [x, y] }, fileName: fileNames[fileIndex]}))
          
          setLastPoint([mouse.x, mouse.y]);
          setCurrentPointsArr((prev: Point[]) => [...prev, finalPoint])

          const source = offScreenCanvas.toDataURL();
          renderCanvasImage(source);

          // Reset values
          setIsDragging(false);
          setStartMousePosition(undefined);
          setLastPoint(null)

        }
      }
    }  
  }, [startMousePosition, currentPointsArr, fileIndex, fileNames, isDragging, lastPoint, dispatch, getCoordinates, renderCanvasImage, getFillStart])


  /**

   */ 
  useEffect(() => {

    if (width && height) {

      // Reset stored values
      dispatch(setCurrentMaskIndices({indices: null, fileName: null}));
      dispatch(setCurrentSegmentationPath({segmentation: null, fileName: null}));
      setCurrentPointsArr([]);
      setIsDragging(false);
      setStartMousePosition(undefined);
      setLastPoint(null);

      // set scale on screen canvas context
      const onScreenCanvas: any = onScreenCanvasRef.current;
      const onScreenContext = onScreenCanvas.getContext("2d");
      onScreenCanvas.width = width * imageScale
      onScreenCanvas.height = height * imageScale
      onScreenContext.scale(imageScale, imageScale)
    
      // set off screen canvas to original width and height
      const offScreenCVS: any = offScreenCanvasRef.current;
      offScreenCVS.width = width;
      offScreenCVS.height = height;

    }
  
  },[width, height, imageScale, dispatch])


  /**
   *  Runs on start and whenever the image changes (data).
   *  Convert data into ImageData object and get imageURL.
   *  Call function to update viewed image to new imageURL and clear canvas
   */ 
  useEffect(() => {
    if (data && width && height) {
      if ((height * width * 4) !== data.length) {
        console.error("Error: Data length is not a equal to height * width * 4")
      } else {
        let cimg = imageDataToImageURL(
                      new ImageData(data, width, height),
                      width, height
                    )
        
        if (fileIndex !== null) {   
          dispatch(setCurrentSegmentationPath({segmentation: null, fileName: null}));
          dispatch(setCurrentMaskIndices({indices: null, fileName: null}));
          drawSavedSegmentations()
        }
        
        setCurrentImageData(new ImageData(data, width, height))
        UpdateImage(cimg)

      } 
  }
  },[data, width, height, fileIndex, UpdateImage, dispatch, drawSavedSegmentations])
  
  /**
   *  Update the on screen canvas whenever the saved segmentations change, selected index
   *  from the external table changes, or image size chages
   */ 
  useEffect(() => {
    // if (fileIndex !== undefined && savedSegmentations && offScreenCanvas) {
    if (fileIndex !== undefined && imageScale && savedSegmentations && savedMaskIndices) {
      
      clearCanvases()
      drawSavedSegmentations()
      const offScreenCanvas: any = offScreenCanvasRef.current;
      const source = offScreenCanvas.toDataURL();
      renderCanvasImage(source);
    }
  },[fileIndex, savedSegmentations ,savedMaskIndices, imageScale, selectedIndex, renderCanvasImage, drawSavedSegmentations])


  /**
   * Runs on start and adds event listener for mouse down
   * Adds event listener for mouse down on canvas
   */
  useEffect(() => {
 
    if (!onScreenCanvasRef.current) { return; }
    const canvas: any = onScreenCanvasRef.current; 
    canvas.addEventListener("mousedown", startDrag);
    return () => {
      canvas.removeEventListener("mousedown", startDrag); 
    };
  }, [startDrag]);

  /**
   * Runs on start and adds event listener for mouse dragging
   * Adds event listener for mouse movement on canvas
   */
  useEffect(() => {
    if (!onScreenCanvasRef.current) { return; }  
    const canvas: any = onScreenCanvasRef.current; 
    canvas.addEventListener("mousemove", dragging);  
    return () => {
      canvas.removeEventListener("mousemove", dragging); 
    };
  }, [dragging]);

  /**
   * Runs on start and adds event listener for mouse drag stop
   * Adds event listener for mouse leave and mouse up on canvas
   */
  useEffect(() => {
    if (!onScreenCanvasRef.current) { return; } 
    const canvas: any = onScreenCanvasRef.current;
    canvas.addEventListener("mouseup", exitDrag);
    canvas.addEventListener("mouseleave", exitDrag);
    return () => {
      canvas.removeEventListener("mouseup", exitDrag);
      canvas.removeEventListener("mouseleave", exitDrag);
    };
  }, [exitDrag]);
  
  return (
    <Fragment>
    <div className='hidden'>
      <canvas ref={offScreenCanvasRef} height={height} width={width} style={{zIndex: 5}}/>
    </div>
    <div>
      <div>
        <canvas ref={onScreenCanvasRef} style={{position: 'absolute', zIndex: 3}}/>
        <img ref={imageRef} src="" id="output" height={height*imageScale} width={width*imageScale} style={{zIndex: 1}} alt="infrared"></img>
      </div>
    </div>
    </Fragment>
  
  )
}

export default Canvas

